import React, { Fragment, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import usaFlag from '@Images/united-states-of-america-flag-icon-64.png';
import spanishFlag from '@Images/spain-flag-icon-64.png';

export const useStyles = makeStyles(theme => ({
	listItem: {
		'&:hover': {
			background: theme.palette.primary.hover,
			'&:before': {
				transform: 'scale(1)'
			}
		}
	}
}));

const LanguageMenu = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const classes = useStyles();
	const openMenu = Boolean(anchorEl);

	const handleMenu = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Fragment>
			<IconButton color="inherit" onClick={handleMenu}>
				<Badge color="secondary">
					<Avatar
						style={{
							height: '23px',
							width: '44px',
							marginTop: '9px'
						}}
						variant="square"
						alt="My PHI"
						src={usaFlag}
					/>
					<Typography
						component="h1"
						variant="h6"
						color="inherit"
						noWrap
						style={{
							marginLeft: '7px',
							marginTop: '3px'
						}}
					>
						English
					</Typography>
				</Badge>
			</IconButton>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				open={openMenu}
				onClose={handleClose}
			>
				<MenuItem className={classes.listItem} onClick={handleClose}>
					<Avatar
						style={{
							height: '23px',
							width: '44px',
							marginTop: '4px'
						}}
						variant="square"
						alt="My PHI"
						src={usaFlag}
					/>
					<Typography
						component="h1"
						variant="h6"
						color="inherit"
						noWrap
						style={{
							marginLeft: '7px',
							marginTop: '3px'
						}}
					>
						English
					</Typography>
				</MenuItem>
				<MenuItem className={classes.listItem} onClick={handleClose}>
					<Avatar
						style={{
							height: '23px',
							width: '44px',
							marginTop: '4px'
						}}
						variant="square"
						alt="My PHI"
						src={spanishFlag}
					/>
					<Typography
						component="h1"
						variant="h6"
						color="inherit"
						noWrap
						style={{
							marginLeft: '7px',
							marginTop: '3px'
						}}
					>
						Spanish
					</Typography>
				</MenuItem>
			</Menu>
		</Fragment>
	);
};

export default LanguageMenu;
