import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import PropTypes from 'prop-types';

const getTooltip = params => {
	if (params.datum.id === 'other') {
		return null;
	}
	return (
		<div style={{ background: '#ffffff', padding: '5px', fontWeight: 700 }}>
			{params.datum.label}: {`${params.datum.value / 10}%`}
		</div>
	);
};

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
	const riskScoreObject = dataWithArc.find(item => item.id === 'riskScore');
	return (
		<text
			x={centerX}
			y={centerY}
			textAnchor="middle"
			dominantBaseline="central"
			className="riskPercent"
		>
			{riskScoreObject && riskScoreObject.value / 10} %
		</text>
	);
};

const RiskScoreChart = ({ data }) => (
	<ResponsivePie
		data={data}
		margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
		innerRadius={0.5}
		padAngle={0.7}
		cornerRadius={3}
		borderWidth={1}
		borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
		sliceLabel="value"
		sliceLabelsTextColor="#333333"
		enableRadialLabels={false}
		enableSliceLabels={false}
		animate
		defs={[
			{
				id: 'dots',
				type: 'patternDots',
				background: 'inherit',
				color: 'rgba(255, 255, 255, 0.3)',
				size: 4,
				padding: 1,
				stagger: true
			},
			{
				id: 'lines',
				type: 'patternLines',
				background: 'inherit',
				color: 'rgba(255, 255, 255, 0.3)',
				rotation: -45,
				lineWidth: 6,
				spacing: 10
			}
		]}
		fill={[
			{
				match: {
					id: 'riskScore'
				},
				id: 'riskScore'
			}
		]}
		colors={{ datum: 'data.color' }}
		legends={[
			{
				anchor: 'bottom',
				direction: 'row',
				justify: false,
				translateX: 0,
				translateY: 56,
				itemsSpacing: 0,
				itemWidth: 100,
				itemHeight: 18,
				itemTextColor: '#red',
				itemDirection: 'left-to-right',
				itemOpacity: 1,
				symbolSize: 18,
				symbolShape: 'circle',
				effects: [
					{
						on: 'hover',
						style: {
							itemTextColor: '#000'
						}
					}
				]
			}
		]}
		tooltip={getTooltip}
		layers={['slices', 'sliceLabels', 'radialLabels', CenteredMetric]}
	/>
);

RiskScoreChart.propTypes = {
	data: PropTypes.array.isRequired
};

export default RiskScoreChart;
