import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import Copyrights from '@Components/Copyrights/index';
import { Route } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import ListItems from './components/ListItems/index';
import Messages from './components/Messages/index';
import LanguageMenu from './components/LanguageMenu/index';

import cookies from '../../utilities/cookies';

/* TODO: Remove this - demo  */
import authentication from '../../utilities/authentication';

import { styles } from './styles';
import { breadcrumbNameMap } from './constants';

const useStyles = makeStyles(styles);

const LinkRouter = props => <Link {...props} component={RouterLink} />;

const Home = props => {
	const {
		logOut,
		children,
		sendFeedback,
		location,
		selectedPerson,
		messages,
		providersWaitingForSms,
		sendSmsCode,
		getJobs
	} = props;

	const isSlideMenuOpen = cookies.get('isSlideMenuOpen');
	const classes = useStyles();
	const [open, setOpen] = useState(isSlideMenuOpen === 'true');
	const refContainer = useRef(null);
	const matchWidth = useMediaQuery('(min-width:400px)');

	useEffect(() => {
		try {
			refContainer.current.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		} catch (error) {
			window.scrollTo(0, 0);
		}
	}, [props.location.pathname]);

	const handleDrawerOpen = () => {
		cookies.set('isSlideMenuOpen', true);
		setOpen(true);
	};

	const handleDrawerClose = () => {
		cookies.set('isSlideMenuOpen', false);
		setOpen(false);
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const openMenu = Boolean(anchorEl);

	const handleMenu = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogOut = async () => {
		try {
			const res = await logOut();
			if (res.response.get('success')) {
				// refactor this
				authentication.logout();
			}
		} catch (e) {
			console.log(e);
		}
	};

	const userData = authentication.loggedData();

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Box display="block" displayPrint="none">
				<AppBar
					position="absolute"
					className={clsx(
						classes.appBar,
						open && classes.appBarShift
					)}
				>
					<Toolbar className={classes.toolbar}>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							className={clsx(
								classes.menuButton,
								open && classes.menuButtonHidden
							)}
						>
							<MenuIcon />
						</IconButton>
						<Typography
							component="h1"
							variant="h6"
							color="inherit"
							noWrap
							className={classes.title}
						>
							Welcome to My PHI
						</Typography>
						<Messages
							messages={messages}
							userId={userData.userId}
							location={location}
							getJobs={getJobs}
						/>
						<LanguageMenu />
						<div>
							<IconButton
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleMenu}
								color="inherit"
							>
								<AccountCircle />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right'
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right'
								}}
								open={openMenu}
								onClose={handleClose}
							>
								<MenuItem onClick={handleClose}>
									{userData.username}
								</MenuItem>
								<MenuItem onClick={handleLogOut}>
									Log Out
								</MenuItem>
							</Menu>
						</div>
					</Toolbar>
				</AppBar>
				<Drawer
					variant="permanent"
					classes={{
						paper: clsx(
							classes.drawerPaper,
							!open && classes.drawerPaperClose
						)
					}}
					open={open}
				>
					<div className={classes.toolbarIcon}>
						<IconButton
							className={classes.customHoverFocus}
							color="primary"
							onClick={handleDrawerClose}
						>
							<ChevronLeftOutlinedIcon />
						</IconButton>
					</div>
					<Divider />
					<ListItems
						sendFeedback={sendFeedback}
						userData={userData}
						location={location}
						selectedPerson={selectedPerson}
						providersWaitingForSms={providersWaitingForSms}
						sendSmsCode={sendSmsCode}
					/>
				</Drawer>
			</Box>
			<main
				id="main-content"
				ref={refContainer}
				className={classes.content}
			>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="lg" className={classes.container}>
					<Grid container spacing={3}>
						{/* Content */}
						<Grid item xs={12}>
							<Paper className={classes.paper}>
								<Route>
									{({ location }) => {
										const pathNames = location.pathname
											.split('/')
											.filter(x => x);

										const { state } = location;
										return (
											<Box
												display="block"
												displayPrint="none"
											>
												<Breadcrumbs aria-label="breadcrumb">
													<LinkRouter
														color="inherit"
														to="/"
													>
														Home
													</LinkRouter>
													{pathNames.map(
														(value, index) => {
															const last =
																index ===
																pathNames.length -
																	1;
															const to = `/${pathNames
																.slice(
																	0,
																	index + 1
																)
																.join('/')}`;

															const title = breadcrumbNameMap(
																to,
																state
															);

															if (!title) {
																return null;
															}

															return last ? (
																<Typography
																	color="textPrimary"
																	key={to}
																>
																	{breadcrumbNameMap(
																		to,
																		state
																	)}
																</Typography>
															) : (
																<LinkRouter
																	color="inherit"
																	to={{
																		pathname: to,
																		state
																	}}
																	key={to}
																>
																	{breadcrumbNameMap(
																		to,
																		state
																	)}
																</LinkRouter>
															);
														}
													)}
												</Breadcrumbs>
											</Box>
										);
									}}
								</Route>
								{children}
							</Paper>
						</Grid>
					</Grid>
					<Box pt={4}>
						<Copyrights />
					</Box>
				</Container>
			</main>
		</div>
	);
};

Home.propTypes = {
	children: PropTypes.array.isRequired,
	messages: PropTypes.array.isRequired,
	sendFeedback: PropTypes.func.isRequired,
	getJobs: PropTypes.func.isRequired,
	providersWaitingForSms: PropTypes.array.isRequired,
	logOut: PropTypes.func.isRequired,
	sendSmsCode: PropTypes.func.isRequired,
	location: PropTypes.object,
	selectedPerson: PropTypes.object.isRequired
};

Home.defaultProps = {
	location: {}
};

export default Home;
