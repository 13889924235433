import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	formControl: {
		minWidth: 120,
		width: '100%'
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	}
}));

const providerMap = {
	MyChart: 'Healthcare',
	Education: 'Education'
};
const RequestNewProviderForm = props => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [providerName, setProviderType] = React.useState('');
	const [username, setUsername] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [url, setWebsite] = React.useState('');

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setProviderType('');
		setUsername('');
		setPassword('');
		setWebsite('');
	};

	const handleFieldChange = event => {
		if (event.target.name === 'providerUsername') {
			setUsername(event.target.value);
		} else if (event.target.name === 'providerPassword') {
			setPassword(event.target.value);
		} else {
			setWebsite(event.target.value);
		}
	};

	const handleChange = event => {
		setProviderType(event.target.value);
	};

	const requestNewProvider = () => {
		const providerData = {
			url,
			username,
			password
		};

		props.requestNewProvider(providerData);
		handleClose();
	};

	return (
		<React.Fragment>
			<Button
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
			>
				Add provider details
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					Request New Provider
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						To request a new provider, select provider type, please
						enter your website, email and password. We will send
						updates occasionally.
					</DialogContentText>
					<FormControl
						variant="outlined"
						className={classes.formControl}
					>
						<InputLabel id="provider-type-select-label">
							Provider Type
						</InputLabel>
						<Select
							labelId="provider-type-select-label"
							id="provider-type-select"
							value={providerName}
							onChange={handleChange}
							label="Provider Type"
						>
							{props.providersType.map(type => {
								return (
									<MenuItem key={type.id} value={type.id}>
										{providerMap[type.name]}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="providerWebsite"
						label="Provider Website"
						name="providerWebsite"
						value={url}
						onChange={handleFieldChange}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="providerUsername"
						label="Username/Login on Provider Site"
						name="providerUsername"
						value={username}
						onChange={handleFieldChange}
						inputProps={{
							autocomplete: 'new-password',
							form: {
								autocomplete: 'off'
							}
						}}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="providerPassword"
						label="Password on Provider Site"
						type="password"
						id="providerPassword"
						value={password}
						onChange={handleFieldChange}
						inputProps={{
							autocomplete: 'new-password',
							form: {
								autocomplete: 'off'
							}
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button
						disabled={
							!providerName || !username || !password || !url
						}
						onClick={requestNewProvider}
						color="primary"
					>
						Send Request
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

RequestNewProviderForm.propTypes = {
	requestNewProvider: PropTypes.func.isRequired,
	providersType: PropTypes.array.isRequired
};

export default RequestNewProviderForm;
