import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@Components/TextField/index';

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: '20px',
		width: '100%',
		border: '1px solid rgba(0, 0, 0, 0.12)',
		padding: '30px'
	}
}));

const keyMap = [
	{
		id: 'studentInfoItemMainInfoGridselector',
		label: 'Student Info Item Main Info Grid Selector'
	},
	{
		id: 'studentInfoItemFamilyInfoGridselector',
		label: 'Student Info Item Family Info Grid Selector'
	},
	{
		id: 'studentInfoItemStudentEntityGridselector',
		label: 'Student Info Item Student Entity Grid Selector'
	},
	{
		id: 'studentinfoItemStudentInfoGridselector',
		label: 'Student Info Item Student Info Grid Selector'
	}
];

const StudentSelectors = props => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography variant="h6" gutterBottom>
				Student Selectors
			</Typography>
			<Grid container spacing={3}>
				{keyMap.map(item => (
					<Grid item xs={12} md={6}>
						<TextField
							id={item.id}
							label={item.label}
							fullWidth
							onChange={props.handleField}
							value={props.data[item.id]}
						/>
					</Grid>
				))}
			</Grid>
		</div>
	);
};

StudentSelectors.propTypes = {
	handleField: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired
};

export default StudentSelectors;
