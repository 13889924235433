import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import CardContent from '@material-ui/core/CardContent';
import ListItem from '@material-ui/core/ListItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	cardContent: {
		flexGrow: 1
	},
	informationWrapper: {
		maxWidth: '180px',
		textAlign: 'right'
	},
	informationWrapperText: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		}
	},
	checkboxWrapper: {
		height: '42px',
		minWidth: '30px'
	},
	header: {
		marginBottom: '20px',
		marginLeft: '40px',
		marginTop: '20px'
	},
	typography: {
		padding: theme.spacing(2)
	},
	ellipsisText: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		cursor: 'pointer',
		minWidth: '300px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px',
			minWidth: '58px'
		}
	},
	titleHeader: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		}
	},
	title: {
		[theme.breakpoints.down('sm')]: {
			width: '140px'
		}
	}
}));

const PersonalInfo = props => {
	const classes = useStyles();

	const {
		showVisibilitySettings,
		data,
		changeVisibilitySettings,
		visibilitySchema,
		sectionSchema
	} = props;

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedPopOverId, setSelectedPopOverId] = React.useState(null);

	const handleToggle = (id, value) => {
		if (id === '_globalSettings') {
			changeVisibilitySettings('studentInfo', !value, [
				'_globalSettings',
				'elements'
			]);
		} else {
			changeVisibilitySettings(id, !value, ['studentInfo']);
		}
	};

	const handleClick = (event, id) => {
		setAnchorEl(event.currentTarget);
		setSelectedPopOverId(id);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setSelectedPopOverId(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const listMap = [
		{
			value: data.firstName,
			id: 'firstName',
			title: 'First Name',
			isSelected: visibilitySchema.firstName
		},
		{
			value: data.lastName,
			id: 'lastName',
			title: 'Last Name',
			isSelected: visibilitySchema.lastName
		},
		{
			value: data.gender,
			id: 'gender',
			title: 'Gender',
			isSelected: visibilitySchema.gender
		},
		{
			value: data.age,
			id: 'age',
			title: 'Age',
			isSelected: visibilitySchema.age
		},
		{
			value: data.address,
			id: 'address',
			title: 'Address',
			isSelected: visibilitySchema.address
		},
		{
			value: data.language,
			id: 'language',
			title: 'Language',
			isSelected: visibilitySchema.language
		},
		{
			value: data.graduationYear,
			id: 'graduationYear',
			title: 'Graduation Year',
			isSelected: visibilitySchema.graduationYear
		},
		{
			value: data.otherId,
			id: 'otherId',
			title: 'Other Id',
			isSelected: visibilitySchema.otherId
		},
		{
			value: data.communityService,
			id: 'communityService',
			title: 'Community Service',
			isSelected: visibilitySchema.communityService
		},
		{
			value: data.second,
			id: 'second',
			title: 'Second',
			isSelected: visibilitySchema.second
		},
		{
			value: data.principal,
			id: 'principal',
			title: 'Principal',
			isSelected: visibilitySchema.principal
		},
		{
			value: data.grade,
			id: 'grade',
			title: 'Grade',
			isSelected: visibilitySchema.grade
		},
		{
			value: data.status,
			id: 'status',
			title: 'Status',
			isSelected: visibilitySchema.status
		},
		{
			value: data.homeRoom,
			id: 'homeRoom',
			title: 'Home Room',
			isSelected: visibilitySchema.homeRoom
		},
		{
			value: data.disciplineOfficer,
			id: 'disciplineOfficer',
			title: 'Discipline Officer',
			isSelected: visibilitySchema.disciplineOfficer
		},
		{
			value: data.school,
			id: 'school',
			title: 'School',
			isSelected: visibilitySchema.school
		},
		{
			value: data.schoolMail,
			id: 'schoolMail',
			title: 'School Mail',
			isSelected: visibilitySchema.schoolMail
		},
		{
			value: data.call,
			id: 'call',
			title: 'Call',
			isSelected: visibilitySchema.call
		}
	];

	return (
		<Grid item xs={12} sm={12} md={12}>
			<Card className={classes.card}>
				<CardContent className={classes.cardContent}>
					<Typography
						align="left"
						gutterBottom
						variant="h5"
						component="h2"
						className={classes.header}
					>
						{showVisibilitySettings && (
							<FormControlLabel
								control={
									<Checkbox
										icon={
											<VisibilityIcon
												style={{
													marginLeft: '15px'
												}}
											/>
										}
										checkedIcon={
											<VisibilityIcon
												color="primary"
												style={{
													marginLeft: '15px'
												}}
											/>
										}
										edge="start"
										checked={sectionSchema}
										tabIndex={-1}
										disableRipple
										onClick={() =>
											handleToggle(
												'_globalSettings',
												sectionSchema
											)
										}
									/>
								}
							/>
						)}
						Student Info
					</Typography>
					<List className={classes.root}>
						{listMap.map(item => {
							const labelId = `checkbox-list-label-${item.value}`;

							return (
								<ListItem
									key={item.id}
									role={undefined}
									dense
									button
									onClick={() =>
										handleToggle(item.id, item.isSelected)
									}
								>
									<ListItemIcon
										className={classes.checkboxWrapper}
									>
										{showVisibilitySettings && (
											<FormControlLabel
												control={
													<Checkbox
														icon={
															<VisibilityIcon />
														}
														checkedIcon={
															<VisibilityIcon color="primary" />
														}
														name="checked"
														edge="start"
														checked={
															item.isSelected
														}
														tabIndex={-1}
														disableRipple
														inputProps={{
															'aria-labelledby': labelId
														}}
													/>
												}
											/>
										)}
									</ListItemIcon>
									<ListItemText
										id={labelId}
										className={classes.title}
										primary={
											<div
												className={classes.titleHeader}
											>
												{`${item.title}:`}
											</div>
										}
									/>

									<ListItemText
										id={labelId}
										align="center"
										primary={
											<div
												className={
													classes.informationWrapperText
												}
											>
												{item.value || 'N/A'}
											</div>
										}
										className={classes.informationWrapper}
										onClick={e => handleClick(e, item.id)}
									/>
									<Popover
										id={id}
										open={selectedPopOverId === item.id}
										anchorEl={anchorEl}
										onClose={handleClose}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center'
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'center'
										}}
									>
										<Typography
											className={classes.typography}
										>
											{item.value || 'N/A'}
										</Typography>
									</Popover>
								</ListItem>
							);
						})}
					</List>
				</CardContent>
			</Card>
		</Grid>
	);
};

PersonalInfo.propTypes = {
	data: PropTypes.object.isRequired,
	showVisibilitySettings: PropTypes.bool.isRequired,
	changeVisibilitySettings: PropTypes.func.isRequired,
	visibilitySchema: PropTypes.object.isRequired,
	sectionSchema: PropTypes.bool.isRequired
};

export default PersonalInfo;
