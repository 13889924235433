import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';

import NestedTableHead from './NestedTableHead';
import NestedTableBody from './NestedTableBody';

import { styles } from './styles';

const useStyles = makeStyles(styles);

const NestedTable = props => {
	const { rows, headCells, isNested, customMap } = props;

	const classes = useStyles();
	const [order, setOrder] = useState('desc');
	const [orderBy, setOrderBy] = useState('date');

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};
	/* HOT FIX - BUG - MYP-226 - hide scroll on tables and set scroll on parent element - Check ISSUE in react-virtualized and when this is fixed, just remove this code - https://github.com/bvaughn/react-virtualized/issues/1339   */
	return (
		<div className={classes.root} style={{ minWidth: '750px' }}>
			<TableContainer style={{ overflow: 'hidden', minWidth: '750px' }}>
				<Table
					className={classes.table}
					aria-labelledby="tableTitle"
					size="medium"
					aria-label="enhanced table"
					style={{ minWidth: '750px' }}
				>
					<NestedTableHead
						classes={classes}
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
						headCells={headCells}
						rowCount={rows.length}
						isNested={isNested}
					/>

					<NestedTableBody
						rows={rows}
						classes={classes}
						order={order}
						orderBy={orderBy}
						headCells={headCells}
						isNested={isNested}
						customMap={customMap}
					/>
				</Table>
			</TableContainer>
		</div>
	);
};

NestedTable.propTypes = {
	rows: PropTypes.array.isRequired,
	headCells: PropTypes.array.isRequired,
	customMap: PropTypes.object,
	isNested: PropTypes.bool.isRequired
};

NestedTable.defaultProps = {
	customMap: {}
};

export default NestedTable;
