import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const defaultState = fromJS({
    data: [],
    chartData: []
});

const testReport = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.GET_TEST_REPORT:
			if (action.state === 'finished') {
                state = state.set('data', action.response.get('data'));
                /* const newChartData = action.response
					.get('chartData')
					.toJS()
					.map(x => {
						return {
							id: x.name,
							value: x.count,
							label: x.name,
							color: 'red'
						};
					});
				state = state
					.set('chartData', action.response.get('newChartData'))
					.set('data', action.response.get('data')); */
			}
			return state;

		default:
			return state;
	}
};

export default testReport;
