import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@Components/Table/index';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Checkbox from '@material-ui/core/Checkbox';

const overdueHeadCells = [
	{
		id: 'title',
		numeric: false,
		disablePadding: false,
		label: 'Title'
	},
	{
		id: 'status',
		numeric: false,
		disablePadding: false,
		label: 'Status'
	}
];

const nextHeadCells = [
	{
		id: 'title',
		numeric: false,
		disablePadding: false,
		label: 'Title'
	},
	{
		id: 'date',
		numeric: false,
		disablePadding: false,
		label: 'Date',
		isDateFormat: true
	},
	{
		id: 'location',
		numeric: false,
		disablePadding: false,
		label: 'Location'
	},
	{
		id: 'orderBy',
		numeric: false,
		disablePadding: false,
		label: 'Order By'
	},
	{
		id: 'orderDetails',
		numeric: false,
		disablePadding: false,
		label: 'Order Details'
	}
];

const futureHeadCells = [
	{
		id: 'title',
		numeric: false,
		disablePadding: false,
		label: 'Title'
	},
	{
		id: 'date',
		numeric: false,
		disablePadding: false,
		label: 'Date',
		isDateFormat: true
	},
	{
		id: 'location',
		numeric: false,
		disablePadding: false,
		label: 'Location'
	},
	{
		id: 'orderBy',
		numeric: false,
		disablePadding: false,
		label: 'Order By'
	},
	{
		id: 'orderDetails',
		numeric: false,
		disablePadding: false,
		label: 'Order Details'
	}
];

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		padding: '0 16px'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(4),
		padding: '0 16px'
	},
	header: {
		padding: '20px'
	},
	informationWrapper: {
		maxWidth: '180px',
		textAlign: 'right'
	}
}));

const PlanOfCare = React.memo(props => {
	const classes = useStyles();

	const {
		data,
		showVisibilitySettings,
		changeVisibilitySettings,
		visibilitySchema,
		sectionSchema
	} = props;

	const handleToggle = (id, value) => {
		changeVisibilitySettings('planOfCare', !value, [
			'_globalSettings',
			'elements'
		]);
	};

	const handleCheckbox = (index, isSelected, type) => {
		changeVisibilitySettings(index, isSelected, ['planOfCare', type]);
	};

	const getSelectedValues = type => {
		const selectedValues = (visibilitySchema[type] || []).map(
			(item, index) => {
				return item && index.toString();
			}
		);
		return selectedValues;
	};

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<Grid item xs={12} sm={12} md={12}>
					<Typography
						align="left"
						gutterBottom
						variant="h5"
						component="h2"
						className={classes.header}
					>
						{showVisibilitySettings && (
							<FormControlLabel
								control={
									<Checkbox
										icon={<VisibilityIcon />}
										checkedIcon={
											<VisibilityIcon color="primary" />
										}
										edge="start"
										checked={sectionSchema}
										tabIndex={-1}
										disableRipple
										onClick={() =>
											handleToggle(
												'_globalSettings',
												sectionSchema
											)
										}
									/>
								}
							/>
						)}
						Plan of Care
					</Typography>
					<Typography
						align="left"
						gutterBottom
						variant="h6"
						component="h6"
						className={classes.header}
					>
						Overdue
					</Typography>
					<Table
						rows={data.planOfCareOverdue}
						headCells={overdueHeadCells}
						hasEmptyRows={false}
						showVisibilitySettings={showVisibilitySettings}
						customCheckbox
						handleCheckboxOnChange={(index, isSelected) =>
							handleCheckbox(
								index,
								isSelected,
								'planOfCareOverdue'
							)
						}
						selectedValues={getSelectedValues('planOfCareOverdue')}
					/>
					<Typography
						align="left"
						gutterBottom
						variant="h6"
						component="h6"
						className={classes.header}
						style={{ marginTop: '10px' }}
					>
						Next
					</Typography>
					<Table
						rows={data.planOfCareNext}
						headCells={nextHeadCells}
						hasEmptyRows={false}
						showVisibilitySettings={showVisibilitySettings}
						customCheckbox
						handleCheckboxOnChange={(index, isSelected) =>
							handleCheckbox(index, isSelected, 'planOfCareNext')
						}
						selectedValues={getSelectedValues('planOfCareNext')}
					/>
					<Typography
						align="left"
						gutterBottom
						variant="h6"
						component="h6"
						className={classes.header}
						style={{ marginTop: '10px' }}
					>
						Future
					</Typography>
					<Table
						rows={data.planOfCareFuture}
						headCells={futureHeadCells}
						hasEmptyRows={false}
						showVisibilitySettings={showVisibilitySettings}
						customCheckbox
						handleCheckboxOnChange={(index, isSelected) =>
							handleCheckbox(
								index,
								isSelected,
								'planOfCareFuture'
							)
						}
						selectedValues={getSelectedValues('planOfCareFuture')}
					/>
				</Grid>
			</Paper>
		</div>
	);
});

PlanOfCare.propTypes = {
	data: PropTypes.array.isRequired,
	visibilitySchema: PropTypes.func.isRequired,
	changeVisibilitySettings: PropTypes.func.isRequired,
	showVisibilitySettings: PropTypes.bool.isRequired,
	sectionSchema: PropTypes.bool.isRequired
};

export default PlanOfCare;
