export const styles = theme => ({
	fullName: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.75rem'
		}
	},
	visibilityIcon: {
		height: '40px',
		width: '40px',
		bottom: '-5px',
		position: 'absolute',
		right: '0px',
		[theme.breakpoints.down('sm')]: {
			height: '35px',
			width: '35px',
			bottom: '0'
		}
	}
});
