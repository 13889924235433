import { fromJS } from 'immutable';
import { CLEAR_SELECTED_PERSON_INFO } from '@SharedActions';
import * as actionTypes from './actionTypes';

export const defaultState = fromJS({
	personsList: [],
	selectedPerson: {}
});

const persons = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.GET_PERSONS:
			if (action.state === 'finished') {
				state = state.set('personsList', action.response);
			}
			return state;
		case actionTypes.SET_SELECTED_PERSON:
			state = state.set(
				'selectedPerson',
				fromJS(action.data.selectedPerson)
			);
			return state;
		case CLEAR_SELECTED_PERSON_INFO:
			state = state.set('selectedPerson', fromJS({}));
			return state;
		default:
			return state;
	}
};

export default persons;
