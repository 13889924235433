import * as actionTypes from './actionTypes';

export const getAllProviders = () => {
	return {
		type: actionTypes.GET_ALL_PROVIDERS,
		requestType: 'GET',
		request: `/allproviders`,
		body: {}
	};
};

export const addProfile = (profileData, providerType) => {
	return {
		type: actionTypes.ADD_PROFILE,
		requestType: 'POST',
		message: 'Creation Success',
		request: `/addProfile`,
		data: { providerType },
		body: {
			...profileData
		}
	};
};

export const fileUpload = form => {
	return {
		type: actionTypes.FILE_UPLOAD,
		requestType: 'POST',
		request: `/fileUpload`,
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		body: form
	};
};

export const getTextFromImage = form => {
	return {
		type: actionTypes.GET_TEXT_FROM_IMAGE,
		requestType: 'POST',
		request: `/getTextFromImage`,
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		body: form
	};
};

export const setDefaultState = () => {
	return {
		type: actionTypes.SET_DEFAULT_STATE
	};
};
