import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Copyrights from '@Components/Copyrights/index';
import VirtualizedTable from '@Components/VirtualizedTable/index';

import { withStyles } from '@material-ui/styles';
import {
	TextField,
	Button,
	CssBaseline,
	Link,
	Paper,
	Box,
	Grid,
	Typography,
	Container
} from '@material-ui/core';
import RenderLink from '@Components/RenderLink/index';
import { getVaccines, checkAgain, goToPoint } from './actions';
import MyMapComponent from './components/GoogleMapComponent/index';

import { styles } from './styles';

class VaccineDashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			fields: {},
			errors: {}
		};
	}

	onChangeHandler = event => {
		const { fields } = this.state;
		fields[event.target.name] = event.target.value;
		this.setState({ fields, errors: {} });
	};

	handleSubmit = async event => {
		const { fields } = this.state;

		if (this.handleValidation()) {
			const formData = {
				zipCode: fields.zipCode,
				address: fields.address,
				range: fields.range,
				city: fields.city,
				centerName: fields.centerName
			};

			try {
				await this.props.getVaccines(formData);
			} catch (e) {
				console.log(e);
			}
		}
	};

	handleCheckAgain = () => {
		this.props.checkAgain();
	};

	handleValidation = () => {
		const { fields } = this.state;
		const errors = {};
		let formIsValid = true;

		if (!fields.zipCode) {
			formIsValid = false;
			errors.zipCode = 'Please enter your zip Code.';
		}

		if (!fields.address) {
			formIsValid = false;
			errors.address = 'Please enter your address.';
		}

		if (!fields.range) {
			formIsValid = false;
			errors.range = 'Please enter your range.';
		}

		this.setState({ errors });

		return formIsValid;
	};

	handleOpenNewTab = coordinates => {
		window.open(
			`https://www.google.com/maps/search/?api=1&query=${coordinates.lat},${coordinates.lon}`,
			'_blank'
		);
	};

	onRowClick = e => {
		this.props.goToPoint(e.index);
	};

	render = () => {
		const { classes, vaccines, mainInfo, hasVaccines } = this.props;
		const { errors, fields } = this.state;
		return (
			<Grid container component="main" className={classes.root}>
				<CssBaseline />
				<Grid item xs={false} sm={4} md={7} className={classes.image}>
					<div
						style={{
							height: '100vh',
							width: '100%'
						}}
					>
						<MyMapComponent
							isMarkerShown
							defaultCenter={{
								lat: mainInfo.lat,
								lng: mainInfo.lon
							}}
							zoom={mainInfo.zoom}
							markers={vaccines}
							handleOnClick={this.handleOpenNewTab}
						/>
					</div>
				</Grid>
				<Grid
					item
					xs={12}
					sm={8}
					md={5}
					component={Paper}
					elevation={6}
					square
				>
					<div className={classes.paper}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
								className={classes.header}
							>
								Interactive Vaccine Center Locator
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
								className={classes.subHeader}
							>
								Please enter your address below to find the
								closest vaccination centers
							</Typography>
						</Container>

						{hasVaccines && vaccines.length > 0 ? (
							<React.Fragment>
								<div
									style={{
										height: ' calc(100vh - 540px)',
										width: '100%'
									}}
								>
									<VirtualizedTable
										rowCount={vaccines.length}
										rowGetter={({ index }) =>
											vaccines[index]
										}
										rowHeight={100}
										hasInitialWidth
										onRowClick={this.onRowClick}
										columns={[
											{
												width: 150,
												label: 'Name',
												dataKey: 'name'
											},
											{
												width: 150,
												label: 'City',
												dataKey: 'city'
											},
											{
												width: 150,
												label: 'Address',
												dataKey: 'address'
											},
											{
												width: 125,
												label: 'Zip Code',
												dataKey: 'zipCode'
											},
											{
												width: 125,
												label: 'Distance',
												dataKey: 'distance'
											}
										]}
									/>
								</div>
								<div>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
										className={classes.submit}
										onClick={this.handleCheckAgain}
									>
										Check Again
									</Button>
								</div>
							</React.Fragment>
						) : (
							<div>
								<TextField
									variant="outlined"
									value={fields.zipCode}
									margin="normal"
									required
									fullWidth
									id="zipCode"
									label="Zip Code"
									name="zipCode"
									error={errors.zipCode}
									helperText={errors.zipCode}
									onChange={this.onChangeHandler}
								/>

								<TextField
									variant="outlined"
									value={fields.range}
									margin="normal"
									required
									fullWidth
									id="range"
									label="Range"
									name="range"
									error={errors.range}
									helperText={errors.range}
									onChange={this.onChangeHandler}
								/>

								<TextField
									variant="outlined"
									value={fields.address}
									margin="normal"
									required
									fullWidth
									name="address"
									label="Address"
									id="address"
									error={errors.address}
									helperText={errors.address}
									onChange={this.onChangeHandler}
								/>

								<TextField
									variant="outlined"
									value={fields.city}
									margin="normal"
									fullWidth
									name="city"
									label="City"
									id="city"
									error={errors.city}
									helperText={errors.city}
									onChange={this.onChangeHandler}
								/>

								<TextField
									variant="outlined"
									value={fields.centerName}
									margin="normal"
									fullWidth
									name="centerName"
									label="Center Name"
									id="centerName"
									error={errors.centerName}
									helperText={errors.centerName}
									onChange={this.onChangeHandler}
								/>

								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									className={classes.submit}
									onClick={this.handleSubmit}
								>
									Check Vaccine
								</Button>
							</div>
						)}

						<Grid container>
							<Grid item xs>
								<RenderLink
									element={Link}
									to="/login"
									variant="body2"
								>
									Back to login page
								</RenderLink>
							</Grid>
						</Grid>
						<Box mt={5}>
							<Copyrights />
						</Box>
					</div>
				</Grid>
			</Grid>
		);
	};
}

VaccineDashboard.propTypes = {
	vaccines: PropTypes.array.isRequired,
	mainInfo: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	getVaccines: PropTypes.func.isRequired,
	hasVaccines: PropTypes.bool.isRequired,
	checkAgain: PropTypes.func.isRequired,
	goToPoint: PropTypes.func.isRequired
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				vaccines: state.unauthenticatedPages
					.getIn(['vaccineDashboard', 'vaccines'])
					.toJS(),
				hasVaccines: state.unauthenticatedPages.getIn([
					'vaccineDashboard',
					'hasVaccines'
				]),

				mainInfo: state.unauthenticatedPages
					.getIn(['vaccineDashboard', 'mainInfo'])
					.toJS()
			};
		},
		{
			getVaccines,
			checkAgain,
			goToPoint
		}
	)(VaccineDashboard)
);
