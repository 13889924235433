import * as actionTypes from './actionTypes';

export const getDashboardUrl = data => {
	return {
		type: actionTypes.GET_DASHBOARD_URL,
		requestType: 'GET',
		request: `/getDashboardUrl?code=${data.password}`,
		data
	};
};

export const setDefaultState = () => {
	return {
		type: actionTypes.SET_DEFAULT_STATE
	};
};
