import * as actionTypes from './actionTypes';

export const getCovidFeedbacks = () => {
	return {
		type: actionTypes.GET_COVID_FEEDBACKS,
		requestType: 'GET',
		request: `/covidFeedbacks`,
		body: {}
	};
};
