export const FINISHED = 'finished';
export const REQUESTED = 'requested';
export const SEND = 'send';
export const ERROR = 'error';

export const REQUEST_TYPES = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	DELETE: 'DELETE',
	PATCH: 'PATCH'
};

/* export const apiBasePath = 'http://localhost:8080/api'; */

export const IS_DEV = process.env.NODE_ENV === 'development';

export const AUTH_TOKEN_COOKIE_NAME = 'Authorization';

export const FAIL_REQUEST_CODES = ['501', '404', '401', '412', '400'];
