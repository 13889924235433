import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { fromJS } from 'immutable';

import { OPEN_ALERT, LOG_IN } from '@SharedActions';
import { CLOSE_ALERT, GET_MESSAGES } from './actions';

import jobs from './pages/Jobs/reducer';
import consent from './pages/Consent/reducer';
import selfProfile from './pages/SelfProfile/reducer';
import admin from './pages/Admin/reducer';
import dashboard from './pages/Dashboard/reducer';
import reports from './pages/Reports/reducer';
import summary from './pages/Summary/reducer';
import persons from './pages/Persons/reducer';
import timeline from './pages/Timeline/reducer';
import connection from './pages/Connection/reducer';
import unauthenticatedPages from './pages/UnauthenticatedPages/reducer';

export const defaultState = fromJS({
	isOpen: false,
	isAlertOpen: false,
	isLoginSuccessful: false,
	messages: [],
	alertInfo: {
		severity: 'success',
		message: 'This is a success message!'
	}
});

const general = (state = defaultState, action) => {
	switch (action.type) {
		case CLOSE_ALERT: {
			return state.set('isAlertOpen', false);
		}
		case OPEN_ALERT: {
			return state
				.setIn(['alertInfo', 'severity'], action.body.severity)
				.setIn(['alertInfo', 'message'], action.body.message)
				.set('isAlertOpen', true);
		}
		case GET_MESSAGES: {
			if (action.state === 'finished') {
				state = state.set('messages', action.response.get('messages'));
			}

			return state;
		}

		default: {
			if (action.state === 'finished') {
				if (action.requestType === 'POST' && action.message) {
					state = state
						.setIn(['alertInfo', 'severity'], 'success')
						.setIn(['alertInfo', 'message'], action.message)
						.set('isAlertOpen', true);
				}

				if (action.type === LOG_IN) {
					state = state.set('isLoginSuccessful', true);
				}

				return state.set('isOpen', false);
			}
			if (action.state === 'send') {
				return state.set('isOpen', true);
			}
			if (action.state === 'error') {
				return state
					.setIn(
						['alertInfo', 'message'],
						action.response.get('message')
					)
					.setIn(['alertInfo', 'severity'], 'error')
					.set('isAlertOpen', true)
					.set('isOpen', false);
			}
			return state;
		}
	}
};

const createRootReducer = history =>
	combineReducers({
		router: connectRouter(history),
		general,
		jobs,
		consent,
		selfProfile,
		admin,
		dashboard,
		reports,
		summary,
		persons,
		timeline,
		connection,
		unauthenticatedPages
	});
export default createRootReducer;
