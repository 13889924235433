import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@Components/Table/index';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(4)
	},
	header: {
		padding: '20px'
	}
}));

const List = React.memo(props => {
	const classes = useStyles();

	const { providers, updateProvider, copyProvider, deleteProvider } = props;

	const headCells = [
		{
			id: 'name',
			numeric: false,
			disablePadding: false,
			label: 'Name'
		},
		{
			id: 'custom_update',
			isCustom: true,
			numeric: false,
			disablePadding: false,
			label: 'Update',
			customComponent: providerInfo => {
				return (
					<Button
						onClick={() => updateProvider(providerInfo)}
						variant="contained"
						color="primary"
						style={{
							width: '190px',
							fontSize: '12px'
						}}
					>
						Update Provider Config
					</Button>
				);
			}
		},
		{
			id: 'custom_copy',
			isCustom: true,
			numeric: false,
			disablePadding: false,
			label: 'Copy',
			customComponent: providerInfo => {
				return (
					<Button
						onClick={() => copyProvider(providerInfo)}
						variant="contained"
						color="primary"
						style={{
							width: '190px',
							fontSize: '12px'
						}}
					>
						Copy Provider Config
					</Button>
				);
			}
		},
		{
			id: 'custom_delete',
			isCustom: true,
			numeric: false,
			disablePadding: false,
			label: 'Delete ',
			customComponent: providerInfo => {
				return (
					<Button
						onClick={() => deleteProvider(providerInfo)}
						variant="contained"
						color="primary"
						style={{
							width: '190px',
							fontSize: '12px'
						}}
					>
						Delete Provider Config
					</Button>
				);
			}
		}
	];

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<Grid item xs={12} sm={12} md={12}>
					<Typography
						align="left"
						gutterBottom
						variant="h5"
						component="h2"
						className={classes.header}
					>
						Configuration exist providers
					</Typography>
					<Table
						rows={providers}
						headCells={headCells}
						hasEmptyRows={false}
						showVisibilitySettings={false}
						handleCheckboxOnChange={false}
						selectedValues={[]}
					/>
				</Grid>
			</Paper>
		</div>
	);
});

List.propTypes = {
	providers: PropTypes.array.isRequired,
	deleteProvider: PropTypes.func.isRequired,
	updateProvider: PropTypes.func.isRequired,
	copyProvider: PropTypes.func.isRequired
};

export default List;
