import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import RoomIcon from '@material-ui/icons/Room';
import PublicIcon from '@material-ui/icons/Public';

const useStyles = makeStyles(theme => ({
	root: {
		width: '460px',
		maxWidth: 460,
		[theme.breakpoints.down('sm')]: {
			width: '95vw',
			maxWidth: '95vw'
		},
		display: 'flex',
		backgroundColor: '#fafafa'
	},
	avatar: {
		backgroundColor: theme.palette.primary.main
	},
	items: {
		paddingLeft: 0,
		paddingRight: 0
	}
}));

export default function ZipCodeInfo(props) {
	const classes = useStyles();
	const { info } = props;
	return (
		<List className={classes.root}>
			<ListItem className={classes.items}>
				<ListItemAvatar>
					<Avatar className={classes.avatar}>
						<LocationCityIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary="City" secondary={`${info.city}`} />
			</ListItem>
			<ListItem className={classes.items}>
				<ListItemAvatar>
					<Avatar className={classes.avatar}>
						<PublicIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText primary="County" secondary={`${info.county}`} />
			</ListItem>
			<ListItem className={classes.items}>
				<ListItemAvatar>
					<Avatar className={classes.avatar}>
						<RoomIcon />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary="Zip Code"
					secondary={`${info.zipCode}`}
				/>
			</ListItem>
		</List>
	);
}

ZipCodeInfo.propTypes = {
	info: PropTypes.object.isRequired
};
