import React from 'react';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import VisibilityIcon from '@material-ui/icons/Visibility';

const EnhancedTableHead = props => {
	const {
		classes,
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort,
		headCells,
		customCheckbox,
		showVisibilitySettings,
        handleCheckbox,
        isCollapsible
	} = props;
	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
                {isCollapsible && (
                    <TableCell padding="checkbox"></TableCell>
                )}
				{showVisibilitySettings && (
					<TableCell padding="checkbox">
						{/* {customCheckbox ? (
							<FormControlLabel
								style={{ marginLeft: '15px' }}
								control={
									<Checkbox
										icon={<VisibilityIcon />}
										checkedIcon={
											<VisibilityIcon color="primary" />
										}
										name="checked"
										edge="start"
										tabIndex={-1}
										disableRipple
										checked={
											rowCount > 0 &&
											numSelected === rowCount
										}
										onChange={onSelectAllClick}
										inputProps={{
											'aria-label': 'select all desserts'
										}}
									/>
								}
							/>
						) : (
							<Checkbox
								indeterminate={
									numSelected > 0 && numSelected < rowCount
								}
								checked={
									rowCount > 0 && numSelected === rowCount
								}
								onChange={onSelectAllClick}
								inputProps={{
									'aria-label': 'select all desserts'
								}}
							/>
						)} */}
					</TableCell>
				)}

				{headCells.map(headCell => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc'
										? 'sorted descending'
										: 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
	headCells: PropTypes.array.isRequired
};

export default EnhancedTableHead;
