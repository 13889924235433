import cookies from './cookies';

function loggedData() {
	const userName =
		process.env.REACT_APP_IS_PROD === 'true'
			? parseSignedCookie(cookies.get('uname'))
			: cookies.get('uname');
	const userId =
		process.env.REACT_APP_IS_PROD === 'true'
			? parseSignedCookie(cookies.get('uid'))
			: cookies.get('uid');
	const userType =
		process.env.REACT_APP_IS_PROD === 'true'
			? parseSignedCookie(cookies.get('utype'))
			: cookies.get('utype');

	let loggedIn = false;

	if (userName !== undefined && userId !== undefined) {
		loggedIn = true;
	}

	const data = {
		loggedIn,
		username: userName,
		userId,
		userType
	};

	return data;
}

function getPerson() {
	const person = {
		id: cookies.get('person')
	};
	return person;
}

function logout() {
	cookies.remove('uname');
	cookies.remove('uid');
	cookies.remove('utype');
	window.location = '/login';
}

function parseSignedCookie(cookie) {
	// cookie comes in format => s:Ivan Ivanov.uZz80tYUImjagwTI7aOvYWNfOsxYIZCR3ieXn8dLYE8
	if (cookie !== undefined) {
		return cookie.substring(2, cookie.indexOf('.'));
	}

	return cookie;
}

export default {
	loggedData,
	logout,
	getPerson
};
