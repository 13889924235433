/* eslint-disable no-underscore-dangle */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { withStyles } from '@material-ui/styles';

import ConfirmationDialog from '@Components/ConfirmationDialog';

import {
	getProfile,
	getProvider,
	getProviderCredentials,
	updateProfileVisibilitySchema,
	deleteProfile,
	sendTempLink,
	updateProviderCredentials,
	updateProfile,
	moveProfile,
	getPersonsList
} from '../../actions';

import { styles } from './styles';

import { infoComponents, initialState } from './constants';

import ActionButtons from '../../components/ActionButtons/index';
import Vendor from '../../components/Vendor/index';

import authentication from '../../../../../../utilities/authentication';

class ProfileInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...initialState,
			profileDetailId: '',
			person: {},
			tempLinkEmail: '',
			isConfirmationDialogOpen: false
		};
	}

	componentDidMount = async () => {
		const userData = authentication.loggedData();

		const { profile, person } = this.props.location.state;

		await this.props.getPersonsList(userData.userId);

		try {
			const provider = await this.props.getProvider(profile.provider.id);

			const res = provider.response.toJS();

			if (res.providerName !== 'self provider') {
				this.props.getProviderCredentials(profile.id);
			}

			const profileData = await this.props.getProfile(
				profile.id,
				profile.profileDetailId
			);

			this.setState({
				profileDetailId: profile.profileDetailId,
				person,
				visibilitySchema: profileData.response
					.get('visibilitySchema')
					.toJS()
			});
		} catch (e) {
			console.log(e, 'error');
		}
	};

	changeVisibilitySettings = (id, value, sectionName) => {
		const { visibilitySchema } = this.state;
		let visibilitySchemaImmutable = fromJS(visibilitySchema);

		visibilitySchemaImmutable = visibilitySchemaImmutable.setIn(
			[...sectionName, id],
			value
		);

		this.setState({
			visibilitySchema: visibilitySchemaImmutable.toJS()
		});
	};

	handleShowVisibilitySettings = () => {
		const { showVisibilitySettings } = this.state;
		this.setState({ showVisibilitySettings: !showVisibilitySettings });
	};

	deleteProfile = async () => {
		const profileObj = {
			profileId: this.props.profileData.toJS().id
		};

		try {
			await this.props.deleteProfile(profileObj);
			const { person } = this.state;

			this.props.history.push({
				pathname: `/dashboard/${person.id}/profiles/`,
				state: { person }
			});
		} catch (e) {
			console.log(e);
		}
	};

	updateProfile = async () => {
		const profileObj = {
			profileId: this.props.profileData.get('id'),
			providerId: this.props.profileData.get('provider'),
			profileDetailId: this.state.profileDetailId
		};

		try {
			await this.props.updateProfile(profileObj);
		} catch (e) {
			console.log(e);
		}
	};

	moveProfile = async personId => {
		const profileObj = {
			profileId: this.props.profileData.toJS().id,
			personId
		};
		/* current person info */
		const { person } = this.state;

		try {
			await this.props.moveProfile(profileObj);
			this.props.history.push({
				pathname: `/dashboard/${person.id}/profiles/`,
				state: { person }
			});
		} catch (e) {
			console.log(e);
		}
	};

	updateProviderCredentials = async data => {
		const { providerCredentials } = this.props;
		const credentialsData = {
			password: data.password,
			username: data.username,
			id: providerCredentials.id
		};

		try {
			await this.props.updateProviderCredentials(credentialsData);
		} catch (e) {
			console.log(e);
		}
	};

	fileAction = async fileId => {
		/* await this.props.openFile(fileId); */
	};

	updateVisibilitySettings = () => {
		const loggedData = authentication.loggedData();

		const updateData = {
			id: this.props.profileData.toJS().id,
			user: loggedData.userId,
			visibilitySchema: this.state.visibilitySchema
		};

		this.props.updateProfileVisibilitySchema(updateData);
	};

	handleChangePersonVisibilitySchema = event => {
		event.preventDefault();
		event.stopPropagation();
		const { all } = this.state.visibilitySchema._globalSettings;
		this.changeVisibilitySettings('all', !all, ['_globalSettings']);
	};

	handleMarkAll = value => {
		const { visibilitySchema } = this.state;

		this.setState({
			visibilitySchema: this.markAll(visibilitySchema, value)
		});
	};

	markAll = (values, markValue) => {
		switch (true) {
			case typeof values === 'boolean':
				return markValue;
			case Object.prototype.toString.call(values) === '[object Array]':
				values.forEach(
					(value, index, theArray) =>
						(theArray[index] = this.markAll(value, markValue))
				);
				break;
			case typeof values === 'object':
				Object.keys(values).forEach(key => {
					if (values[key] !== null) {
						values[key] = this.markAll(values[key], markValue);
					}
				});
				break;
		}

		return values;
	};

	generateTempLink = () => {
		const loggedData = authentication.loggedData();

		const tempLink = {
			user: loggedData.userId,
			email: this.state.tempLinkEmail,
			profileId: this.props.profileData.toJS().id,
			url: 'printProfile',
			content: {
				parentData: this.props.providerData,
				responseData: { profile: this.props.profileData.toJS() },
				adminMode: false,
				tempLinkCodeAccess: false
			}
		};

		this.props.sendTempLink(tempLink);

		this.setState({
			tempLinkEmail: '',
			isConfirmationDialogOpen: false
		});
	};

	handleSendEmail = tempLinkEmail => {
		this.setState({
			tempLinkEmail,
			isConfirmationDialogOpen: true
		});
	};

	handlePrint = () => {
		window.print();
	};

	handleCloseConfirmationDialog = () => {
		this.setState({
			tempLinkEmail: '',
			isConfirmationDialogOpen: false
		});
	};

	render = () => {
		const {
			profileData,
			providerData,
			providerCredentials,
			personsList,
			classes
		} = this.props;

		const {
			showVisibilitySettings,
			visibilitySchema,
			person,
			isConfirmationDialogOpen
		} = this.state;

		const infoComponentsMap = infoComponents[providerData.providerTypeName];

		return (
			<Fragment>
				{profileData.get('content') && (
					<Fragment>
						<Typography
							component="h1"
							variant="h2"
							align="center"
							color="textPrimary"
							className={classes.fullNameWrapper}
							gutterBottom
						>
							{showVisibilitySettings && (
								<FormControlLabel
									control={
										<Checkbox
											icon={
												<VisibilityIcon
													className={
														classes.visibilityIcon
													}
												/>
											}
											checkedIcon={
												<VisibilityIcon
													className={
														classes.visibilityIcon
													}
													color="primary"
												/>
											}
											edge="start"
											checked={
												visibilitySchema._globalSettings
													.all
											}
											tabIndex={-1}
											disableRipple
											onClick={
												this
													.handleChangePersonVisibilitySchema
											}
										/>
									}
								/>
							)}
							<span
								className={classes.fullName}
							>{`${providerData.providerName.toUpperCase()} - ${profileData.get(
								'firstName'
								// eslint-disable-next-line react/jsx-closing-tag-location
							)} ${profileData.get('lastName')}`}</span>
						</Typography>
						<Grid
							style={{ marginBottom: '10px' }}
							container
							spacing={4}
						>
							<Grid item md={6} xs={12}>
								<Box display="block" displayPrint="none">
									<ActionButtons
										visibilitySchema={
											this.state.visibilitySchema
										}
										providerName={providerData.providerName}
										showVisibilitySettings={
											showVisibilitySettings
										}
										personalInfo={
											profileData.toJS().content
												.personalInfo
										}
										handleShowVisibilitySettings={
											this.handleShowVisibilitySettings
										}
										changeVisibilitySettings={
											this.changeVisibilitySettings
										}
										updateVisibilitySettings={
											this.updateVisibilitySettings
										}
										markAll={this.handleMarkAll}
										handleSendEmail={this.handleSendEmail}
										deleteProfile={this.deleteProfile}
									/>
								</Box>
							</Grid>

							<Grid item md={6} xs={12}>
								<Box display="block" displayPrint="none">
									<Vendor
										providerData={providerData}
										profileData={profileData.toJS()}
										updateProfile={this.updateProfile}
										updateProviderCredentials={
											this.updateProviderCredentials
										}
										profileId={profileData.toJS().id}
										handlePrint={this.handlePrint}
										fileAction={this.fileAction}
										providerCredentials={
											providerCredentials
										}
										moveProfile={this.moveProfile}
										personsList={personsList}
										person={person}
									/>
								</Box>
							</Grid>
						</Grid>

						{infoComponentsMap.map(item => {
							const ItemComponent = item.comp;

							const schema = fromJS(visibilitySchema);
							const currentSchema = schema.getIn([
								...item.schemaProp
							]);

							const sectionSchema = schema.getIn([
								'_globalSettings',
								'elements',
								...item.sectionSchemaProp
							]);

							return (
								<Grid container spacing={4}>
									<ItemComponent
										data={profileData
											.getIn([...item.data])
											.toJS()}
										providerName={providerData}
										showVisibilitySettings={
											showVisibilitySettings
										}
										changeVisibilitySettings={
											this.changeVisibilitySettings
										}
										visibilitySchema={currentSchema.toJS()}
										sectionSchema={sectionSchema}
									/>
								</Grid>
							);
						})}
					</Fragment>
				)}
				<ConfirmationDialog
					open={isConfirmationDialogOpen}
					dialogContent="Are you sure that you want to share your health information with this email?"
					dialogTitle="Confirmation Dialog"
					action={this.generateTempLink}
					close={this.handleCloseConfirmationDialog}
				/>
			</Fragment>
		);
	};
}

ProfileInformation.propTypes = {
	profileData: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	providerData: PropTypes.object.isRequired,
	providerCredentials: PropTypes.object.isRequired,
	updateProfileVisibilitySchema: PropTypes.func.isRequired,
	sendTempLink: PropTypes.func.isRequired,
	deleteProfile: PropTypes.func.isRequired,
	updateProviderCredentials: PropTypes.func.isRequired,
	updateProfile: PropTypes.func.isRequired,
	getPersonsList: PropTypes.func.isRequired,
	moveProfile: PropTypes.func.isRequired,
	getProvider: PropTypes.func.isRequired,
	getProviderCredentials: PropTypes.func.isRequired,
	getProfile: PropTypes.func.isRequired,
	personsList: PropTypes.array.isRequired,
	classes: PropTypes.object.isRequired,
	location: PropTypes.shape({
		state: PropTypes.shape({
			profile: PropTypes.object.isRequired,
			person: PropTypes.object.isRequired
		})
	})
};

ProfileInformation.defaultProps = {
	location: {}
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				personsList: state.dashboard
					.getIn(['profileDashboard', 'personsList'])
					.toJS(),
				profileData: state.dashboard.getIn([
					'profileDashboard',
					'profileData'
				]),
				providerData: state.dashboard
					.getIn(['profileDashboard', 'providerData'])
					.toJS(),
				providerCredentials: state.dashboard
					.getIn(['profileDashboard', 'providerCredentials'])
					.toJS()
			};
		},
		{
			getProfile,
			getProvider,
			updateProfileVisibilitySchema,
			getProviderCredentials,
			deleteProfile,
			sendTempLink,
			updateProviderCredentials,
			updateProfile,
			moveProfile,
			getPersonsList
		}
	)(ProfileInformation)
);
