import * as actionTypes from './actionTypes';

export const getAllProfiles = ({ searchTags, endCursor, isSubmit }) => {
	return {
		type: actionTypes.GET_ALL_PROFILES,
		requestType: 'GET',
		request: `/getAllProfiles?pageCursor=${endCursor}&searchTags=${searchTags}`,
		body: {
			searchTags,
			isSubmit
		}
	};
};
