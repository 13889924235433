import React from 'react';
import PrivateRoute from '@Components/Routes/PrivateRoute';
import ProfilesList from './pages/ProfilesList/index';
import ProfileInformation from './pages/ProfileInformation/index';

class Profiles extends React.Component {
	render = () => {
		return (
			<React.Fragment>
				<PrivateRoute
					path="/dashboard/:personId/profiles"
					component={ProfilesList}
					exact
				/>
				<PrivateRoute
					path="/dashboard/:personId/profiles/:profileId"
					component={ProfileInformation}
					exact
				/>
			</React.Fragment>
		);
	};
}

export default Profiles;
