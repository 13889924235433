import * as actionTypes from './actionTypes';

export const getPersonsList = userId => {
	return {
		type: actionTypes.GET_PERSONS,
		requestType: 'GET',
		request: `/people?user=${userId}`,
		body: {
			userId
		}
	};
};

export const addPerson = personData => {
	return {
		type: actionTypes.ADD_PERSON,
		requestType: 'POST',
		request: `/addPerson`,
		message: 'Name updated successful',
		body: {
			...personData
		}
	};
};

export const photoUpload = form => {
	return {
		type: actionTypes.PHOTO_UPLOAD,
		requestType: 'POST',
		request: `/photoUpload`,
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		body: form
	};
};

export const setSelectedPerson = selectedPerson => {
	return {
		type: actionTypes.SET_SELECTED_PERSON,
		data: {
			selectedPerson
		}
	};
};
