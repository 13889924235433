import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const defaultState = fromJS({
	profiles: [],
	endCursor: '',
	hasMore: true
});

const profiles = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.GET_ALL_PROFILES:
			if (action.state === 'finished') {
				const oldProfiles = state.get('profiles');
				const resProfiles = action.response.get(0);
				const newProfiles = action.body.isSubmit
					? resProfiles
					: oldProfiles.concat(resProfiles);
				state = state
					.set('profiles', newProfiles)
					.set('endCursor', action.response.getIn([1, 'endCursor']))
					.set(
						'hasMore',
						action.response.getIn([1, 'moreResults']) !==
							'NO_MORE_RESULTS'
					);
			}
			return state;
		default:
			return state;
	}
};

export default profiles;
