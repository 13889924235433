import covidReport from '@Images/covid_report.jpg';

export const styles = theme => ({
	avatar: {
		backgroundColor: theme.palette.primary.main,
		display: 'flex',
		flexDirection: 'column',
		margin: '0 auto'
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	mainImage: {
		position: 'relative',
		backgroundColor: theme.palette.grey[800],
		color: theme.palette.common.white,
		marginBottom: theme.spacing(4),
		backgroundImage: `url(${covidReport})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		height: '240px'
	},
	formWrapper: {
		marginTop: '30px'
	},
	resultsWrapper: {
		marginTop: '30px'
	},
	mainHeader: {
		marginTop: '20px',
		fontWeight: 300,
		[theme.breakpoints.down('sm')]: {
			fontSize: '25px'
		}
	},
	errorMessage: {
		marginTop: '20px',
		fontWeight: 300,
		color: '#f44336'
	},
	chartWrapper: {
		height: '500px',
		width: '550px',
		margin: '0 auto',
		[theme.breakpoints.down('sm')]: {
			height: '350px',
			width: '95vw'
		}
	},
	zipCodeInfoWrapper: {
		margin: '0 auto',
		width: '430px',
		[theme.breakpoints.down('sm')]: {
			width: '95vw'
		}
	},
	feedBackFormWrapper: {
		margin: '0 auto',
		width: '430px',
		marginBottom: '20px',
		[theme.breakpoints.down('sm')]: {
			margin: 'unset',
			marginBottom: '20px',
			width: '95vw'
		}
	}
});
