import React from 'react';

import { Switch } from 'react-router-dom';
import { withRouter } from 'react-router';

import PrivateRoute from './PrivateRoute';

/* Dashboard Page */
import Dashboard from '../../pages/Dashboard/index';

/* Persons */
import PersonsList from '../../pages/Persons/index';

/* Connection */
import CreateProviderProfile from '../../pages/Connection/index';

/* Summary Page */
import Summary from '../../pages/Summary/index';

/* Timeline Page */
import Timeline from '../../pages/Timeline/index';

/* Jobs page */
import Jobs from '../../pages/Jobs/index';

/* Consent(settings) page */
import Consent from '../../pages/Consent/index';

const Routes = () => {
	return (
		<Switch>
			<PrivateRoute exact path="/" component={PersonsList} />

			<PrivateRoute
				path="/dashboard/:personId/newProviderProfile"
				component={CreateProviderProfile}
			/>

			<PrivateRoute
				path="/dashboard/:personId/timeline"
				component={Timeline}
			/>
			<PrivateRoute
				path="/dashboard/:personId/exportSummary"
				component={Summary}
			/>
			<PrivateRoute path="/profilesJobs" component={Jobs} />
			<PrivateRoute
				path="/dashboard/:personId/settings"
				component={Consent}
			/>
			<PrivateRoute
				path="/dashboard/:personId"
				component={Dashboard}
				exact
			/>
		</Switch>
	);
};

export default withRouter(Routes);
