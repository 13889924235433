import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const ProviderForm = props => {
	return (
		<Fragment>
			<TextField
				variant="outlined"
				margin="normal"
				required
				fullWidth
				id="providerUsername"
				label="Username for Provider Portal"
				name="providerUsername"
				value={props.values.providerUsername}
				onChange={props.handleChange}
				inputProps={{
					autocomplete: 'new-password',
					form: {
						autocomplete: 'off'
					}
				}}
			/>
			<TextField
				variant="outlined"
				margin="normal"
				required
				fullWidth
				name="providerPassword"
				label="Password for Provider Portal"
				id="providerPassword"
				type="password"
				value={props.values.providerPassword}
				onChange={props.handleChange}
				inputProps={{
					autocomplete: 'new-password',
					form: {
						autocomplete: 'off'
					}
				}}
			/>
		</Fragment>
	);
};

ProviderForm.propTypes = {
	values: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired
};

export default ProviderForm;
