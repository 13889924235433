export const GET_VISITS = Symbol('GET_VISITS');
export const GET_VISIT = Symbol('GET_VISIT');
export const UPDATE_VISIBILITY_SCHEMA = Symbol('UPDATE_VISIBILITY_SCHEMA');
export const SEND_TEMP_LINK = Symbol('SEND_TEMP_LINK');
export const GET_CONTENT = Symbol('GET_CONTENT');

export const GET_PROVIDER = Symbol('GET_PROVIDER');
export const UPDATE_VISIT = Symbol('UPDATE_VISIT');
export const MOVE_VISIT = Symbol('MOVE_VISIT');
export const GET_PERSONS = Symbol('GET_PERSONS');
export const UPDATE_PROVIDER_CREDENTIALS = Symbol(
	'UPDATE_PROVIDER_CREDENTIALS'
);
export const RESET_DATA = Symbol('RESET_DATA');
export const CHECK_ACCESS_CODE = Symbol('CHECK_ACCESS_CODE');
export const LOAD_VISIT_FROM_TEMP_LINK = Symbol('CHECK_ACCESS_CODE');
export const DELETE_VISIT = Symbol('DELETE_VISIT');
export const GET_PROVIDER_CREDENTIALS = Symbol('GET_PROVIDER_CREDENTIALS');
