import { fromJS } from 'immutable';

import providerList, {
	defaultState as providerListDefaultState
} from './pages/ProviderList/reducer';

export const defaultState = fromJS({
	providerList: providerListDefaultState
});

const PROVIDER_LIST = 'providerList';

export default function providers(state = defaultState, action) {
	return state.set(
		PROVIDER_LIST,
		providerList(state.get(PROVIDER_LIST), action)
	);
}
