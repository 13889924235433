import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	deleteButton: {
		width: '100%',
		cursor: 'pointer',
		textAlign: 'center',
		backgroundColor: '#d9534f',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		},
		'&:hover': {
			backgroundColor: '#c9302c'
		}
	}
}));

const DeleteProfileDialog = props => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Button
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
				className={classes.deleteButton}
			>
				Delete Profile
			</Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				className={classes.root}
			>
				<DialogTitle id="form-dialog-title">
					Delete Profile Confirmation
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete this profile?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button onClick={props.deleteProfile} color="primary">
						Confirm Delete Profile
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

DeleteProfileDialog.propTypes = {
	deleteProfile: PropTypes.func.isRequired
};

export default DeleteProfileDialog;
