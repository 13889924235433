import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';

import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormHelperText from '@material-ui/core/FormHelperText';
import Container from '@material-ui/core/Container';

import { withStyles } from '@material-ui/styles';
import Copyrights from '@Components/Copyrights/index';
import RenderLink from '@Components/RenderLink/index';

import { logIn, registerUser } from '../actions';
import AgreeDialog from '../components/AgreeDialog/index';
import { registerStyles } from './styles';

const EMAIL_REGEX = new RegExp(
	/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
);
const STRONG_PASS_REGEX = new RegExp(
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
);

class Register extends Component {
	constructor(props) {
		super(props);

		this.state = {
			fields: {
				agree: false
			},
			errors: {}
		};
	}

	onChangeHandler = event => {
		const { fields } = this.state;
		fields[event.target.name] = event.target.value;
		this.setState({ fields, errors: {} });
	};

	handleAgreeChange = isAgree => {
		const { fields } = this.state;
		fields.agree = isAgree;
		this.setState({ fields, errors: {} });
	};

	onFormSubmit = async event => {
		event.preventDefault();
		if (this.handleValidation()) {
			const { fields } = this.state;
			const regData = {
				firstName: fields.firstName,
				lastName: fields.lastName,
				email: fields.email.toLowerCase(),
				password: fields.password
			};

			try {
				const registrationResponse = await this.props.registerUser(
					regData
				);

				if (
					registrationResponse.response.has('code') &&
					registrationResponse.response.get('code') === 400
				) {
					return;
				}
				try {
					await this.props.logIn({
						email: fields.email,
						password: fields.password
					});

					this.props.history.push({
						pathname: '/',
						state: { isCreateDialogOpen: true }
					});
				} catch (e) {
					console.log(e, 'login error');
				}
			} catch (e) {
				console.log(e, 'registration error');
			}
		}
	};

	handleValidation = () => {
		const { fields } = this.state;
		const errors = {};
		let formIsValid = true;

		if (!fields.firstName) {
			formIsValid = false;
			errors.firstName = 'Field cannot be empty.';
		}

		if (!fields.lastName) {
			formIsValid = false;
			errors.lastName = 'Field cannot be empty.';
		}

		if (!fields.email) {
			formIsValid = false;
			errors.email = 'Field cannot be empty.';
		} else if (!EMAIL_REGEX.test(fields.email)) {
			formIsValid = false;
			errors.email = 'Email format is not valid.';
		}

		if (!fields.password) {
			formIsValid = false;
			errors.password = 'Field cannot be empty.';
		} else if (!STRONG_PASS_REGEX.test(fields.password)) {
			formIsValid = false;
			errors.password =
				'Password must contains at least 1 lowercase, 1 uppercase alphabetical character, 1 numeric character, one special character and eight characters or longer.';
		}

		if (!fields.repPassword) {
			formIsValid = false;
			errors.repPassword = 'Field cannot be empty.';
		} else if (!STRONG_PASS_REGEX.test(fields.repPassword)) {
			formIsValid = false;
			errors.repPassword =
				'Password must contains at least 1 lowercase, 1 uppercase alphabetical character, 1 numeric character, one special character and eight characters or longer.';
		}

		if (
			fields.password &&
			fields.repPassword &&
			fields.password !== fields.repPassword
		) {
			formIsValid = false;
			errors.repPassword = 'Passwords not match.';
		}

		if (!fields.agree) {
			formIsValid = false;
			errors.agree = 'Please accept user agreements.';
		}

		this.setState({ errors });

		return formIsValid;
	};

	render = () => {
		const { classes } = this.props;
		const { errors, fields } = this.state;
		return (
			<div>
				<Paper className={classes.mainImage} />
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<div className={classes.paper}>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Sign Up
						</Typography>
						<form
							onSubmit={this.onFormSubmit}
							className={classes.form}
							noValidate
						>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<TextField
										autoComplete="fname"
										name="firstName"
										onChange={this.onChangeHandler}
										variant="outlined"
										required
										fullWidth
										value={fields.firstName}
										error={errors.firstName}
										helperText={errors.firstName}
										id="firstName"
										label="First Name"
										inputProps={{
											autocomplete: 'new-password',
											form: {
												autocomplete: 'off'
											}
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										variant="outlined"
										required
										fullWidth
										id="lastName"
										label="Last Name"
										name="lastName"
										value={fields.lastName}
										error={errors.lastName}
										helperText={errors.lastName}
										onChange={this.onChangeHandler}
										autoComplete="lname"
										inputProps={{
											autocomplete: 'new-password',
											form: {
												autocomplete: 'off'
											}
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										id="email"
										label="Email Address"
										name="email"
										value={fields.email}
										onChange={this.onChangeHandler}
										error={errors.email}
										helperText={errors.email}
										inputProps={{
											autocomplete: 'new-password',
											form: {
												autocomplete: 'off'
											}
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										name="password"
										onChange={this.onChangeHandler}
										error={errors.password}
										helperText={errors.password}
										label="Password"
										value={fields.password}
										type="password"
										id="password"
										inputProps={{
											autocomplete: 'new-password',
											form: {
												autocomplete: 'off'
											}
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										name="repPassword"
										onChange={this.onChangeHandler}
										error={errors.repPassword}
										helperText={errors.repPassword}
										label="Confirm Password"
										value={fields.repPassword}
										type="password"
										id="repPassword"
										inputProps={{
											autocomplete: 'new-password',
											form: {
												autocomplete: 'off'
											}
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<AgreeDialog
										handleAgree={this.handleAgreeChange}
									/>
									{errors.agree && (
										<FormHelperText
											className={classes.agreeError}
										>
											Please indicate that you have read
											and agree to the Terms and
											Conditions and Privacy Policy
										</FormHelperText>
									)}
								</Grid>
							</Grid>

							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
								/* 	disabled={!fields.agree} */
							>
								Create your account
							</Button>
							<Grid container justify="flex-end">
								<Grid item>
									<RenderLink
										element={Link}
										to="/login"
										variant="body2"
									>
										Already have an account? Sign in
									</RenderLink>
								</Grid>
							</Grid>
						</form>
					</div>
					<Box mt={5}>
						<Copyrights />
					</Box>
				</Container>
			</div>
		);
	};
}

Register.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	logIn: PropTypes.func.isRequired,
	registerUser: PropTypes.func.isRequired
};

export default withStyles(registerStyles)(
	connect(null, {
		logIn,
		registerUser
	})(Register)
);
