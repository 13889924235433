/* eslint-disable react/no-danger */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import {
	CssBaseline,
	Typography,
	Container,
	Button,
	Box
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import { openAlert } from '@SharedActions';

import { styles } from './styles';

import {
	checkAccessCode,
	loadVisitFromTempLink,
	getContent
} from '../../actions';

class VisitPrintInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			code: ''
		};
	}

	componentDidMount() {}

	handlePrint = () => {
		window.print();
	};

	checkCode = async () => {
		const { code } = this.state;
		const { guid } = this.props.match.params;

		try {
			const res = await this.props.checkAccessCode(code, guid);
			if (res.response) {
				try {
					const visitInfoRes = await this.props.loadVisitFromTempLink(
						guid
					);
					await this.props.getContent(
						visitInfoRes.response.getIn([
							'content',
							'visitData',
							'content'
						])
					);
				} catch (e) {
					console.log(e);
				}
			} else {
				this.props.openAlert({
					severity: 'error',
					message: 'The access code is wrong. Please try again.'
				});
			}
		} catch (e) {
			console.log(e);
		}
	};

	handleFieldChange = e => {
		this.setState({ code: e.target.value });
	};

	render() {
		const { visitData, classes, isAccessCodeValid, content } = this.props;

		const { code } = this.state;

		return (
			<Fragment>
				<CssBaseline />
				<main>
					<div className={classes.informationWrapper}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
							>
								Visit Information
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
							>
								Please enter the access code, sent in a separate
								email, to view the health information that has
								been shared
							</Typography>
						</Container>
						<Container
							className={classes.informationGrid}
							maxWidth="md"
						>
							{isAccessCodeValid && visitData.get('content') ? (
								<Grid
									style={{ marginBottom: '10px' }}
									container
									spacing={7}
								>
									<Grid item xs={12} md={12}>
										<Box
											display="block"
											displayPrint="none"
										>
											<Button
												variant="contained"
												color="primary"
												className={classes.button}
												onClick={this.handlePrint}
												style={{ width: '100%' }}
											>
												Print
											</Button>
										</Box>
									</Grid>
									<Grid
										item
										xs={12}
										md={12}
										className={classes.buttonsWrapper}
									>
										<Typography
											component="h1"
											variant="h2"
											align="center"
											color="textPrimary"
											gutterBottom
											className={classes.header}
										>
											{visitData.get('visitName')}
										</Typography>
									</Grid>
									<Grid
										item
										xs={12}
										md={12}
										className={classes.buttonsWrapper}
									>
										<div
											className="visits_content_wrapper"
											dangerouslySetInnerHTML={{
												__html: content
											}}
										/>
									</Grid>
								</Grid>
							) : (
								<Grid
									style={{ marginBottom: '10px' }}
									container
									spacing={7}
								>
									<Grid offset={5} item xs={12}>
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="code"
											label="Access code"
											name="code"
											value={code}
											onChange={this.handleFieldChange}
										/>
									</Grid>
									<Grid item xs={12}>
										<Button
											onClick={this.checkCode}
											variant="contained"
											color="primary"
											style={{
												width: '100%'
											}}
										>
											Check code
										</Button>
									</Grid>
								</Grid>
							)}
						</Container>
					</div>
				</main>
			</Fragment>
		);
	}
}

VisitPrintInformation.propTypes = {
	checkAccessCode: PropTypes.func.isRequired,
	openAlert: PropTypes.func.isRequired,
	loadVisitFromTempLink: PropTypes.func.isRequired,
	getContent: PropTypes.func.isRequired,
	isAccessCodeValid: PropTypes.bool.isRequired,
	visitData: PropTypes.object.isRequired,
	content: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			guid: PropTypes.string.isRequired
		})
	})
};

VisitPrintInformation.defaultProps = {
	match: {}
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				isAccessCodeValid: state.dashboard.getIn([
					'visitDashboard',
					'isAccessCodeValid'
				]),
				visitData: state.dashboard.getIn([
					'visitDashboard',
					'visitData'
				]),
				content: state.dashboard.getIn(['visitDashboard', 'content'])
			};
		},
		{
			checkAccessCode,
			loadVisitFromTempLink,
			openAlert,
			getContent
		}
	)(VisitPrintInformation)
);
