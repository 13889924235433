import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const defaultState = fromJS({
	showResetCodeButton: false,
	url: '',
	errorMessage: ''
});

const covidHesitancyAnalysis = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.GET_DASHBOARD_URL:
			if (action.state === 'finished') {
				//const url = 'https://github.com/';
				const url = action.response.get('url');
				state = state.set('showResetCodeButton', true).set('url', url);
			}
			if (action.state === 'error') {
				state = state.set(
					'errorMessage',
					action.response.get('message')
				);
			}
			return state;
		case actionTypes.SET_DEFAULT_STATE:
			state = defaultState;
			return state;
		default:
			return state;
	}
};

export default covidHesitancyAnalysis;
