import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		
	},
	updateProfileButton: {
		width: '100%',
		cursor: 'pointer',
		textAlign: 'center',

		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		}
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	}
}));

export default function UpdateProfileDialog(props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleConfirmButton = () => {
		props.updateProfile();
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Button
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
				className={classes.updateProfileButton}
			>
				Update Profile
			</Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				className={classes.root}
			>
				<DialogTitle id="form-dialog-title">
					Update Profile Confirmation
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to update this profile?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleConfirmButton} color="primary">
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}

UpdateProfileDialog.propTypes = {
	updateProfile: PropTypes.array.isRequired
};
