import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const defaultState = fromJS({
	showRiskScoreChart: false,
	data: [],
	cityInfo: {},
	userData: {},
	showGreetingsElements: false,
	errorMessage: ''
});

const getBarColor = value => {
	let color = '#00b050';
	if (value >= 10) {
		color = '#c00000';
	}
	if (value >= 5 && value < 10) {
		color = '#ffc000';
	}

	return color;
};

const riskScoreByZipCode = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.GET_RISK_SCORE_BY_ZIP_CODE:
			if (action.state === 'finished') {
				const data = [
					{
						id: 'riskScore',
						value: action.response.get('riskScore') * 10,
						label: 'Risk Score',
						color: getBarColor(action.response.get('riskScore'))
					},
					{
						id: 'other',
						value: 1000 - action.response.get('riskScore') * 10,
						label: 'Other',
						color: '#e8e8e8'
					}
				];

				state = state
					.set('showRiskScoreChart', true)
					.set('cityInfo', action.response)
					.set('errorMessage', '')
					.set('data', fromJS(data));
			}
			if (action.state === 'error') {
				state = state.set(
					'errorMessage',
					action.response.get('message')
				);
			}
			return state;
		case actionTypes.SEND_COVID_FEEDBACK:
			if (action.state === 'finished') {
				state = state.set('userData', action.response);
			}
			return state;
		case actionTypes.UPDATE_COVID_FEEDBACK:
			if (action.state === 'finished') {
				state = state.set('showGreetingsElements', true);
			}
			return state;
		case actionTypes.SET_DEFAULT_STATE:
			state = defaultState;
			return state;

		default:
			return state;
	}
};

export default riskScoreByZipCode;
