import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CssBaseline, Typography, Container, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import VirtualizedTable from '@Components/VirtualizedTable/index';

import { getCovidReport } from './actions';

import ProgressBar from './components/ProgressBar';

class CovidReport extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		const { personId } = this.props.match.params;
		this.props.getCovidReport(personId);
	}

	render() {
		const { data } = this.props;
		return (
			<Fragment>
				<CssBaseline />
				<Container>
					<Typography
						component="h1"
						variant="h2"
						align="center"
						color="textPrimary"
						gutterBottom
					>
						Covid Report
					</Typography>
					<Typography
						variant="h5"
						align="center"
						color="textSecondary"
						paragraph
					>
						This report is showing the COVID-19 contamination risk
						per ZIP code based on live data collected by Facebook &
						Yahoo
					</Typography>
					<Paper
						style={{
							height: ' calc(100vh - 300px)',
							width: '930px'
						}}
					>
						<VirtualizedTable
							rowCount={data.length}
							rowGetter={({ index }) => data[index]}
							columns={[
								{
									label: 'Zip Code',
									dataKey: 'zipCode',
									width: 320
								},
								{
									label: 'Profile Name',
									dataKey: 'profile',
									width: 320,
									customComponent: profile => (
										<div>{`${profile.firstName} ${profile.lastName}`}</div>
									)
								},
								{
									label: 'Risk',
									width: 520,
									dataKey: 'riskScore',
									customComponent: score => (
										<div style={{ width: '100%' }}>
											<ProgressBar value={score} />
										</div>
									)
								}
							]}
						/>
					</Paper>
				</Container>
			</Fragment>
		);
	}
}

CovidReport.propTypes = {
	data: PropTypes.array.isRequired,
	getCovidReport: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			personId: PropTypes.string.isRequired
		})
	})
};

CovidReport.defaultProps = {
	match: {}
};

export default connect(
	state => {
		return {
			data: state.reports.getIn(['covidReport', 'data']).toJS()
		};
	},
	{
		getCovidReport
	}
)(CovidReport);
