import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import FeedbackIcon from '@material-ui/icons/Feedback';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	ratingWrapper: {
		textAlign: 'center'
	},
	feedbackButton: {
		width: '100%',
		backgroundColor: '#d9534f',
		'&:hover': {
			backgroundColor: '#c9302c'
		}
	}
}));
const FeedbackDialog = props => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [feedback, setFeedback] = React.useState('');
	const [rating, setRating] = React.useState(5);

	const handleClickOpen = e => {
		setOpen(true);
		e.preventDefault();
		e.stopPropagation();
	};

	const handleClose = () => {
		setOpen(false);
		setRating(3);
		setFeedback('');
	};

	const handleFeedbackChange = e => {
		setFeedback(e.target.value);
	};

	const handleSendFeedback = () => {
		props.sendFeedback({ feedback, rating });
		handleClose();
	};

	return (
		<React.Fragment>
			<ListItemIcon>
				<IconButton
					aria-label="feedback form"
					aria-controls="menu-appbar"
					aria-haspopup="true"
					onClick={handleClickOpen}
					style={{ paddingLeft: 0 }}
					color="inherit"
				>
					<FeedbackIcon />
				</IconButton>
			</ListItemIcon>
			<ListItemText onClick={handleClickOpen} primary="Send Feedback" />
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				className={classes.root}
			>
				<DialogTitle id="form-dialog-title">Feedback Form</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Please share here your feedback about the platform
					</DialogContentText>

					<Box
						component="fieldset"
						className={classes.ratingWrapper}
						borderColor="transparent"
					>
						<Typography component="legend">Rating</Typography>
						<Rating
							name="simple-controlled"
							value={rating}
							onChange={(event, newValue) => {
								setRating(newValue);
							}}
						/>
					</Box>

					<TextField
						id="feedback"
						label="Feedback"
						multiline
						rows={4}
						defaultValue="Default Value"
						variant="outlined"
						value={feedback}
						style={{
							width: '100%'
						}}
						onChange={handleFeedbackChange}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button
						disabled={!feedback}
						onClick={handleSendFeedback}
						color="primary"
					>
						Send
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

FeedbackDialog.propTypes = {
	sendFeedback: PropTypes.func.isRequired
};

export default FeedbackDialog;
