import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import {
	Avatar,
	Collapse,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	ListSubheader
} from '@material-ui/core';

/* Icons */
import logo from '@Images/Logo2_small.png';
import connectionIcon from '@Images/connection-icon.png';

import SettingsIcon from '@material-ui/icons/Settings';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewListIcon from '@material-ui/icons/ViewList';
import AssessmentIcon from '@material-ui/icons/Assessment';
import TimelineIcon from '@material-ui/icons/Timeline';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import FeedbackDialog from '../FeedbackDialog/index';
import TwoFactorAuthenticationForm from '../TwoFactorAuthenticationForm/index';
import ListItemLink from './ListItemLink';

import { useStyles } from './styles';

const ListItems = props => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	const {
		userData,
		location,
		selectedPerson,
		providersWaitingForSms,
		sendSmsCode
	} = props;

	const locationState = fromJS(location.state) || fromJS({});

	const personData =
		Object.keys(selectedPerson).length > 0
			? fromJS({ person: selectedPerson })
			: locationState;

	const showPersonalInformation = personData.has('person');
	return (
		<div>
			<List>
				<ListItemLink
					className={classes.item}
					to="/"
					primary="My PHI"
					icon={
						<Avatar
							variant="square"
							className={classes.avatar}
							alt="My PHI"
							src={logo}
						/>
					}
				/>
				<ListItemLink
					className={classes.item}
					to="/profilesJobs"
					primary="Jobs"
					icon={
						<Avatar
							variant="square"
							className={classes.connectionIcon}
							alt="Jobs"
							src={connectionIcon}
						/>
					}
				/>
				{userData.userType !== 'BASIC' && (
					<Fragment>
						<ListItem
							className={classes.item}
							button
							onClick={handleClick}
						>
							<ListItemIcon>
								<ListItemIcon>
									<PeopleIcon />
								</ListItemIcon>
								<ListItemText primary="Admin Panel" />
							</ListItemIcon>
							{open ? (
								<ExpandLess className={classes.expandIcon} />
							) : (
								<ExpandMore className={classes.expandIcon} />
							)}
						</ListItem>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{userData.userType === 'GOD' && (
									<ListItemLink
										className={classes.item}
										to="/admin/users"
										primary="Users"
										icon={<AccessibilityIcon />}
									/>
								)}
								{userData.userType === 'GOD' && (
									<ListItemLink
										className={classes.item}
										to="/admin/providers"
										primary="Providers"
										icon={<PersonAddIcon />}
									/>
								)}
								{userData.userType === 'GOD' && (
									<ListItemLink
										className={classes.item}
										to="/admin/allProfiles"
										primary="Profiles"
										icon={<GroupAddIcon />}
									/>
								)}
								{userData.userType === 'GOD' && (
									<ListItemLink
										className={classes.item}
										to="/admin/profileImporter"
										primary="Import Profiles"
										icon={<ImportExportIcon />}
									/>
								)}
								{userData.userType === 'GOD' && (
									<ListItemLink
										className={classes.item}
										to="/admin/riskScoresImporter"
										primary="Import Risk Scores"
										icon={<ImportExportIcon />}
									/>
								)}
								{userData.userType === 'GOD' && (
									<ListItemLink
										className={classes.item}
										to="/admin/covidFeedbacks"
										primary="Covid Feedback"
										icon={<AssignmentIcon />}
									/>
								)}
								{userData.userType === 'GOD' && (
									<ListItemLink
										className={classes.item}
										to="/admin/requestedZipCodes"
										primary="Requested Zip Codes"
										icon={<AssignmentIcon />}
									/>
								)}
								{userData.userType === 'GOD' && (
									<ListItemLink
										className={classes.item}
										to="/admin/feedbacks"
										primary="MyPHI Feedbacks"
										icon={<AssignmentIcon />}
									/>
								)}
							</List>
						</Collapse>
					</Fragment>
				)}
				<li>
					<ListItem
						style={{
							paddingTop: 0,
							paddingBottom: 0
						}}
						button
						className={classes.item}
					>
						<FeedbackDialog sendFeedback={props.sendFeedback} />
					</ListItem>
				</li>
			</List>
			{showPersonalInformation && (
				<Fragment>
					<Divider />
					<List aria-label="secondary mailbox folders">
						<ListSubheader inset style={{ fontSize: '1rem' }}>
							Personal Details
						</ListSubheader>
						{providersWaitingForSms.length > 0 && (
							<li>
								<ListItem
									style={{
										paddingTop: 0,
										paddingBottom: 0
									}}
									button
									className={classes.item}
								>
									<TwoFactorAuthenticationForm
										sendSmsCode={sendSmsCode}
										providersWaitingForSms={
											providersWaitingForSms
										}
									/>
								</ListItem>
							</li>
						)}
						<ListItemLink
							className={classes.item}
							to={{
								pathname: `/dashboard/${personData.getIn([
									'person',
									'id'
								])}/settings`,
								state: {
									person: personData.get('person').toJS()
								}
							}}
							primary="Settings"
							icon={<SettingsIcon />}
						/>
						<ListItemLink
							className={classes.item}
							to={{
								pathname: `/dashboard/${personData.getIn([
									'person',
									'id'
								])}`,
								state: {
									person: personData.get('person').toJS()
								}
							}}
							primary="Person"
							icon={<PersonIcon />}
						/>
						<ListItemLink
							className={classes.item}
							to={{
								pathname: `/dashboard/${personData.getIn([
									'person',
									'id'
								])}/newProviderProfile`,
								state: {
									person: personData.get('person').toJS()
								}
							}}
							primary="Add Connection"
							icon={<AddCircleIcon />}
						/>
						<ListItemLink
							className={classes.item}
							to={{
								pathname: `/dashboard/${personData.getIn([
									'person',
									'id'
								])}/profiles`,
								state: {
									person: personData.get('person').toJS()
								}
							}}
							primary="My Connections"
							icon={<ViewModuleIcon />}
						/>
						<ListItemLink
							className={classes.item}
							to={{
								pathname: `/dashboard/${personData.getIn([
									'person',
									'id'
								])}/visits`,
								state: {
									person: personData.get('person').toJS()
								}
							}}
							primary="My Visits"
							icon={<ViewListIcon />}
						/>

						<ListItemLink
							className={classes.item}
							to={{
								pathname: `/dashboard/${personData.getIn([
									'person',
									'id'
								])}/exportSummary`,
								state: {
									person: personData.get('person').toJS()
								}
							}}
							primary="Export Summary"
							icon={<ImportExportIcon />}
						/>

						<ListItemLink
							className={classes.item}
							to={{
								pathname: `/dashboard/${personData.getIn([
									'person',
									'id'
								])}/covid-report`,
								state: {
									person: personData.get('person').toJS()
								}
							}}
							primary="COVID-19 report"
							icon={<AssessmentIcon />}
						/>
						<ListItemLink
							className={classes.item}
							to={{
								pathname: `/dashboard/${personData.getIn([
									'person',
									'id'
								])}/timeline`,
								state: {
									person: personData.get('person').toJS()
								}
							}}
							primary="Timeline"
							icon={<TimelineIcon />}
						/>
					</List>
				</Fragment>
			)}
		</div>
	);
};

ListItems.propTypes = {
	userData: PropTypes.object.isRequired,
	selectedPerson: PropTypes.object.isRequired,
	sendFeedback: PropTypes.func.isRequired,
	sendSmsCode: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired,
	providersWaitingForSms: PropTypes.array.isRequired
};

export default ListItems;
