/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import FlareIcon from '@material-ui/icons/Flare';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
	uploadButtonWrapper: {
		marginTop: '20px'
	},
	input: {
		display: 'none'
	},
	deleteButton: {
		backgroundColor: '#d9534f',
		'&:hover': {
			backgroundColor: '#c9302c'
		},
		color: '#ffffff',
		height: '45px',
		width: '45px',
		marginTop: '15px',
		cursor: 'pointer'
	},
	ocrButton: {
		backgroundColor: '#1976d2',
		'&:hover': {
			backgroundColor: 'rgb(17, 82, 147)'
		},
		color: '#ffffff',
		height: '45px',
		width: '45px',
		marginTop: '15px',
		cursor: 'pointer'
	}
}));

const Files = props => {
	const classes = useStyles();

	const handleAdd = () => {
		props.handleAddNewItem(
			[
				{
					name: '',
					file: '',
					id: Math.random()
				}
			],
			'Files',
			'files'
		);
	};

	const handleDelete = index => {
		props.deleteData(index, 'Files', 'files');
	};

	const handleFileUpload = (event, index) => {
		const file = event.target.files[0];
		const oneMBSize = 10048487;
		if (file && file.size <= oneMBSize) {
			props.handleField(event.currentTarget.files[0], 'Files', 'files', [
				index,
				'file'
			]);
		}
	};

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom>
				Files
			</Typography>
			{props.data.length > 0 ? (
				props.data.map((file, index) => {
					return (
						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
								<TextField
									id="name"
									label="Name"
									fullWidth
									InputProps={{
										readOnly: true
									}}
									value={
										(file.file && file.file.name) ||
										file.name
									}
								/>
							</Grid>

							<Grid item xs={12} md={3}>
								<div className={classes.uploadButtonWrapper}>
									<input
										className={classes.input}
										id={`contained-button-file-${file.id}`}
										multiple
										type="file"
										onChange={e =>
											handleFileUpload(e, index)
										}
									/>
									<label
										htmlFor={`contained-button-file-${file.id}`}
									>
										<Button
											variant="contained"
											color="primary"
											component="span"
										>
											Browse
										</Button>
									</label>
								</div>
							</Grid>

							<Grid item xs={12} md={1}>
								<IconButton
									aria-label="ocr"
									color="primary"
									className={classes.ocrButton}
									disabled={!(file.file && file.file.name)}
									onClick={() => props.handleGetOCR(file)}
								>
									<FlareIcon />
								</IconButton>
							</Grid>

							<Grid item xs={12} md={1}>
								<IconButton
									aria-label="delete"
									color="primary"
									className={classes.deleteButton}
									onClick={() => handleDelete(index)}
								>
									<DeleteIcon />
								</IconButton>
							</Grid>
						</Grid>
					);
				})
			) : (
				<Typography component="h1" variant="h4" align="center">
					No Files
				</Typography>
			)}

			<Grid contariner spacing={3}>
				<Button
					style={{ marginTop: '20px' }}
					variant="contained"
					color="primary"
					onClick={handleAdd}
				>
					Add file
				</Button>
			</Grid>
		</React.Fragment>
	);
};

Files.propTypes = {
	data: PropTypes.array.isRequired,
	handleField: PropTypes.func.isRequired,
	handleGetOCR: PropTypes.func.isRequired,
	deleteData: PropTypes.func.isRequired,
	handleAddNewItem: PropTypes.func.isRequired
};

export default Files;
