import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const Vaccinations = props => {
	const handleDateChange = (date, index, dateProp) => {
		props.handleField(date, 'Vaccinations', 'vaccinations', [
			index,
			dateProp
		]);
	};

	const handleAdd = () => {
		props.handleAddNewItem(
			[
				{
					compliance: '',
					date1: new Date(),
					date2: new Date(),
					date3: new Date(),
					date4: new Date(),
					date5: new Date(),
					type: '',
					vaccination: '',
					waiver: ''
				}
			],
			'Vaccinations',
			'vaccinations'
		);
	};

	const handleField = (event, index) => {
		props.handleField(event.target.value, 'Vaccinations', 'vaccinations', [
			index,
			event.target.id
		]);
	};

	const handleDelete = index => {
		props.deleteData(index, 'Vaccinations', 'vaccinations');
	};

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom>
				Vaccinations
			</Typography>
			{props.data.length > 0 ? (
				props.data.map((vaccination, index) => {
					return (
						<Grid container spacing={3}>
							<Grid item xs={12} md={3}>
								<TextField
									id="vaccination"
									label="Vaccination"
									fullWidth
									onChange={e => handleField(e, index)}
									value={vaccination.vaccination}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="compliance"
									label="Compliance"
									fullWidth
									onChange={e => handleField(e, index)}
									value={vaccination.compliance}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="waiver"
									label="Waiver"
									fullWidth
									onChange={e => handleField(e, index)}
									value={vaccination.waiver}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="type"
									label="Type"
									fullWidth
									onChange={e => handleField(e, index)}
									value={vaccination.type}
								/>
							</Grid>

							<Grid item xs={12} md={3}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="MM/dd/yyyy"
										margin="normal"
										id="date1"
										label="Date1"
										value={vaccination.date1}
										style={{
											width: '100%',
											marginTop: 0,
											marginBottom: 0
										}}
										autoOk
										onChange={date =>
											handleDateChange(
												date,
												index,
												'date1'
											)
										}
										KeyboardButtonProps={{
											'aria-label': 'change date'
										}}
									/>
								</MuiPickersUtilsProvider>
							</Grid>

							<Grid item xs={12} md={3}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="MM/dd/yyyy"
										margin="normal"
										id="date2"
										label="Date2"
										value={vaccination.date2}
										style={{
											width: '100%',
											marginTop: 0,
											marginBottom: 0
										}}
										autoOk
										onChange={date =>
											handleDateChange(
												date,
												index,
												'date2'
											)
										}
										KeyboardButtonProps={{
											'aria-label': 'change date'
										}}
									/>
								</MuiPickersUtilsProvider>
							</Grid>

							<Grid item xs={12} md={3}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="MM/dd/yyyy"
										margin="normal"
										id="date3"
										label="Date3"
										value={vaccination.date3}
										style={{
											width: '100%',
											marginTop: 0,
											marginBottom: 0
										}}
										autoOk
										onChange={date =>
											handleDateChange(
												date,
												index,
												'date3'
											)
										}
										KeyboardButtonProps={{
											'aria-label': 'change date'
										}}
									/>
								</MuiPickersUtilsProvider>
							</Grid>

							<Grid item xs={12} md={3}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="MM/dd/yyyy"
										margin="normal"
										id="date4"
										label="Date4"
										value={vaccination.date4}
										style={{
											width: '100%',
											marginTop: 0,
											marginBottom: 0
										}}
										autoOk
										onChange={date =>
											handleDateChange(
												date,
												index,
												'date4'
											)
										}
										KeyboardButtonProps={{
											'aria-label': 'change date'
										}}
									/>
								</MuiPickersUtilsProvider>
							</Grid>

							<Grid item xs={12} md={3}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="MM/dd/yyyy"
										margin="normal"
										id="date5"
										label="Date5"
										value={vaccination.date5}
										style={{
											width: '100%',
											marginTop: 0,
											marginBottom: 0
										}}
										autoOk
										onChange={date =>
											handleDateChange(
												date,
												index,
												'date5'
											)
										}
										KeyboardButtonProps={{
											'aria-label': 'change date'
										}}
									/>
								</MuiPickersUtilsProvider>
							</Grid>

							<Grid item xs={12} md={1}>
								<IconButton
									aria-label="delete"
									color="primary"
									style={{
										backgroundColor: '#d9534f',
										'&:hover': {
											backgroundColor: '#c9302c'
										},
										color: '#ffffff',
										height: '45px',
										width: '45px',
										marginTop: '5px',
										cursor: 'pointer'
									}}
									onClick={() => handleDelete(index)}
								>
									<DeleteIcon />
								</IconButton>
							</Grid>
						</Grid>
					);
				})
			) : (
				<Typography component="h1" variant="h4" align="center">
					No Vaccinations
				</Typography>
			)}
			<Grid contariner spacing={3}>
				<Button
					style={{ marginTop: '20px' }}
					variant="contained"
					color="primary"
					onClick={handleAdd}
				>
					Add vaccination
				</Button>
			</Grid>
		</React.Fragment>
	);
};

Vaccinations.propTypes = {
	data: PropTypes.array.isRequired,
	handleField: PropTypes.func.isRequired,
	deleteData: PropTypes.func.isRequired,
	handleAddNewItem: PropTypes.func.isRequired
};

export default Vaccinations;
