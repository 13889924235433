import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import { withStyles } from '@material-ui/styles';
import Copyrights from '@Components/Copyrights/index';
import RenderLink from '@Components/RenderLink/index';

import { forgottenPasswordStyles } from './styles';
import { sendRecoveryEmail } from '../actions';

const EMAIL_REGEX = new RegExp(
	/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
);

class ForgottenPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			fields: {},
			errors: {}
		};
	}

	onChangeHandler = event => {
		const { fields } = this.state;
		fields[event.target.name] = event.target.value;
		this.setState({ fields, errors: {} });
	};

	onFormSubmit = async event => {
		event.preventDefault();
		if (this.handleValidation()) {
			const { fields } = this.state;
			const data = {
				email: fields.email
			};

			try {
				await this.props.sendRecoveryEmail(data);
				this.props.history.push('/login');
			} catch (e) {
				console.log(e);
			}
		}
	};

	handleValidation = () => {
		const { fields } = this.state;
		const errors = {};
		let formIsValid = true;

		if (!fields.email) {
			formIsValid = false;
			errors.email = 'Field cannot be empty.';
		} else if (!EMAIL_REGEX.test(fields.email)) {
			formIsValid = false;
			errors.email = 'Email format is not valid.';
		}

		this.setState({ errors });

		return formIsValid;
	};

	render() {
		const { classes } = this.props;
		const { errors, fields } = this.state;
		return (
			<div>
				<Paper className={classes.mainImage} />
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<div className={classes.paper}>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Forgotten Password
						</Typography>
						<Typography
							variant="h6"
							align="center"
							color="textSecondary"
							paragraph
						>
							If you have forgotten your password, please enter
							your email address and then press submit.
						</Typography>
						<form
							onSubmit={this.onFormSubmit}
							className={classes.form}
							noValidate
						>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										id="email"
										label="Email Address"
										name="email"
										onChange={this.onChangeHandler}
										error={errors.email}
										helperText={errors.email}
									/>
								</Grid>
							</Grid>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
							>
								Submit
							</Button>
							<Typography
								variant="caption"
								align="center"
								color="textSecondary"
								paragraph
							>
								You will receive an email containing a link to
								the password setup page and given instructions
								on how to reset your password.
							</Typography>
							<Grid container>
								<Grid item xs>
									<RenderLink
										element={Link}
										to="/login"
										variant="body2"
									>
										Sign in
									</RenderLink>
								</Grid>
								<Grid item>
									<RenderLink
										element={Link}
										to="/register"
										variant="body2"
									>
										Sign Up
									</RenderLink>
								</Grid>
							</Grid>
						</form>
					</div>
					<Box mt={5}>
						<Copyrights />
					</Box>
				</Container>
			</div>
		);
	}
}

ForgottenPassword.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	sendRecoveryEmail: PropTypes.func.isRequired
};

export default withStyles(forgottenPasswordStyles)(
	connect(null, {
		sendRecoveryEmail
	})(ForgottenPassword)
);
