export const styles = theme => ({
	icon: {
		marginRight: theme.spacing(2)
	},
	settingsContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6)
	},
	heroButtons: {
		marginTop: theme.spacing(4)
	},
	settingsGrid: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8),
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2)
		}
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	cardMedia: {
		paddingTop: '56.25%' // 16:9
	},
	cardContent: {
		flexGrow: 1
	},
	sectionHeader: {
		marginBottom: '20px',
		marginTop: '20px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.00rem'
		}
	},
	header: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.75rem'
		}
	},
	subHeader: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.00rem'
		}
	},
	link: {
		textDecoration: 'none',
		color: 'rgba(0, 0, 0, 0.87)'
	},
	tempLinkField: {
		width: '100%'
	},
	saveButton: {
		marginTop: '20px'
	}
});
