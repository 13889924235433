export const styles = theme => ({
	icon: {
		marginRight: theme.spacing(2)
	},
	heroContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6)
	},
	heroButtons: {
		marginTop: theme.spacing(4)
	},
	cardGrid: {
		width: '100%',
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8),
		[theme.breakpoints.down('sm')]: {
			paddingTop: 0,
			paddingBottom: 0
		}
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		'&:hover': {
			transform: 'scale(1.1)'
		}
	},
	cardMedia: {
		paddingTop: '56.25%' // 16:9
	},
	cardContent: {
		flexGrow: 1
	},
	link: {
		textDecoration: 'none',
		color: 'rgba(0, 0, 0, 0.87)'
	},
	cardWrapper: {
		'&:hover': {
			transform: 'scale(1.1)'
		}
	},
	header: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.75rem'
		}
	},
	subHeader: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.00rem'
		}
	},
	button: {
		marginRight: theme.spacing(1)
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	stepperWrapper: {
		width: '100%'
	},
	requestContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignIitems: 'center',
		flexDirection: 'column'
	},
	tabsContainer: {
		width: '100%'
	}
});
