export const styles = () => ({
	avatar: {
		display: 'flex',
		flexDirection: 'column',
		height: '140px',
		width: '140px',
		margin: '0 auto'
	},
	input: {
		display: 'none'
	},
	uploadButtons: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '15px'
	}
});
