import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InsertChartIcon from '@material-ui/icons/InsertChart';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';

import { withStyles } from '@material-ui/styles';
import Copyrights from '@Components/Copyrights/index';
import { getDashboardUrl, setDefaultState } from './actions';

import Form from './components/Form/index';

import { styles } from './styles';

class CovidHesitancyAnalysis extends Component {
	getDashboardUrl = async fields => {
		await this.props.getDashboardUrl(fields);
	};

	render() {
		const { classes, showResetCodeButton, errorMessage, url } = this.props;

		if (url) {
			window.open(url, '_blank');
		}
		return (
			<div>
				<Paper className={classes.mainImage} />
				<Container maxWidth="md">
					<Typography
						component="h2"
						variant="h3"
						align="center"
						color="textPrimary"
						gutterBottom
						className={classes.mainHeader}
					>
						Covid Hesitancy Analysis
					</Typography>

					<Avatar className={classes.avatar}>
						<InsertChartIcon />
					</Avatar>
					{errorMessage && (
						<Typography
							className={classes.errorMessage}
							component="h5"
							variant="h5"
							align="center"
							color="textPrimary"
							gutterBottom
						>
							{errorMessage}
						</Typography>
					)}
				</Container>
				<Container component="main" maxWidth="md">
					<CssBaseline />

					{showResetCodeButton ? (
						<div className={classes.resultsWrapper}>
							<div
								style={{
									margin: '0 auto',
									marginTop: '20px',
									marginBottom: '50px'
								}}
							>
								<Typography align="center" variant="subtitle1">
									Thank you ! To reload the page again, press
									the press button
								</Typography>
							</div>
							<div className={classes.resetButtonWrapper}>
								<Button
									style={{
										width: '100%',
										cursor: 'pointer'
									}}
									variant="contained"
									color="primary"
									onClick={this.props.setDefaultState}
								>
									Get url again
								</Button>
							</div>
						</div>
					) : (
						<div className={classes.formWrapper}>
							<Form getDashboardUrl={this.getDashboardUrl} />
						</div>
					)}

					<Box mt={5}>
						<Copyrights />
					</Box>
				</Container>
			</div>
		);
	}
}

CovidHesitancyAnalysis.propTypes = {
	classes: PropTypes.object.isRequired,
	showResetCodeButton: PropTypes.bool.isRequired,
	url: PropTypes.string.isRequired,
	getDashboardUrl: PropTypes.func.isRequired,
	errorMessage: PropTypes.string
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				showResetCodeButton: state.unauthenticatedPages.getIn([
					'covidHesitancyAnalysis',
					'showResetCodeButton'
				]),
				url: state.unauthenticatedPages.getIn([
					'covidHesitancyAnalysis',
					'url'
				])
			};
		},
		{
			getDashboardUrl,
			setDefaultState
		}
	)(CovidHesitancyAnalysis)
);
