import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { useStyles } from './constants';

const Form = props => {
	const classes = useStyles();
	const [fields, setFields] = useState({
		password: ''
	});
	const [errors, setErrors] = useState({
		password: ''
	});
	const { getDashboardUrl } = props;

	const onChangeHandler = event => {
		const newFields = { ...fields };
		newFields[event.target.name] = event.target.value;
		setFields(newFields);
	};

	const handleOnKeyPress = e => {
		if (e.keyCode === 13) {
			handleSubmit();
		}
	};

	const handleSubmit = () => {
		if (handleValidation()) {
			getDashboardUrl(fields);
		}
	};

	const handleValidation = () => {
		const newErrors = {};
		let formIsValid = true;

		if (!fields.password) {
			formIsValid = false;
			newErrors.password = 'Field cannot be empty.';
		}

		setErrors(newErrors);

		return formIsValid;
	};

	return (
		<Fragment>
			<div className={classes.formWrapper}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							fullWidth
							required
							id="Password"
							label="Password"
							name="password"
							onChange={onChangeHandler}
							value={fields.password}
							onKeyDown={handleOnKeyPress}
							error={errors.password}
							helperText={errors.password}
							type="password"
						/>
					</Grid>
				</Grid>
				<Button
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
					onClick={handleSubmit}
				>
					Submit
				</Button>
			</div>
		</Fragment>
	);
};

Form.propTypes = {
	getDashboardUrl: PropTypes.func.isRequired
};

export default Form;
