import * as actionTypes from './actionTypes';

export const getProvidersType = () => {
	return {
		type: actionTypes.GET_PROVIDERS_TYPE,
		requestType: 'GET',
		request: `/providersType`,
		body: {}
	};
};

export const getProvidersConfig = id => {
	return {
		type: actionTypes.GET_PROVIDERS_CONFIG,
		requestType: 'GET',
		request: `/providersConfigIds?providerType=${id}`,
		body: {}
	};
};

export const deleteProvider = id => {
	return {
		type: actionTypes.DELETE_PROVIDER_CONFIG,
		message: 'Provider configuration delete successful.',
		requestType: 'POST',
		request: `/deleteProviderConfig`,
		body: {
			id
		}
	};
};

export const updateProvider = providerData => {
	return {
		type: actionTypes.UPDATE_PROVIDER_CONFIG,
		message: 'Provider configuration update successful.',
		requestType: 'POST',
		request: `/providerConfig`,
		body: {
			...providerData
		}
	};
};

export const addProvider = providerData => {
	return {
		type: actionTypes.ADD_PROVIDER_CONFIG,
		message: 'Provider configuration create successful.',
		requestType: 'POST',
		request: `/providerConfiguration`,
		body: {
			...providerData
		}
	};
};

export const getProvider = id => {
	return {
		type: actionTypes.GET_PROVIDER,
		requestType: 'GET',
		request: `/providerConfig?providerConfig=${id}`,
		body: {
			id
		}
	};
};
