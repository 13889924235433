import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	deleteButton: {
		width: '100%',
		cursor: 'pointer',
		textAlign: 'center',
		backgroundColor: '#d9534f',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		},
		'&:hover': {
			backgroundColor: '#c9302c'
		}
	}
}));

const TextImageDialog = props => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (props.text) {
			setOpen(true);
		}
	}, [props.text]);

	const handleClose = () => {
		setOpen(false);
	};
	const copyText = () => {
		document.execCommand('copy');
	};

	return (
		<React.Fragment>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				className={classes.root}
			>
				<DialogTitle id="form-dialog-title">OCR Dialog</DialogTitle>
				<DialogContent>
					<DialogContentText>{props.text}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button onClick={copyText} color="primary">
						Copy
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

TextImageDialog.propTypes = {
	text: PropTypes.string.isRequired
};

export default TextImageDialog;
