import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@Components/Table/index';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import VisibilityIcon from '@material-ui/icons/Visibility';

const familyHistoryHeadCells = [
	{
		id: 'healthIssue',
		numeric: false,
		disablePadding: false,
		label: 'Health Issue'
	},
	{
		id: 'relationship',
		numeric: false,
		disablePadding: false,
		label: 'Relationship'
	},
	{ id: 'comment', numeric: false, disablePadding: false, label: 'Comment' }
];

const medicalHistoryHeadCells = [
	{
		id: 'name',
		numeric: false,
		disablePadding: false,
		label: 'Name'
	},
	{
		id: 'when',
		numeric: false,
		disablePadding: false,
		label: 'Date',
		isDateFormat: true
	}
];

const surgicalHistoryHeadCells = [
	{
		id: 'name',
		numeric: false,
		disablePadding: false,
		label: 'Name'
	},
	{
		id: 'when',
		numeric: false,
		disablePadding: false,
		label: 'Date',
		isDateFormat: true
	}
];

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		padding: '0 16px'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(4),
		padding: '0 16px'
	},
	header: {
		padding: '20px'
	},
	informationWrapper: {
		maxWidth: '180px',
		textAlign: 'right'
	}
}));

const History = React.memo(props => {
	const classes = useStyles();

	const {
		data,
		showVisibilitySettings,
		changeVisibilitySettings,
		visibilitySchema,
		sectionSchema
	} = props;

	const handleToggle = (id, value) => {
		if (id === '_globalSettings') {
			changeVisibilitySettings('history', !value, [
				'_globalSettings',
				'elements'
			]);
		} else {
			changeVisibilitySettings(id, !value, ['history', 'socialHistory']);
		}
	};

	const handleCheckbox = (index, isSelected, type) => {
		changeVisibilitySettings(index, isSelected, ['history', type]);
	};

	const getSelectedValues = type => {
		const selectedValues = (visibilitySchema[type] || []).map(
			(item, index) => {
				return item && index.toString();
			}
		);
		return selectedValues;
	};

	const listMap = [
		{
			value: data.socialHistory.alcoholUse,
			title: 'Alcohol Use',
			isSelected: visibilitySchema.socialHistory.alcoholUse,
			id: 'alcoholUse'
		},
		{
			value: data.socialHistory.ouncesWeek,
			title: 'Ounces Week',
			isSelected: visibilitySchema.socialHistory.ouncesWeek,
			id: 'ouncesWeek'
		},
		{
			value: data.socialHistory.smokelessTobaccoUse,
			title: 'Smokeless Tobacco',
			isSelected: visibilitySchema.socialHistory.smokelessTobaccoUse,
			id: 'smokelessTobaccoUse'
		},
		{
			value: data.socialHistory.smokelessTobaccoTypes,
			title: 'Smokeless Tobacco Types',
			isSelected: visibilitySchema.socialHistory.smokelessTobaccoTypes,
			id: 'smokelessTobaccoTypes'
		},
		{
			value: data.socialHistory.packsDay,
			title: 'Packs per Day',
			isSelected: visibilitySchema.socialHistory.packsDay,
			id: 'packsDay'
		},
		{
			value: data.socialHistory.smokingTobaccoTypes,
			title: 'Tobacco Types',
			isSelected: visibilitySchema.socialHistory.smokingTobaccoTypes,
			id: 'smokingTobaccoTypes'
		},
		{
			value: data.socialHistory.smokingTobaccoUse,
			title: 'Smoking Tobacco Use',
			isSelected: visibilitySchema.socialHistory.smokingTobaccoUse,
			id: 'smokingTobaccoUse'
		},
		{
			value: data.socialHistory.yearsSmoked,
			title: 'Years Smoked',
			isSelected: visibilitySchema.socialHistory.yearsSmoked,
			id: 'yearsSmoked'
		}
	];

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<Grid item xs={12} sm={12} md={12}>
					<Typography
						align="left"
						gutterBottom
						variant="h5"
						component="h2"
						className={classes.header}
					>
						{showVisibilitySettings && (
							<FormControlLabel
								control={
									<Checkbox
										icon={<VisibilityIcon />}
										checkedIcon={
											<VisibilityIcon color="primary" />
										}
										edge="start"
										checked={sectionSchema}
										tabIndex={-1}
										disableRipple
										onClick={() =>
											handleToggle(
												'_globalSettings',
												sectionSchema
											)
										}
									/>
								}
							/>
						)}
						History
					</Typography>
					<Typography
						align="left"
						gutterBottom
						variant="h6"
						component="h6"
						className={classes.header}
					>
						Family History
					</Typography>
					<Table
						rows={data.familyHistory}
						headCells={familyHistoryHeadCells}
						hasEmptyRows={false}
						showVisibilitySettings={showVisibilitySettings}
						customCheckbox
						handleCheckboxOnChange={(index, isSelected) =>
							handleCheckbox(index, isSelected, 'familyHistory')
						}
						selectedValues={getSelectedValues('familyHistory')}
					/>
					<Typography
						align="left"
						gutterBottom
						variant="h6"
						component="h6"
						className={classes.header}
						style={{ marginTop: '10px' }}
					>
						Medical History
					</Typography>
					<Table
						rows={data.medicalHistory}
						headCells={medicalHistoryHeadCells}
						hasEmptyRows={false}
						showVisibilitySettings={showVisibilitySettings}
						customCheckbox
						handleCheckboxOnChange={(index, isSelected) =>
							handleCheckbox(index, isSelected, 'medicalHistory')
						}
						selectedValues={getSelectedValues('medicalHistory')}
					/>
					<Typography
						align="left"
						gutterBottom
						variant="h6"
						component="h6"
						className={classes.header}
						style={{ marginTop: '10px' }}
					>
						Social History
					</Typography>
					<List className={classes.root}>
						{listMap.map(item => {
							const labelId = `checkbox-list-label-${item.value}`;

							return (
								<ListItem
									key={item.id}
									role={undefined}
									dense
									button
									onClick={() =>
										handleToggle(item.id, item.isSelected)
									}
								>
									<ListItemIcon
										className={classes.checkboxWrapper}
									>
										{showVisibilitySettings && (
											<FormControlLabel
												control={
													<Checkbox
														icon={
															<VisibilityIcon />
														}
														checkedIcon={
															<VisibilityIcon color="primary" />
														}
														name="checked"
														edge="start"
														checked={
															item.isSelected
														}
														tabIndex={-1}
														disableRipple
														inputProps={{
															'aria-labelledby': labelId
														}}
													/>
												}
											/>
										)}
									</ListItemIcon>
									<ListItemText
										id={labelId}
										primary={`${item.title}:`}
									/>
									<ListItemText
										id={labelId}
										align="center"
										primary={item.value || 'N/A'}
										className={classes.informationWrapper}
									/>
								</ListItem>
							);
						})}
					</List>
					<Typography
						align="left"
						gutterBottom
						variant="h6"
						component="h6"
						className={classes.header}
						style={{ marginTop: '10px' }}
					>
						Surgical History
					</Typography>
					<Table
						rows={data.surgicalHistory}
						headCells={surgicalHistoryHeadCells}
						hasEmptyRows={false}
						showVisibilitySettings={showVisibilitySettings}
						customCheckbox
						handleCheckboxOnChange={(index, isSelected) =>
							handleCheckbox(index, isSelected, 'surgicalHistory')
						}
						selectedValues={getSelectedValues('surgicalHistory')}
					/>
				</Grid>
			</Paper>
		</div>
	);
});

History.propTypes = {
	data: PropTypes.array.isRequired,
	visibilitySchema: PropTypes.func.isRequired,
	changeVisibilitySettings: PropTypes.func.isRequired,
	showVisibilitySettings: PropTypes.bool.isRequired,
	sectionSchema: PropTypes.bool.isRequired
};

export default History;
