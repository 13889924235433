import { makeStyles } from '@material-ui/core/styles';

export const EMAIL_REGEX = new RegExp(
	/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
);

export const diseaseMap = [
	{
		id: 'asthma',
		value: 'asthma',
		name: 'Asthma'
	},
	{
		id: 'adhd',
		value: 'adhd',
		name: 'ADHD'
	},
	{
		id: 'autismSpectrum',
		value: 'autismSpectrum',
		name: 'Autism Spectrum'
	},
	{
		id: 'depression',
		value: 'depression',
		name: 'Anxiety/Depression'
	},
	{
		id: 'other',
		value: 'other',
		name: 'Other'
	},
	{
		id: 'none',
		value: 'none',
		name: 'None'
	}
];

export const useStyles = makeStyles(theme => ({
	root: {
		width: '460px',
		maxWidth: 460,
		display: 'flex',
		backgroundColor: '#fafafa'
	},
	avatar: {
		backgroundColor: theme.palette.primary.main
	},
	items: {
		paddingLeft: 0,
		paddingRight: 0
	},
	formControl: {
		minWidth: 120,
		width: '100%'
	},
	disease: {
		textTransform: 'capitalize'
	},
	submit: {
		marginTop: '30px'
	},
	formWrapper: {
		width: '50%',
		margin: '0  auto',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	}
}));
