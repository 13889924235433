import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper
	},
	item: {
		'&:hover': {
			background: theme.palette.primary.hover,
			'&:before': {
				transform: 'scale(1)'
			}
		}
	},
	avatar: {
		margin: theme.spacing(1),
		height: '45px',
		width: '36px',
		marginLeft: '-6px'
	},
	expandIcon: {
		marginLeft: '20px'
	},
	connectionIcon: {
		width: '36px',
		height: '36px',
		margin: 0
	},
	covidReportIcon: {
		width: '25px',
		height: '25px',
		margin: 0
	}
}));
