export const styles = theme => ({
	providerCreateContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6)
	},
	providerCreateGrid: {
		width: '100%',
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8),
		[theme.breakpoints.down('sm')]: {
			paddingTop: 0,
			paddingBottom: 0
		}
	},
	header: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.75rem'
		}
	},
	subHeader: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.00rem'
		}
	},
	tabsContainer: {
		width: '100%'
	},
	saveButton: {
		marginTop: '20px'
	},
	cancelButton: {
		marginTop: '20px'
	},
	nameFiledWrapper: {
		border: '1px solid rgba(0, 0, 0, 0.12)',
		padding: '30px',
		width: '100%'
	}
});
