import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@Components/Table/index';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import VisibilityIcon from '@material-ui/icons/Visibility';

const headCells = [
	{
		id: 'name',
		numeric: false,
		disablePadding: false,
		label: 'Name'
	},
	{
		id: 'date',
		numeric: false,
		disablePadding: false,
		label: 'Date',
		isDateFormat: true
	},
	{ id: 'code', numeric: false, disablePadding: false, label: 'Code' }
];

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		padding: '0 16px'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(4),
		padding: '0 16px'
	},
	header: {
		padding: '20px'
	}
}));

const Diagnosis = React.memo(props => {
	const {
		data,
		showVisibilitySettings,
		visibilitySchema,
		changeVisibilitySettings,
		sectionSchema
	} = props;

	const classes = useStyles();

	const handleCheckbox = (index, isSelected) => {
		changeVisibilitySettings(index, isSelected, ['diagnosis']);
	};

	const handleToggle = () => {
		changeVisibilitySettings('diagnosis', !sectionSchema, [
			'_globalSettings',
			'elements'
		]);
	};

	const selectedValues = (visibilitySchema || []).map((item, index) => {
		return item && index.toString();
	});

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<Grid item xs={12} sm={12} md={12}>
					<Typography
						align="left"
						gutterBottom
						variant="h5"
						component="h2"
						className={classes.header}
					>
						{showVisibilitySettings && (
							<FormControlLabel
								control={
									<Checkbox
										icon={<VisibilityIcon />}
										checkedIcon={
											<VisibilityIcon color="primary" />
										}
										edge="start"
										checked={sectionSchema}
										tabIndex={-1}
										disableRipple
										onClick={handleToggle}
									/>
								}
							/>
						)}
						Diagnosis
					</Typography>
					<Table
						rows={data}
						headCells={headCells}
						hasEmptyRows={false}
						showVisibilitySettings={showVisibilitySettings}
						customCheckbox
						handleCheckboxOnChange={handleCheckbox}
						selectedValues={selectedValues}
					/>
				</Grid>
			</Paper>
		</div>
	);
});

Diagnosis.propTypes = {
	data: PropTypes.array.isRequired,
	changeVisibilitySettings: PropTypes.func.isRequired,
	visibilitySchema: PropTypes.array.isRequired,
	showVisibilitySettings: PropTypes.bool.isRequired,
	sectionSchema: PropTypes.bool.isRequired
};

export default Diagnosis;
