import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

const HealthConditions = props => {
	const handleAdd = () => {
		props.handleAddNewItem(
			[
				{
					healthCondition: '',
					schoolYear: '',
					school: '',
					status: '',
					treatment: '',
					examinedBy: '',
					referredBy: ''
				}
			],
			'HealthConditions',
			'healthConditions'
		);
	};

	const handleField = (event, index) => {
		props.handleField(
			event.target.value,
			'HealthConditions',
			'healthConditions',
			[index, event.target.id]
		);
	};

	const handleDelete = index => {
		props.deleteData(index, 'HealthConditions', 'healthConditions');
	};

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom>
				Health Conditions
			</Typography>
			{props.data.length > 0 ? (
				props.data.map((condition, index) => {
					return (
						<Grid container spacing={3}>
							<Grid item xs={12} md={4}>
								<TextField
									id="healthCondition"
									label="Health Condition"
									fullWidth
									onChange={e => handleField(e, index)}
									value={condition.healthCondition}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<TextField
									id="schoolYear"
									label="School Year"
									fullWidth
									onChange={e => handleField(e, index)}
									value={condition.schoolYear}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="school"
									label="School"
									fullWidth
									onChange={e => handleField(e, index)}
									value={condition.school}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<TextField
									id="status"
									label="Status"
									fullWidth
									onChange={e => handleField(e, index)}
									value={condition.status}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<TextField
									id="treatment"
									label="Treatment"
									fullWidth
									onChange={e => handleField(e, index)}
									value={condition.treatment}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="examinedBy"
									label="Examined By"
									fullWidth
									onChange={e => handleField(e, index)}
									value={condition.examinedBy}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="referredBy"
									label="Referred By"
									fullWidth
									onChange={e => handleField(e, index)}
									value={condition.referredBy}
								/>
							</Grid>
							<Grid item xs={12} md={1}>
								<IconButton
									aria-label="delete"
									color="primary"
									style={{
										backgroundColor: '#d9534f',
										'&:hover': {
											backgroundColor: '#c9302c'
										},
										color: '#ffffff',
										height: '45px',
										width: '45px',
										marginTop: '5px',
										cursor: 'pointer'
									}}
									onClick={() => handleDelete(index)}
								>
									<DeleteIcon />
								</IconButton>
							</Grid>
						</Grid>
					);
				})
			) : (
				<Typography component="h1" variant="h4" align="center">
					No Health Conditions
				</Typography>
			)}
			<Grid contariner spacing={3}>
				<Button
					style={{ marginTop: '20px' }}
					variant="contained"
					color="primary"
					onClick={handleAdd}
				>
					Add health conditions
				</Button>
			</Grid>
		</React.Fragment>
	);
};

HealthConditions.propTypes = {
	data: PropTypes.array.isRequired,
	handleField: PropTypes.func.isRequired,
	deleteData: PropTypes.func.isRequired,
	handleAddNewItem: PropTypes.func.isRequired
};

export default HealthConditions;
