import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	CssBaseline,
	Grid,
	Typography,
	Container,
	Avatar,
	Box
} from '@material-ui/core';

import timelineScrShot from '@Images/timeline.png';

import { withStyles } from '@material-ui/styles';

import DeleteDialog from './components/DeleteDialog';
import EditDialog from './components/EditDialog';
import TwoFactorAuthenticationForm from './components/TwoFactorAuthenticationForm';

import { styles } from './styles';
import { CARDS } from './constants';
import {
	updatePerson,
	photoUpload,
	deletePerson,
	getPerson,
	getProfilesList,
	getProvidersWaitingForSms,
	sendSmsCode
} from './actions';

import { setSelectedPerson } from '../Persons/actions';

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openSmsDialog: false
		};
	}

	componentDidMount = async () => {
		this.getPersonInformation();
	};

	componentDidUpdate = async prevProps => {
		if (
			this.props.match.params.personId !== prevProps.match.params.personId
		) {
			this.getPersonInformation();
		}
	};

	getPersonInformation = async () => {
		const { personId } = this.props.match.params;
		const { history } = this.props;

		const res = await this.props.getPerson(personId);

		await this.props.getProfilesList(personId);

		await this.props.getProvidersWaitingForSms(personId);

		const newPersonState = {
			person: {
				id: personId,
				name: res.response.get('name'),
				photo: res.response.get('photo')
			}
		};

		await this.props.setSelectedPerson(newPersonState.person);

		const openSmsDialog =
			(this.props.location.state && this.props.location.state.sendSms) ||
			false;

		history.replace({ ...history.location, state: newPersonState });

		this.setState({
			openSmsDialog
		});
	};

	deletePerson = async () => {
		try {
			await this.props.deletePerson(this.props.person.id);
			this.props.history.push('/');
		} catch (e) {
			console.log(e);
		}
	};

	updatePerson = async personData => {
		const { history, person } = this.props;

		try {
			const res = await this.props.updatePerson(personData);
			const newPersonState = {
				person: {
					...person,
					name: res.response.get('name'),
					photo: res.response.get('photo')
				}
			};

			history.replace({ ...history.location, state: newPersonState });
		} catch (e) {
			console.log(e);
		}
	};

	editPerson = async (personData, formData) => {
		try {
			if (formData) {
				const res = await this.props.photoUpload(formData);
				personData.photo = res.response.get('fileName');
			}

			this.updatePerson({
				...personData
			});
		} catch (e) {
			console.log(e);
		}
	};

	handleSendSmsCode = async (smsCode, providerId) => {
		try {
			await this.props.sendSmsCode(smsCode, providerId);

			const { person } = this.props;

			await this.props.getProvidersWaitingForSms(person.id);
		} catch (e) {
			console.log(e);
		}
	};

	handleClickOpen = () => {
		this.setState({
			openSmsDialog: true
		});
	};

	closeSmsDialog = () => {
		this.setState({
			openSmsDialog: false
		});
	};

	render = () => {
		const {
			classes,
			profiles,
			providersWaitingForSms,
			person
		} = this.props;
		const { openSmsDialog } = this.state;

		return (
			<React.Fragment>
				<CssBaseline />
				<main>
					<div className={classes.dashboardContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
								className={classes.header}
							>
								{person.name}
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
								className={classes.subHeader}
							>
								Please see below the set of actions – for
								example you can enter school & health
								information, connect to external school & health
								account and share your health information.
							</Typography>
							<div className={classes.buttonsContainer}>
								<Grid container spacing={2} justify="center">
									<div>
										<Avatar
											className={classes.avatar}
											alt={person.name}
											src={person.photo}
										/>
									</div>

									{/* {providersWaitingForSms.length > 0 && (
										<Grid item md={12} xs={12}>
											<TwoFactorAuthenticationForm
												handleClickOpen={
													this.handleClickOpen
												}
												sendSmsCode={
													this.handleSendSmsCode
												}
												providersWaitingForSms={
													providersWaitingForSms
												}
												open={openSmsDialog}
												closeDialog={
													this.closeSmsDialog
												}
											/>
										</Grid>
									)} */}
									<div
										className={
											classes.userActionsIconContent
										}
									>
										<EditDialog
											editPerson={this.editPerson}
											person={person}
										/>
										<DeleteDialog
											deletePerson={this.deletePerson}
											person={person}
										/>
									</div>
								</Grid>
							</div>
						</Container>
					</div>
					<Container className={classes.cardGrid} maxWidth="md">
						<Grid container spacing={4}>
							<Grid item container xs={12} sm={6} md={6}>
								<fieldset
									className={classes.btnsFieldset}
									style={{ marginBottom: '10px' }}
								>
									<legend
										className={classes.btnsFieldsetTitle}
									>
										Add/Edit
									</legend>
									<div
										className={classes.btnsFieldsetContent}
									>
										<Box mb="16px">
											<Button
												component={RouterLink}
												className={classes.actionButton}
												variant="contained"
												color="primary"
												to={{
													pathname: `/dashboard/${person.id}/newSelfProfile`,
													state: {
														person,
														showMenu: true
													}
												}}
											>
												Add/Edit Information
											</Button>
										</Box>

										<Button
											component={RouterLink}
											className={classes.actionButton}
											to={{
												pathname: `/dashboard/${person.id}/newProviderProfile`,
												state: {
													person
												}
											}}
											variant="contained"
											color="primary"
										>
											Add Connection
										</Button>
									</div>
								</fieldset>
								<fieldset className={classes.btnsFieldset}>
									<legend
										className={classes.btnsFieldsetTitle}
									>
										View/Share
									</legend>
									<div
										className={classes.btnsFieldsetContent}
									>
										<Box mb="16px">
											<Button
												component={RouterLink}
												className={classes.actionButton}
												to={{
													pathname: `/dashboard/${person.id}/timeline`,
													state: {
														person
													}
												}}
												variant="contained"
												color="primary"
											>
												View Timeline
											</Button>
										</Box>
										<Button
											component={RouterLink}
											className={classes.actionButton}
											to={{
												pathname: `/dashboard/${person.id}/exportSummary`,
												state: {
													person
												}
											}}
											variant="contained"
											color="primary"
										>
											Share Summary
										</Button>
									</div>
								</fieldset>
							</Grid>
							<Grid
								item
								className={classes.cardWrapper}
								xs={12}
								sm={6}
								md={6}
							>
								<RouterLink
									to={{
										pathname: `/dashboard/${person.id}/timeline`,
										state: {
											person
										}
									}}
								>
									<img
										src={timelineScrShot}
										alt="Timeline screenshot img"
										className={classes.timelineImg}
									/>
								</RouterLink>
							</Grid>
							{CARDS.map(card => (
								<Grid
									item
									className={classes.cardWrapper}
									key={card}
									xs={12}
									sm={6}
									md={6}
								>
									<RouterLink
										className={classes.link}
										to={{
											pathname: card.url(person.id),
											state: { person }
										}}
									>
										<Card className={classes.card}>
											<CardMedia
												className={classes.cardMedia}
												image={card.image}
												title="Image title"
											/>
											<CardContent
												className={classes.cardContent}
											>
												<Typography
													gutterBottom
													variant="h5"
													component="h2"
												>
													{card.title}
												</Typography>
												<Typography>
													{card.description}
												</Typography>
											</CardContent>
											<CardActions>
												<Button
													size="small"
													color="primary"
												>
													Go to page
												</Button>
											</CardActions>
										</Card>
									</RouterLink>
								</Grid>
							))}
						</Grid>
					</Container>
				</main>
			</React.Fragment>
		);
	};
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	person: PropTypes.object.isRequired,
	updatePerson: PropTypes.func.isRequired,
	getPerson: PropTypes.func.isRequired,
	photoUpload: PropTypes.func.isRequired,
	deletePerson: PropTypes.func.isRequired,
	getProfilesList: PropTypes.func.isRequired,
	sendSmsCode: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	providersWaitingForSms: PropTypes.array.isRequired,
	profiles: PropTypes.object.isRequired,
	getProvidersWaitingForSms: PropTypes.func.isRequired,
	setSelectedPerson: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			personId: PropTypes.string.isRequired
		})
	})
};

Dashboard.defaultProps = {
	match: {}
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				person: state.dashboard.get('person').toJS(),
				profiles: state.dashboard.get('profiles').toJS(),
				providersWaitingForSms: state.dashboard
					.get('providersWaitingForSms')
					.toJS()
			};
		},
		{
			updatePerson,
			photoUpload,
			deletePerson,
			getPerson,
			getProfilesList,
			getProvidersWaitingForSms,
			sendSmsCode,
			setSelectedPerson
		}
	)(Dashboard)
);
