import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	deleteButton: {
		cursor: 'pointer'
	}
}));

const DeleteDialog = props => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleDelete = deletePerson => {
		deletePerson();
		handleClose();
	};

	return (
		<>
			<DeleteIcon
				variant="contained"
				color="secondary"
				className={classes.deleteButton}
				onClick={handleClickOpen}
			/>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				className={classes.root}
			>
				<DialogTitle id="form-dialog-title">
					Delete Person Confirmation
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete this person?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button
						onClick={() => handleDelete(props.deletePerson)}
						color="primary"
					>
						Confirm Delete Person
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

DeleteDialog.propTypes = {
	deletePerson: PropTypes.func.isRequired
};

export default DeleteDialog;
