import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		width: '100%'
	},
	optionName: {
		textTransform: 'capitalize'
	}
}));

const SettingsSelect = props => {
	const classes = useStyles();

	return (
		<FormControl variant="outlined" className={classes.formControl}>
			<InputLabel id="options-select-label">{props.title}</InputLabel>
			<Select
				labelId="options-select-label"
				id="options-select"
				value={props.value}
				className={classes.optionName}
				onChange={props.handleChange}
				label={props.title}
			>
				{props.options.map(option => {
					return (
						<MenuItem
							className={classes.optionName}
							key={option.value}
							value={option.value}
						>
							{option.label}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

SettingsSelect.propTypes = {
	value: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	title: PropTypes.string.isRequired
};

export default SettingsSelect;
