export const styles = theme => ({
	usersContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6)
	},
	usersGrid: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8)
	},
	subHeader: {
		marginBottom: '20px',
		marginTop: '20px'
	},
	refreshButton: {
		marginBottom: '20px'
	},
	searchButton: {
		marginBottom: '20px',
		marginLeft: '20px'
	},
	buttonsGrid: {
		marginTop: '20px',
		marginBottom: '20px'
	}
});
