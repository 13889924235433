import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { CssBaseline, Grid, Container } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '@Components/TabPanel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
/* Clear information for selected person in left navigation */
import { clearSelectedPerson } from '@SharedActions';

import {
	getProvidersType,
	getProvidersConfig,
	deleteProvider,
	updateProvider,
	addProvider,
	getProvider
} from './actions';

import List from './components/List/index';
import { styles } from './styles';

class ProviderList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: 0,
			openDialog: false,
			providerId: ''
		};
	}

	componentDidMount = async () => {
		this.props.clearSelectedPerson();
		const providersTypeRes = await this.props.getProvidersType();

		/* Get first provider type from list for initial load */
		await this.props.getProvidersConfig(
			providersTypeRes.response.getIn([0, 'id'])
		);
	};

	handleChange = async (event, newValue) => {
		const { providersType } = this.props;
		await this.props.getProvidersConfig(providersType[newValue].id);
		this.setState({ value: newValue });
	};

	handleChangeIndex = index => {
		this.setState({ value: index });
	};

	copyProvider = async providerInfo => {
		const { providersType } = this.props;
		const { value } = this.state;
		try {
			const providerData = await this.props.getProvider(providerInfo.id);

			const newProvider = {
				name: `copy_${providerData.response.get('name')}`,
				providerTypeId: providerData.response.get('providerType'),
				configuration: providerData.response.get('configuration')
			};

			await this.props.addProvider(newProvider);
			await this.props.getProvidersConfig(providersType[value].id);
		} catch (e) {
			console.log(e);
		}
	};

	handleDeleteButton = providerInfo => {
		this.setState({
			openDialog: true,
			providerId: providerInfo.id
		});
	};

	handleCloseDialog = () => {
		this.setState({
			openDialog: false,
			providerId: ''
		});
	};

	handleDeleteProvider = async () => {
		const { providerId, value } = this.state;
		const { providersType } = this.props;

		await this.props.deleteProvider(providerId);
		await this.props.getProvidersConfig(providersType[value].id);

		this.handleCloseDialog();
	};

	handleUpdate = (providerInfo, type) => {
		this.props.history.push({
			pathname: `/admin/providers/providerUpdate/${type.name}/${type.id}`,
			state: {
				type: 'update',
				providerType: type.name,
				providerTypeId: type.id,
				providerId: providerInfo.id
			}
		});
	};

	render() {
		const { classes, providersType, providers } = this.props;
		const { value, openDialog } = this.state;

		return (
			<React.Fragment>
				<CssBaseline />
				<main>
					<div className={classes.providerListContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
								className={classes.header}
							>
								Providers Administration
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
								className={classes.subHeader}
							>
								Please select type of providers
							</Typography>
						</Container>
					</div>
					<Container
						className={classes.providerListGrid}
						maxWidth="md"
					>
						<Grid container spacing={4}>
							<div className={classes.tabsContainer}>
								<AppBar position="static" color="default">
									<Tabs
										value={value}
										onChange={this.handleChange}
										indicatorColor="primary"
										textColor="primary"
										variant="fullWidth"
										aria-label="full width tabs example"
									>
										{providersType.map(type => (
											<Tab label={type.name} />
										))}
									</Tabs>
								</AppBar>

								<SwipeableViews
									axis="x-reverse"
									index={value}
									onChangeIndex={this.handleChangeIndex}
								>
									{providersType.map((type, index) => (
										<TabPanel
											value={value}
											index={index}
											dir="ltr"
										>
											<List
												providers={providers}
												deleteProvider={
													this.handleDeleteButton
												}
												updateProvider={providerInfo =>
													this.handleUpdate(
														providerInfo,
														type
													)
												}
												copyProvider={this.copyProvider}
											/>

											<Button
												component={RouterLink}
												style={{
													cursor: 'pointer'
												}}
												variant="contained"
												color="primary"
												to={{
													pathname: `/admin/providers/providerCreate/${type.name}/${type.id}`,
													state: {
														type: 'create',
														providerType: type.name,
														providerTypeId: type.id
													}
												}}
											>
												Create New Provider
											</Button>
										</TabPanel>
									))}
								</SwipeableViews>
							</div>
						</Grid>
					</Container>
				</main>
				<Dialog
					open={openDialog}
					onClose={this.handleCloseDialog}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">
						Delete Provider Configuration
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to delete this provider
							config?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={this.handleCloseDialog}
							color="primary"
						>
							Cancel
						</Button>
						<Button
							onClick={this.handleDeleteProvider}
							color="primary"
						>
							Confirm Delete Provider
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	}
}

ProviderList.propTypes = {
	classes: PropTypes.object.isRequired,
	providersType: PropTypes.array.isRequired,
	providers: PropTypes.array.isRequired,
	getProvidersType: PropTypes.func.isRequired,
	deleteProvider: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	getProvider: PropTypes.func.isRequired,
	addProvider: PropTypes.func.isRequired,
	clearSelectedPerson: PropTypes.func.isRequired,
	getProvidersConfig: PropTypes.func.isRequired
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				providersType: state.admin
					.getIn(['providers', 'providerList', 'providersType'])
					.toJS(),
				providers: state.admin
					.getIn(['providers', 'providerList', 'providers'])
					.toJS()
			};
		},
		{
			getProvidersType,
			getProvidersConfig,
			deleteProvider,
			updateProvider,
			addProvider,
			getProvider,
			clearSelectedPerson
		}
	)(ProviderList)
);
