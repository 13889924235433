import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	CssBaseline,
	Typography,
	Container,
	Grid,
	Button
} from '@material-ui/core';

import CSVReader from '@Components/CSVReader/index';
import { withStyles } from '@material-ui/styles';
/* Clear information for selected person in left navigation */
import { clearSelectedPerson } from '@SharedActions';
import { styles } from './styles';

import { importProfile } from './actions';

const PARSE_OPTIONS = {
	header: true,
	dynamicTyping: true,
	skipEmptyLines: true,
	transformHeader: header => header.toLowerCase().replace(/\W/g, '_')
};

class ProfileImporter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			fileInfo: {
				name: ''
			}
		};
	}

	componentDidMount = () => {
		this.props.clearSelectedPerson();
	};

	importFile = async () => {
		const { data } = this.state;

		try {
			await this.props.importProfile(data);
		} catch (e) {
			console.log(e);
		}
	};

	handleFileLoad = (data, fileInfo) => {
		this.setState({ data, fileInfo });
	};

	render() {
		const { classes } = this.props;
		const { fileInfo } = this.state;

		return (
			<Fragment>
				<CssBaseline />
				<main>
					<div className={classes.importProfilesContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
							>
								Import Profiles
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
							>
								Please upload the CSV file with the profiles
								information.
							</Typography>
						</Container>

						<Container
							className={classes.importProfilesGrid}
							maxWidth="sm"
						>
							<Grid style={{ marginBottom: '10px' }} item xs={12}>
								<CSVReader
									onFileLoaded={this.handleFileLoad}
									parserOptions={PARSE_OPTIONS}
									inputId="profiles-input-csv"
									buttonStyles={{
										width: '80%'
									}}
								/>
							</Grid>
							<Grid style={{ marginBottom: '10px' }} item xs={12}>
								<div className={classes.importButtonWrapper}>
									<Button
										variant="contained"
										color="primary"
										disabled={!fileInfo.name}
										onClick={this.importFile}
										style={{
											width: '80%'
										}}
									>
										Import Profiles
									</Button>
								</div>
							</Grid>
							{fileInfo.name && (
								<Grid
									style={{ marginBottom: '10px' }}
									item
									xs={12}
								>
									<Typography
										variant="subtitle1"
										align="center"
									>
										{`File Name: ${fileInfo.name}`}
									</Typography>
								</Grid>
							)}
						</Container>
					</div>
				</main>
			</Fragment>
		);
	}
}

ProfileImporter.propTypes = {
	importProfile: PropTypes.func.isRequired,
	clearSelectedPerson: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(
	connect(null, { importProfile, clearSelectedPerson })(ProfileImporter)
);
