import * as actionTypes from './actionTypes';

export const importProfile = data => {
	return {
		type: actionTypes.IMPORT_PROFILE,
		requestType: 'POST',
		message: 'File is imported successful.',
		request: `/import`,
		body: {
			data,
			importType: 'profile'
		}
	};
};
