import natureWallpapers1 from '@Images/backgrounds/nature-wallpapers-1.jpg';
import natureWallpapers2 from '@Images/backgrounds/nature-wallpapers-2.jpg';
import natureWallpapers3 from '@Images/backgrounds/nature-wallpapers-3.jpg';
import natureWallpapers4 from '@Images/backgrounds/nature-wallpapers-4.jpg';
import natureWallpapers5 from '@Images/backgrounds/nature-wallpapers-5.jpg';
import natureWallpapers6 from '@Images/backgrounds/nature-wallpapers-6.jpg';
import natureWallpapers7 from '@Images/backgrounds/nature-wallpapers-7.jpg';
import natureWallpapers8 from '@Images/backgrounds/nature-wallpapers-8.jpg';
import natureWallpapers9 from '@Images/backgrounds/nature-wallpapers-9.jpg';
import natureWallpapers10 from '@Images/backgrounds/nature-wallpapers-10.jpg';
import natureWallpapers11 from '@Images/backgrounds/nature-wallpapers-11.jpg';
import natureWallpapers12 from '@Images/backgrounds/nature-wallpapers-12.jpg';
import natureWallpapers13 from '@Images/backgrounds/nature-wallpapers-13.jpg';
import natureWallpapers14 from '@Images/backgrounds/nature-wallpapers-14.jpg';
import natureWallpapers15 from '@Images/backgrounds/nature-wallpapers-15.jpg';

const imageMap = {
	1: natureWallpapers1,
	2: natureWallpapers2,
	3: natureWallpapers3,
	4: natureWallpapers4,
	5: natureWallpapers5,
	6: natureWallpapers6,
	7: natureWallpapers7,
	8: natureWallpapers8,
	9: natureWallpapers9,
	10: natureWallpapers10,
	11: natureWallpapers11,
	12: natureWallpapers12,
	13: natureWallpapers13,
	14: natureWallpapers14,
	15: natureWallpapers15
};

const randomBackgroundImage = imageMap[Math.floor(Math.random() * 15) + 1];

export const loginStyles = theme => ({
	root: {
		height: '100vh'
	},
	image: {
		backgroundImage: `url(${randomBackgroundImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundColor:
			theme.palette.type === 'light'
				? theme.palette.grey[50]
				: theme.palette.grey[900],
		backgroundSize: 'cover',
		backgroundPosition: 'center'
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	logo: {
		width: 150,
		height: 200
	}
});

export const registerStyles = theme => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	mainImage: {
		position: 'relative',
		backgroundColor: theme.palette.grey[800],
		color: theme.palette.common.white,
		marginBottom: theme.spacing(4),
		backgroundImage: `url(${randomBackgroundImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		height: '240px'
	},
	agreeError: {
		color: '#f44336'
	}
});

export const restPasswordStyles = theme => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	mainImage: {
		position: 'relative',
		backgroundColor: theme.palette.grey[800],
		color: theme.palette.common.white,
		marginBottom: theme.spacing(4),
		backgroundImage: `url(${randomBackgroundImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		height: '240px'
	}
});

export const forgottenPasswordStyles = theme => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	mainImage: {
		position: 'relative',
		backgroundColor: theme.palette.grey[800],
		color: theme.palette.common.white,
		marginBottom: theme.spacing(4),
		backgroundImage: `url(${randomBackgroundImage})`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		height: '240px'
	}
});
