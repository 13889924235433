import * as actionTypes from './actionTypes';

export const getJobs = () => {
	return {
		type: actionTypes.GET_JOBS,
		requestType: 'GET',
		request: `/profilesJobs`,
		body: {}
	};
};

export const deleteJob = jobId => {
	return {
		type: actionTypes.DELETE_JOB,
		requestType: 'POST',
		request: `/deleteJob`,
		body: {
			jobId
		}
	};
};
