import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		width: '100%'
	},
	recipient: {
		textTransform: 'capitalize'
	},
	requestButton: {
		cursor: 'pointer',
		textAlign: 'center',
		width: '100%',
		margin: '0 auto',
		marginBottom: '20px',
		maxWidth: '400px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		}
	}
}));

const MedicalAdviceDialog = props => {
	const classes = useStyles();
	const { medicalQuestions, requestMedicalAdvice } = props;
	const [recipient, setRecipient] = React.useState('');
	const [subject, setSubject] = React.useState('');
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setRecipient('');
		setSubject('');
	};

	const handleRecipient = event => {
		setRecipient(event.target.value);
	};

	const handleSubject = event => {
		setSubject(event.target.value);
	};

	const handleSendButton = () => {
		requestMedicalAdvice({ recipient, subject });
		handleClose();
	};

	return (
		<React.Fragment>
			<Button
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
				className={classes.requestButton}
			>
				Request for medical advice
			</Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				className={classes.root}
			>
				<DialogTitle id="form-dialog-title">
					Request for medical advice
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Request for medical advice
					</DialogContentText>

					<FormControl
						variant="outlined"
						className={classes.formControl}
					>
						<InputLabel id="recipient-select-label">
							Recipient
						</InputLabel>
						<Select
							labelId="recipient-select-label"
							id="recipient-select"
							value={recipient}
							className={classes.recipient}
							onChange={handleRecipient}
						>
							{medicalQuestions.recipients.map(item => {
								return (
									<MenuItem
										className={classes.recipient}
										key={item.name}
										value={item.name}
									>
										{item.name}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
					<FormControl
						variant="outlined"
						className={classes.formControl}
					>
						<InputLabel id="recipient-select-label">
							Object
						</InputLabel>
						<Select
							labelId="subject-select-label"
							id="subject-select"
							value={subject}
							className={classes.subject}
							onChange={handleSubject}
						>
							{medicalQuestions.subjects.map(item => {
								return (
									<MenuItem
										className={classes.subject}
										key={item.name}
										value={item.name}
									>
										{item.name}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleSendButton} color="primary">
						Send Summary
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

MedicalAdviceDialog.propTypes = {
	requestMedicalAdvice: PropTypes.func.isRequired,
	medicalQuestions: PropTypes.object.isRequired
};

export default MedicalAdviceDialog;
