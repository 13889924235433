export const styles = theme => ({
	dashboardContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6)
	},
	buttonsContainer: {
		marginTop: theme.spacing(4)
	},
	cardGrid: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(8),
		[theme.breakpoints.down('sm')]: {
			paddingTop: 0,
			paddingBottom: 0
		}
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		'&:hover': {
			backgroundColor: theme.palette.primary.hover
		}
	},
	cardMedia: {
		paddingTop: '56.25%' // 16:9
	},
	cardContent: {
		flexGrow: 1
	},
	link: {
		textDecoration: 'none',
		color: 'rgba(0, 0, 0, 0.87)'
	},
	cardWrapper: {
		/* 	'&:hover': {
			transform: 'scale(1.1)'
		} */
	},
	header: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.75rem'
		}
	},
	subHeader: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.00rem'
		}
	},
	avatar: {
		display: 'flex',
		flexDirection: 'column',
		height: '140px',
		width: '140px',
		margin: '0 auto'
	},
	actionButton: {
		width: '100%',
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		},
		fontWeight: 100,
		textTransform: 'none'
	},
	btnsFieldset: {
		borderColor: theme.palette.primary.main,
		width: '100%',
		paddingInlineStart: '1em'
	},
	btnsFieldsetTitle: {
		color: theme.palette.primary.main,
		fontSize: '24px',
		fontWeight: 400
	},
	btnsFieldsetContent: {
		padding: '5px 10px',
		display: 'flex',
		flexDirection: 'column'
	},
	timelineImg: {
		height: '310px'
	},
	mb10px: {
		marginBottom: '10px'
	},
	userActionsIconContent: {
		display: 'flex',
		flexFlow: 'column',
		justifyContent: 'center',
		marginLeft: '20px'
	}
});
