import React from 'react';
import { Link, Typography } from '@material-ui/core';

export default function Copyrights() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="https://me.myphi.info/">
				My PHI
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}
