import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	root: {
		width: '460px',
		maxWidth: 460,
		display: 'flex',
		backgroundColor: '#fafafa'
	},
	avatar: {
		backgroundColor: theme.palette.primary.main
	},
	items: {
		paddingLeft: 0,
		paddingRight: 0
	},
	formControl: {
		minWidth: 120,
		width: '100%'
	},
	disease: {
		textTransform: 'capitalize'
	},
	submit: {
		marginTop: '30px'
	},
	formWrapper: {
		width: '50%',
		margin: '0  auto',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	}
}));
