/* eslint-disable react/no-unescaped-entities */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { Button, CssBaseline, Grid, Container } from '@material-ui/core';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

import ProviderSelect from './components/ProviderSelect';
import ProviderForm from './components/ProviderForm';
import RequestNewProviderForm from './components/RequestNewProviderForm';
import TwoFactorAuthenticationForm from './components/TwoFactorAuthenticationForm';
import ReviewComponent from './components/ReviewComponent';

import {
	getProviders,
	getProviderType,
	addProfileFromProvider,
	requestNewProvider,
	getProvidersWaitingForSms,
	sendSmsCode
} from './actions';

import { styles } from './styles';
import { STEPS, validationMap } from './constants';

class Connection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeStep: 0,
			selectedProviderId: '',
			providerUsername: '',
			providerPassword: ''
		};
	}

	componentDidMount = async () => {
		await this.props.getProviders();
		await this.props.getProviderType();

		const { personId } = this.props.match.params;

		await this.props.getProvidersWaitingForSms(personId);
	};

	handleNext = () => {
		const { activeStep } = this.state;
		this.setState({
			activeStep: activeStep + 1
		});
	};

	handleBack = () => {
		const { activeStep } = this.state;
		this.setState({
			activeStep: activeStep - 1
		});
	};

	handleReset = () => {
		this.setState({
			activeStep: 0,
			selectedProviderId: '',
			providerUsername: '',
			providerPassword: ''
		});
	};

	setProvider = event => {
		this.setState({
			selectedProviderId: event.target.value
		});
	};

	isValidate = () => {
		const { activeStep } = this.state;
		let isValidate = false;

		validationMap[activeStep].map(item => {
			if (!this.state[item]) {
				isValidate = true;
			}
		});

		return isValidate;
	};

	getStepContent = step => {
		const {
			selectedProviderId,
			providerUsername,
			providerPassword
		} = this.state;
		const { providers } = this.props;

		switch (step) {
			case 0:
				return (
					<ProviderSelect
						handleChange={this.setProvider}
						providerId={selectedProviderId}
						providers={providers}
					/>
				);
			case 1:
				return (
					<ProviderForm
						handleChange={this.handleTextField}
						values={{
							providerUsername,
							providerPassword
						}}
					/>
				);
			case 2:
				return (
					<ReviewComponent
						values={{
							selectedProviderId,
							providerUsername,
							providerPassword
						}}
						providers={providers}
					/>
				);
			default:
				return 'Unknown step';
		}
	};

	submitHandler = () => {
		const {
			selectedProviderId,
			providerUsername,
			providerPassword
		} = this.state;

		const data = {
			providerId: selectedProviderId,
			personId: this.props.match.params.personId,
			username: providerUsername,
			password: providerPassword
		};

		this.props.addProfileFromProvider(data);
		this.handleNext();
	};

	handleTextField = event => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleSendSmsCode = async (smsCode, providerId) => {
		try {
			await this.props.sendSmsCode(smsCode, providerId);

			const { personId } = this.props.match.params;

			await this.props.getProvidersWaitingForSms(personId);
		} catch (e) {
			console.log(e);
		}
	};

	render = () => {
		const { classes, providersType, providersWaitingForSms } = this.props;

		const { activeStep } = this.state;

		return (
			<Fragment>
				<CssBaseline />
				<main>
					<div className={classes.profileCreateContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
								className={classes.header}
							>
								Add a New Connection
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
								className={classes.subHeader}
							>
								Create a new connection using the provider list
								below. Select your provider then provide the
								login and password that you use with that
								provider. Don't see your Provider? Scroll down
								to request their addition.
							</Typography>
						</Container>
					</div>
					<Container
						className={classes.profileCrateGrid}
						maxWidth="md"
					>
						<Grid container spacing={4}>
							<div className={classes.stepperWrapper}>
								<Stepper activeStep={activeStep}>
									{STEPS.map((label, index) => {
										const stepProps = {};
										const labelProps = {};

										return (
											<Step key={label} {...stepProps}>
												<StepLabel {...labelProps}>
													<div
														className={
															classes.stepLabel
														}
													>
														{label}
													</div>
												</StepLabel>
											</Step>
										);
									})}
								</Stepper>
								<div>
									{activeStep === STEPS.length ? (
										<div>
											<Container
												style={{ marginBottom: '50px' }}
												fixed
											>
												<Typography
													variant="h5"
													align="center"
													paragraph
												>
													Profile create request sent
													successful. You can check
													create status in Connections
													menu.
												</Typography>
											</Container>
											<Button
												onClick={this.handleReset}
												className={classes.button}
												variant="contained"
												color="primary"
											>
												Select another provider
											</Button>
										</div>
									) : (
										<div>
											<Container
												style={{ marginBottom: '50px' }}
												fixed
											>
												{this.getStepContent(
													activeStep
												)}
											</Container>
											<div
												className={
													classes.buttonsWrapper
												}
											>
												<Button
													disabled={activeStep === 0}
													variant="contained"
													color="primary"
													onClick={this.handleBack}
												>
													Back
												</Button>
												<Button
													variant="contained"
													color="primary"
													disabled={this.isValidate()}
													onClick={
														activeStep ===
														STEPS.length - 1
															? this.submitHandler
															: this.handleNext
													}
												>
													{activeStep ===
													STEPS.length - 1
														? 'Finish'
														: 'Next'}
												</Button>
											</div>
										</div>
									)}
								</div>
							</div>
						</Grid>
					</Container>
					<div>
						<Container
							maxWidth="sm"
							className={classes.requestContainer}
						>
							<Typography
								variant="h4"
								align="center"
								paragraph
								className={classes.subHeader}
							>
								Request New Provider
							</Typography>
							<Typography
								variant="h5"
								align="center"
								paragraph
								className={classes.subHeader}
								color="textSecondary"
								style={{
									marginBottom: '40px',
									marginTop: '40px'
								}}
							>
								If you don't see your provider listed, you can
								request a new one. Click below to send us their
								details.
							</Typography>
							<RequestNewProviderForm
								providersType={providersType}
								requestNewProvider={
									this.props.requestNewProvider
								}
							/>
						</Container>
					</div>

					{providersWaitingForSms.length > 0 && (
						<div>
							<Container
								maxWidth="sm"
								className={classes.smsContainer}
							>
								<Typography
									variant="h4"
									align="center"
									paragraph
									className={classes.subHeader}
								>
									Send Sms Code
								</Typography>
								<Typography
									variant="h5"
									align="center"
									paragraph
									className={classes.subHeader}
									color="textSecondary"
									style={{
										marginBottom: '40px',
										marginTop: '40px'
									}}
								>
									If your provider has two factor
									authentication enabled and requires SMS code
									- after you receive the code please enter it
									by clicking the button below.
								</Typography>
								<TwoFactorAuthenticationForm
									providersWaitingForSms={
										providersWaitingForSms
									}
									sendSmsCode={this.handleSendSmsCode}
								/>
							</Container>
						</div>
					)}
				</main>
			</Fragment>
		);
	};
}

Connection.propTypes = {
	getProviders: PropTypes.func.isRequired,
	getProviderType: PropTypes.func.isRequired,
	requestNewProvider: PropTypes.func.isRequired,
	addProfileFromProvider: PropTypes.func.isRequired,
	getProvidersWaitingForSms: PropTypes.func.isRequired,
	sendSmsCode: PropTypes.func.isRequired,
	providers: PropTypes.array.isRequired,
	providersWaitingForSms: PropTypes.array.isRequired,
	providersType: PropTypes.array.isRequired,
	classes: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			personId: PropTypes.string.isRequired
		})
	})
};

Connection.defaultProps = {
	match: {}
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				providers: state.connection.get('providers').toJS(),
				providersType: state.connection.get('providersType').toJS(),
				providersWaitingForSms: state.connection
					.get('providersWaitingForSms')
					.toJS()
			};
		},
		{
			getProviders,
			getProviderType,
			addProfileFromProvider,
			requestNewProvider,
			getProvidersWaitingForSms,
			sendSmsCode
		}
	)(Connection)
);
