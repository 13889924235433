import * as actionTypes from './actionTypes';

export const updatePerson = personData => {
	return {
		type: actionTypes.UPDATE_PERSON,
		requestType: 'POST',
		message: 'Person updated successful.',
		request: `/updatePerson`,
		body: {
			...personData
		}
	};
};

export const photoUpload = form => {
	return {
		type: actionTypes.PHOTO_UPLOAD,
		requestType: 'POST',
		request: `/photoUpload`,
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		body: form
	};
};

export const deletePerson = personId => {
	return {
		type: actionTypes.DELETE_PERSON,
		requestType: 'POST',
		message: 'Person deleted successful.',
		request: `/deletePerson`,
		body: {
			personId
		}
	};
};

export const getPerson = personId => {
	return {
		type: actionTypes.GET_PERSON,
		requestType: 'GET',
		request: `/person?personId=${personId}`,
		body: {
			personId
		}
	};
};
/* Temporary action */
export const getProfilesList = personId => {
	return {
		type: actionTypes.GET_PROFILES,
		requestType: 'GET',
		request: `/profiles?person=${personId}`,
		body: {
			personId
		}
	};
};

export const getProvidersWaitingForSms = personId => {
	return {
		type: actionTypes.GET_PROVIDERS_WAITING_FOR_SMS,
		requestType: 'GET',
		request: `/getProvidersWaitingForSms?personId=${personId}`
	};
};

export const sendSmsCode = (smsCode, providerId) => {
	return {
		type: actionTypes.SEND_SMS_CODE,
		requestType: 'POST',
		message: 'SMS code sent successful.',
		request: `/sendSmsCode`,
		body: {
			smsCode,
			id: providerId
		}
	};
};
