import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import VirtualizedTable from '@Components/VirtualizedTable/index';

import {
	Button,
	CssBaseline,
	Typography,
	Paper,
	Container
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { getFeedbacks } from './actions';

const styles = theme => ({
	feedbackContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6)
	},
	feedbacksGrid: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8)
	},
	subHeader: {
		marginBottom: '20px',
		marginTop: '20px'
	},
	refreshButton: {
		marginBottom: '20px'
	}
});

class Feedbacks extends Component {
	componentDidMount() {
		this.props.getFeedbacks();
	}

	render() {
		const { feedbacks, classes } = this.props;

		return (
			<React.Fragment>
				<CssBaseline />
				<main>
					<div className={classes.feedbackContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
							>
								My Feedback
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
							>
								See below the submitted information by the
								feedback form
							</Typography>
						</Container>
						<Container
							className={classes.feedbacksGrid}
							maxWidth="md"
						>
							<Button
								onClick={this.props.getFeedbacks}
								color="primary"
								variant="contained"
								className={classes.refreshButton}
							>
								Refresh
							</Button>
							<Paper
								style={{
									height: ' calc(100vh - 400px)',
									width: '770px'
								}}
							>
								<VirtualizedTable
									rowCount={feedbacks.length}
									rowGetter={({ index }) => feedbacks[index]}
									rowHeight={100}
									columns={[
										{
											width: 200,
											label: 'Email',
											dataKey: 'email'
										},
										{
											width: 200,
											label: 'Feedback',
											dataKey: 'feedback'
										},
										{
											width: 200,
											label: 'Name',
											dataKey: 'name'
										},
										{
											width: 50,
											label: 'Rating',
											dataKey: 'rating'
										},
										{
											width: 120,
											label: 'Crated',
											dataKey: 'created',
											isDateTime: true
										}
									]}
								/>
							</Paper>
						</Container>
					</div>
				</main>
			</React.Fragment>
		);
	}
}

Feedbacks.propTypes = {
	feedbacks: PropTypes.array.isRequired,
	classes: PropTypes.object.isRequired,
	getFeedbacks: PropTypes.func.isRequired
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				feedbacks: state.admin.getIn(['feedbacks', 'feedbacks']).toJS()
			};
		},
		{
			getFeedbacks
		}
	)(Feedbacks)
);
