import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		minWidth: 500
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	ratingWrapper: {
		textAlign: 'center'
	},
	feedbackButton: {
		width: '100%',
		backgroundColor: '#d9534f',
		'&:hover': {
			backgroundColor: '#c9302c'
		}
	}
}));
const FeedbackForm = props => {
	const classes = useStyles();
	const [feedback, setFeedback] = React.useState('');
	const [rating, setRating] = React.useState(0);

	const handleFeedbackChange = e => {
		setFeedback(e.target.value);
	};

	const handleSendFeedback = () => {
		props.sendFeedback({ feedback, rating });
	};

	return (
		<React.Fragment>
			<Grid container>
				<Grid item xs={12}>
					<Typography variant="h4" gutterBottom align="center">
						Your feedback means a lot to us
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Box
						component="fieldset"
						className={classes.ratingWrapper}
						borderColor="transparent"
					>
						<Typography component="legend">
							Please, rate your experience on MyPHI
						</Typography>
						<Rating
							name="simple-controlled"
							value={rating}
							onChange={(event, newValue) => {
								setRating(newValue);
							}}
						/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="feedback"
						label="Please, leave a comment here"
						multiline
						rows={10}
						defaultValue="Please, leave a comment here"
						variant="outlined"
						value={feedback}
						style={{
							width: '100%',
							marginTop: '20px',
							marginBottom: '20px'
						}}
						onChange={handleFeedbackChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<div
						style={{
							textAlign: 'center'
						}}
					>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							className={classes.submit}
							disbled={!feedback}
							onClick={handleSendFeedback}
							style={{
								width: '40%'
							}}
						>
							Submit
						</Button>
					</div>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

FeedbackForm.propTypes = {
	sendFeedback: PropTypes.func.isRequired
};

export default FeedbackForm;
