export const profileHelper = {
	myChart: {
		personalInfo: {
			address: '',
			dateOfBirth: new Date(),
			emergencyContactName: '',
			emergencyContactPhone: '',
			firstName: '',
			insuranceName: '',
			insuranceNumber: '',
			insuranceType: '',
			lastName: '',
			phoneNumber: '',
			race: '',
			zipCode: '',
			medicalRecordNumber: '',
			clinic: '',
			primaryProvider: ''
		},
		allergies: [],
		diagnosis: [],
		planOfCare: {
			planOfCareOverdue: [],
			planOfCareNext: [],
			planOfCareFuture: []
		},
		history: {
			familyHistory: [],
			medicalHistory: [],
			socialHistory: {
				alcoholUse: '',
				ouncesWeek: '',
				smokelessTobaccoUse: '',
				smokelessTobaccoTypes: '',
				smokingTobaccoUse: '',
				smokingTobaccoTypes: '',
				packsDay: '',
				yearsSmoked: ''
			},
			surgicalHistory: []
		},
		immunizations: [],
		laboratoryTests: [],
		medications: [],
		files: []
	},
	education: {
		gradebooks: [],
		attendances: [],
		disciplines: [],
		studentInfo: {
			firstName: '',
			lastName: '',
			schoolMail: '',
			address: '',
			call: '',
			gender: '',
			age: '',
			language: '',
			graduationYear: '',
			otherId: '',
			communityService: '',
			second: '',
			school: '',
			principal: '',
			grade: '',
			status: '',
			homeRoom: '',
			disciplineOfficer: '',
			itemStudentInfo: []
		},
		healthInfo: {
			healthConditions: [],
			diabeteScarelog: [],
			disabilities: [],
			officeVisits: [],
			ihpForms: [],
			childhoodIllnesses: [],
			injuries: [],
			medications: [],
			physicalExams: [],
			tests: [],
			vaccinations: []
		}
	}
};
