import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InsertChartIcon from '@material-ui/icons/InsertChart';

import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';

import { withStyles } from '@material-ui/styles';
import Copyrights from '@Components/Copyrights/index';
import RenderLink from '@Components/RenderLink/index';
import {
	getRiskScoreByZipCode,
	sendFeedback,
	updateFeedback,
	setDefaultState
} from './actions';

import RiskScoreChart from './components/RiskScoreChart/index';
import ZipCodeInfo from './components/ZipCodeInfo/index';
import Covid19Info from './components/Covid19Info/index';
import Form from './components/Form/index';
import FeedbackForm from './components/FeedbackForm/index';

import { styles } from './styles';

class RiskScoreByZipCode extends Component {
	getRiskScore = async fields => {
		await this.props.getRiskScoreByZipCode(fields);
		await this.props.sendFeedback({
			...fields,
			feedback: '',
			rating: ''
		});
	};

	sendFeedback = params => {
		const { userData } = this.props;

		const feedbackData = {
			...userData,
			...params
		};

		this.props.updateFeedback(feedbackData);
	};

	render() {
		const {
			classes,
			showRiskScoreChart,
			data,
			cityInfo,
			showGreetingsElements,
			errorMessage
		} = this.props;
		return (
			<div>
				<Paper className={classes.mainImage} />
				<Container maxWidth="md">
					<Typography
						component="h2"
						variant="h3"
						align="center"
						color="textPrimary"
						gutterBottom
						className={classes.mainHeader}
					>
						Please, Stay Safe – Keep Six Feet Apart, Wear a Mask,
						Avoid Large Gatherings. Is COVID-19 Spreading in Your
						Community? Enter information below to find out.
					</Typography>

					<Avatar className={classes.avatar}>
						<InsertChartIcon />
					</Avatar>
					{errorMessage && (
						<Typography
							className={classes.errorMessage}
							component="h5"
							variant="h5"
							align="center"
							color="textPrimary"
							gutterBottom
						>
							{errorMessage}
						</Typography>
					)}
				</Container>
				<Container component="main" maxWidth="md">
					<CssBaseline />

					{showRiskScoreChart ? (
						<div className={classes.resultsWrapper}>
							<div>
								<Covid19Info info={cityInfo} />
							</div>
							<div className={classes.chartWrapper}>
								<RiskScoreChart data={data} />
							</div>
							<div className={classes.zipCodeInfoWrapper}>
								<ZipCodeInfo info={cityInfo} />
							</div>
							<div className={classes.feedBackFormWrapper}>
								<Button
									style={{
										width: '100%',
										cursor: 'pointer'
									}}
									variant="contained"
									color="primary"
									onClick={this.props.setDefaultState}
								>
									Check another zip code
								</Button>
							</div>
							<div
								style={{
									margin: '0 auto'
								}}
							>
								<Typography align="center" variant="subtitle1">
									Register
									<RenderLink
										element={Link}
										to="/register"
										variant="body2"
									>
										{' '}
										here{' '}
									</RenderLink>
									in three simple steps to get started with
									MyPHI, where you can view daily updates on
									COVID-19 in your neighborhood and track all
									your health information.
								</Typography>
							</div>
							<div
								style={{
									margin: '0 auto',
									marginTop: '20px'
								}}
							>
								<Typography align="center" variant="subtitle1">
									MyPHI can also help you share information
									safely with your child’s doctor and School
									Nurse, such as vaccine records, medications,
									or test results.
								</Typography>
							</div>
							<div
								style={{
									margin: '0 auto',
									marginTop: '20px'
								}}
							>
								{showGreetingsElements ? (
									<Typography
										variant="h4"
										gutterBottom
										align="center"
									>
										Thank you for visiting MyPHI.
										<Link
											onClick={this.props.setDefaultState}
										>
											{' '}
											Click here{' '}
										</Link>
										to check COVID-19 Risk in another
										neighborhood.
									</Typography>
								) : (
									<FeedbackForm
										sendFeedback={this.sendFeedback}
									/>
								)}
							</div>
						</div>
					) : (
						<div className={classes.formWrapper}>
							<Form getRiskScore={this.getRiskScore} />
						</div>
					)}

					<Box mt={5}>
						<Copyrights />
					</Box>
				</Container>
			</div>
		);
	}
}

RiskScoreByZipCode.propTypes = {
	classes: PropTypes.object.isRequired,
	cityInfo: PropTypes.object.isRequired,
	data: PropTypes.array.isRequired,
	showRiskScoreChart: PropTypes.bool.isRequired,
	showGreetingsElements: PropTypes.bool.isRequired,
	getRiskScoreByZipCode: PropTypes.func.isRequired,
	sendFeedback: PropTypes.func.isRequired,
	userData: PropTypes.object.isRequired,
	updateFeedback: PropTypes.func.isRequired,
	setDefaultState: PropTypes.func.isRequired,
	errorMessage: PropTypes.string.isRequired
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				data: state.reports
					.getIn(['riskScoreByZipCode', 'data'])
					.toJS(),
				userData: state.reports
					.getIn(['riskScoreByZipCode', 'userData'])
					.toJS(),
				cityInfo: state.reports
					.getIn(['riskScoreByZipCode', 'cityInfo'])
					.toJS(),
				showRiskScoreChart: state.reports.getIn([
					'riskScoreByZipCode',
					'showRiskScoreChart'
				]),
				showGreetingsElements: state.reports.getIn([
					'riskScoreByZipCode',
					'showGreetingsElements'
				]),
				errorMessage: state.reports.getIn([
					'riskScoreByZipCode',
					'errorMessage'
				])
			};
		},
		{
			getRiskScoreByZipCode,
			sendFeedback,
			updateFeedback,
			setDefaultState
		}
	)(RiskScoreByZipCode)
);
