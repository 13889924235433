export const OPEN_ALERT = Symbol('OPEN_ALERT');
export const CLEAR_SELECTED_PERSON_INFO = Symbol('CLEAR_SELECTED_PERSON_INFO');
export const LOG_IN = Symbol('LOG_IN');

export const openAlert = body => {
	return {
		type: OPEN_ALERT,
		body: {
			...body
		}
	};
};

export const clearSelectedPerson = () => {
	return {
		type: CLEAR_SELECTED_PERSON_INFO
	};
};

export const logIn = loginData => {
	return {
		type: LOG_IN,
		requestType: 'POST',
		request: `/login`,
		message: 'Login is successful.',
		body: {
			...loginData
		}
	};
};