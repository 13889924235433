import * as actionTypes from './actionTypes';

export const getTimelineList = personId => {
	return {
		type: actionTypes.GET_TIMELINE_LIST,
		requestType: 'GET',
		request: `/getTimelineData?personId=${personId}`,
		body: {}
	};
};

export const getContent = fileName => {
	return {
		type: actionTypes.GET_CONTENT,
		requestType: 'GET',
		request: `/readFile?id=${fileName}`
	};
};
