/* My chart components */
import PersonalInfo from '../MyChart/PersonalInfo';
import Allergies from '../MyChart/Allergies';
import Diagnosis from '../MyChart/Diagnosis';
import FamilyHistory from '../MyChart/FamilyHistory';
import MedicalHistory from '../MyChart/MedicalHistory';
import SocialHistory from '../MyChart/SocialHistory';
import SurgicalHistory from '../MyChart/SurgicalHistory';
import PlanOfCareOverdue from '../MyChart/PlanOfCareOverdue';
import PlanOfCareNext from '../MyChart/PlanOfCareNext';
import PlanOfCareFuture from '../MyChart/PlanOfCareFuture';
import Immunizations from '../MyChart/Immunizations';
import Medications from '../MyChart/Medications';
import LaboratoryTests from '../MyChart/LaboratoryTests';
import Files from '../MyChart/Files';
/*  Education Components */
import StudentInfo from '../Education/StudentInfo';
import EmerganceContacts from '../Education/EmerganceContacts';
import Attendances from '../Education/Attendances';
import Gradebooks from '../Education/Gradebooks';
import Disciplines from '../Education/Disciplines';
import HealthConditions from '../Education/HealthConditions';
import DiabeteScarelogs from '../Education/DiabeteScarelogs';
import Disabilities from '../Education/Disabilities';
import OfficeVisits from '../Education/OfficeVisits';
import IhpForms from '../Education/IhpForms';
import ChildhoodIllnesses from '../Education/ChildhoodIllnesses';
import Injuries from '../Education/Injuries';
import EducationMedications from '../Education/Medications';
import PhysicalExams from '../Education/PhysicalExams';
import Tests from '../Education/Tests';
import Vaccinations from '../Education/Vaccinations';

export const myChartSteps = [
	'Personal Info',
	'Allergies',
	'Diagnosis',
	'Family History',
	'Medical History',
	'Social History',
	'Surgical History',
	'Plan of Care - Overdue',
	'Plan of Care - Next',
	'Plan of Care - Future',
	'Immunizations',
	'Laboratory Tests',
	'Medications',
	'Files'
];
export const educationSteps = [
	'Student Info',
	'Emergency Contacts',
	'Gradebooks',
	'Attendance',
	'Discipline',
	'Health Conditions ',
	'Diabetes Carelogs',
	'Disabilities',
	'Office Visits',
	'IHP Forms',
	'Childhood Illnesses',
	'Injuries',
	'Medications',
	'Physical Exams',
	'Tests',
	'Vaccinations'
];

export const myChartPersonalInfoDefaultState = {
	firstName: '',
	lastName: '',
	race: '',
	phoneNumber: '',
	address: '',
	zipCode: '',
	emergencyContactName: '',
	emergencyContactPhoneNumber: '',
	insuranceName: '',
	insuranceType: '',
	insuranceNumber: '',
	dateOfBirth: new Date()
};

export const studentInfoDefaultState = {
	firstName: '',
	lastName: '',
	age: '',
	address: '',
	otherId: '',
	language: '',
	graduationYear: '',
	second: '',
	school: '',
	principal: '',
	grade: '',
	status: '',
	homeRoom: '',
	disciplineOfficer: '',
	communityService: '',
	schoolMail: '',
	call: ''
};
export const myChartSocialHistoryDefaultState = {
	alcoholUse: '',
	ouncesWeek: '',
	smokelessTobaccoUse: '',
	phoneNumber: '',
	smokelessTobaccoTypes: '',
	packsDay: '',
	smokingTobaccoTypes: '',
	smokingTobaccoUse: '',
	yearsSmoked: ''
};

export const myChartComponentsMap = {
	0: { comp: PersonalInfo, data: 'personalInfo' },
	1: { comp: Allergies, data: 'allergies' },
	2: { comp: Diagnosis, data: 'diagnosis' },
	3: { comp: FamilyHistory, data: 'familyHistories' },
	4: { comp: MedicalHistory, data: 'medicalHistories' },
	5: { comp: SocialHistory, data: 'socialHistory' },
	6: { comp: SurgicalHistory, data: 'surgicalHistories' },
	7: { comp: PlanOfCareOverdue, data: 'planOfCareOverdues' },
	8: { comp: PlanOfCareNext, data: 'planOfCareNexts' },
	9: { comp: PlanOfCareFuture, data: 'planOfCareFutures' },
	10: { comp: Immunizations, data: 'immunizations' },
	11: { comp: LaboratoryTests, data: 'laboratoryTests' },
	12: { comp: Medications, data: 'medications' },
	13: { comp: Files, data: 'files' }
};

export const educationComponentsMap = {
	0: { comp: StudentInfo, data: 'studentInfo' },
	1: { comp: EmerganceContacts, data: 'emerganceContacts' },
	2: { comp: Gradebooks, data: 'gradebooks' },
	3: { comp: Attendances, data: 'attendances' },
	4: { comp: Disciplines, data: 'disciplines' },
	5: { comp: HealthConditions, data: 'healthConditions' },
	6: { comp: DiabeteScarelogs, data: 'diabeteScarelogs' },
	7: { comp: Disabilities, data: 'disabilities' },
	8: { comp: OfficeVisits, data: 'officeVisits' },
	9: { comp: IhpForms, data: 'ihpForms' },
	10: { comp: ChildhoodIllnesses, data: 'childhoodIllnesses' },
	11: { comp: Injuries, data: 'injuries' },
	12: { comp: EducationMedications, data: 'educationMedications' },
	13: { comp: PhysicalExams, data: 'physicalExams' },
	14: { comp: Tests, data: 'tests' },
	15: { comp: Vaccinations, data: 'vaccinations' }
};

export const myChartInfoObject = {
	personalInfo: 'personalInfo',
	allergies: [],
	diagnosis: [],
	planOfCare: {
		planOfCareOverdue: [],
		planOfCareNext: [],
		planOfCareFuture: []
	},
	history: {
		familyHistory: [],
		medicalHistory: [],
		socialHistory: {
			alcoholUse: '',
			ouncesWeek: '',
			smokelessTobaccoUse: '',
			smokelessTobaccoTypes: '',
			smokingTobaccoUse: '',
			smokingTobaccoTypes: '',
			packsDay: '',
			yearsSmoked: ''
		},
		surgicalHistory: []
	},
	immunizations: [],
	laboratoryTests: [],
	medications: [],
	files: []
};

export const educationInfoObject = {
	gradebooks: [],
	attendances: [],
	disciplines: [],
	studentInfo: {
		firstName: '',
		lastName: '',
		schoolMail: '',
		address: '',
		call: '',
		gender: '',
		age: '',
		language: '',
		graduationYear: '',
		otherId: '',
		communityService: '',
		second: '',
		school: '',
		principal: '',
		grade: '',
		status: '',
		homeRoom: '',
		disciplineOfficer: '',
		itemStudentInfo: []
	},
	healthInfo: {
		healthConditions: [],
		diabeteScarelog: [],
		disabilities: [],
		officeVisits: [],
		ihpForms: [],
		childhoodIllnesses: [],
		injuries: [],
		medications: [],
		physicalExams: [],
		tests: [],
		vaccinations: []
	}
};
