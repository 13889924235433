import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	updateProviderCredentialsButton: {
		width: '100%',
		cursor: 'pointer',
		textAlign: 'center',

		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		}
	}
}));

const UpdateProviderCredentials = props => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [username, setUsername] = React.useState(
		props.providerCredentials.username
	);
	const [password, setPassword] = React.useState('');

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleFieldChange = event => {
		if (event.target.name === 'providerUsername') {
			setUsername(event.target.value);
		} else if (event.target.name === 'providerPassword') {
			setPassword(event.target.value);
		}
	};

	const handleClose = () => {
		setOpen(false);
		setUsername(props.providerCredentials.username);
		setPassword('');
	};

	const handleUpdateCredentials = () => {
		props.updateProviderCredentials({
			username,
			password
		});
		handleClose();
	};

	return (
		<React.Fragment>
			<Button
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
				className={classes.updateProviderCredentialsButton}
			>
				Update Provider Credentials
			</Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				className={classes.root}
			>
				<DialogTitle id="form-dialog-title">
					Update Provider Credentials
				</DialogTitle>
				<DialogContent>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="providerUsername"
						label="Provider Username"
						name="providerUsername"
						value={username}
						onChange={handleFieldChange}
						inputProps={{
							autocomplete: 'new-password',
							form: {
								autocomplete: 'off'
							}
						}}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="providerPassword"
						label="Provider Password"
						type="password"
						id="providerPassword"
						value={password}
						onChange={handleFieldChange}
						inputProps={{
							autocomplete: 'new-password',
							form: {
								autocomplete: 'off'
							}
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button
						disabled={!username || !password}
						onClick={handleUpdateCredentials}
						color="primary"
					>
						Update Credentials
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

UpdateProviderCredentials.propTypes = {
	updateProviderCredentials: PropTypes.func.isRequired,
	providerCredentials: PropTypes.object.isRequired
};

export default UpdateProviderCredentials;
