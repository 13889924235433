import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CssBaseline, Grid, Container } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

import Form from '../../components/Form/index';
import TextImageDialog from '../../components/TextImageDialog/index';

import {
	getProfile,
	getAllProviders,
	updateProfile,
	setDefaultState,
	fileUpload,
	getTextFromImage,
	getTextFromUrlImage
} from './actions';
import { styles } from './styles';

class EditSelfProfile extends Component {
	componentDidMount = async () => {
		const profileId = this.props.match.params.id;
		await this.props.getAllProviders();
		await this.props.getProfile({ profileId });
	};

	updateProfile = async (
		profileData,
		providerType,
		files,
		isLast,
		deletedFiles
	) => {
		const { profileInfo } = this.props;

		let formData;
		let updateFormData = false;
		if (files && files.length !== 0) {
			formData = new FormData();

			files.forEach(file => {
				if (file.file) {
					formData.append('avatar', file.file);
					updateFormData = true;
				}
			});
		}

		const profile = {
			id: profileInfo.id,
			content: profileData,
			visibilitySchema: profileInfo.visibilitySchema,
			fileNameChanges: [],
			deletedFiles
		};

		try {
			const updatedProfile = await this.props.updateProfile(profile);

			if (updatedProfile.response && formData && updateFormData) {
				formData.append('profileId', updatedProfile.response.toJS().id);
				try {
					await this.props.fileUpload(formData);
				} catch (error) {
					console.log(error);
				}
			}

			if (isLast) {
				this.props.setDefaultState();
				this.redirect();
			}
		} catch (error) {
			console.log(error);
		}
	};

	redirect = () => {
		const { person } = this.props.history.location.state;
		this.props.history.push({
			pathname: `/dashboard/${person.id}`,
			state: { person }
		});
	};

	handleGetOCR = async file => {
		if (file.fileUrl) {
			await this.props.getTextFromUrlImage(file.fileUrl);
		} else {
			const formData = new FormData();
			formData.append('avatar', file.file || file.fileUrl);
			await this.props.getTextFromImage(formData);
		}
	};

	render() {
		const { profile, classes, providerTypeName, imageText } = this.props;

		return (
			<Fragment>
				<CssBaseline />
				<main>
					<div className={classes.heroContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
								className={classes.header}
							>
								Edit self provider profile
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
								className={classes.subHeader}
							>
								Please provide information about yourself
							</Typography>
						</Container>
					</div>
					<Container className={classes.cardGrid} maxWidth="md">
						<Grid container spacing={4}>
							<Form
								type={providerTypeName}
								action={this.updateProfile}
								data={profile}
								redirect={this.redirect}
								handleGetOCR={this.handleGetOCR}
							/>
						</Grid>
					</Container>
				</main>
				<TextImageDialog text={imageText} />
			</Fragment>
		);
	}
}

EditSelfProfile.propTypes = {
	profile: PropTypes.object.isRequired,
	profileInfo: PropTypes.object.isRequired,
	getProfile: PropTypes.func.isRequired,
	providerTypeName: PropTypes.string.isRequired,
	getAllProviders: PropTypes.func.isRequired,
	setDefaultState: PropTypes.func.isRequired,
	updateProfile: PropTypes.func.isRequired,
	imageText: PropTypes.string.isRequired,
	getTextFromImage: PropTypes.func.isRequired,
	getTextFromUrlImage: PropTypes.func.isRequired,
	fileUpload: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string.isRequired
		})
	})
};

EditSelfProfile.defaultProps = {
	match: {}
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				profile: state.selfProfile
					.getIn(['editSelfProfile', 'profileData'])
					.toJS(),
				providers: state.selfProfile
					.getIn(['editSelfProfile', 'providers'])
					.toJS(),
				providerTypeName: state.selfProfile.getIn([
					'editSelfProfile',
					'providerTypeName'
				]),
				profileInfo: state.selfProfile
					.getIn(['editSelfProfile', 'profileInfo'])
					.toJS(),
				imageText: state.selfProfile.getIn([
					'editSelfProfile',
					'imageText'
				])
			};
		},
		{
			getProfile,
			getAllProviders,
			updateProfile,
			setDefaultState,
			fileUpload,
			getTextFromImage,
			getTextFromUrlImage
		}
	)(EditSelfProfile)
);
