import CakeIcon from '@material-ui/icons/Cake';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import FormatColorResetIcon from '@material-ui/icons/FormatColorReset';
import WarningIcon from '@material-ui/icons/Warning';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PostAddIcon from '@material-ui/icons/PostAdd';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import DescriptionIcon from '@material-ui/icons/Description';
import AccessibleIcon from '@material-ui/icons/Accessible';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

export const iconsMap = {
	dateOfBirth: CakeIcon,
	allergy: FireplaceIcon,
	medication: LocalHospitalIcon,
	laboratoryTest: FormatColorResetIcon,
	diagnosis: WarningIcon,
	planOfCareNext: AssignmentIcon,
	planOfCareFuture: PostAddIcon,
	gradebooks: MenuBookIcon,
	disciplines: LocalLibraryIcon,
	attendances: AssignmentIndIcon,
	diabeteScarelog: DescriptionIcon,
	disabilities: AccessibleIcon,
	officeVisits: HomeWorkIcon,
	ihpForms: InsertDriveFileIcon,
	childhoodIllnesses: ChildCareIcon,
	injuries: SentimentDissatisfiedIcon,
	physicalExams: TransferWithinAStationIcon,
	vaccinations: FavoriteBorderIcon,
	immunization: VerifiedUserIcon,
	surgicalHistory: AssignmentIcon,
	medicalHistory: LocalHospitalIcon,
	visitNotes: LocalHospitalIcon
};

export const sectionTitlesMap = {
	dateOfBirth: 'Date Of Birth',
	allergy: 'Allergy',
	medication: 'Medication',
	laboratoryTest: 'Laboratory Test',
	diagnosis: 'Diagnosis',
	planOfCareNext: 'Plan Of Care Next',
	planOfCareFuture: 'Plan Of Care Future',
	gradebooks: 'Gradebooks',
	disciplines: 'Disciplines',
	attendances: 'Attendances',
	diabeteScarelog: 'Diabetes Carelog',
	disabilities: 'Disabilities',
	officeVisits: 'Office Visits',
	ihpForms: 'Ihp Forms',
	childhoodIllnesses: 'Childhood Illnesses',
	injuries: 'Injuries',
	physicalExams: 'Physical Exams',
	vaccinations: 'Vaccinations',
	medicalHistory: 'Medical History',
	surgicalHistory: 'Surgical History',
	immunization: 'Immunization',
	visitNotes: 'Visit Notes'
};
