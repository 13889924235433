import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { EMAIL_REGEX, diseaseMap, useStyles } from './constants';

const Form = props => {
	const classes = useStyles();
	const [fields, setFields] = useState({
		zipCode: '',
		disease: [],
		email: '',
		otherDisease: ''
	});
	const [errors, setErrors] = useState({
		zipCode: '',
		email: ''
	});
	const { getRiskScore } = props;

	const onChangeHandler = event => {
		const newFields = { ...fields };
		newFields[event.target.name] = event.target.value;
		setFields(newFields);
	};

	const onChangeCheckBoxHandler = e => {
		const newFields = { ...fields };
		if (e.target.checked) {
			newFields.disease = [...newFields.disease, e.target.name];
		} else {
			newFields.disease = newFields.disease.filter(
				item => item !== e.target.name
			);
		}
		setFields(newFields);
	};

	const handleOnKeyPress = e => {
		if (e.keyCode === 13) {
			handleSubmit();
		}
	};

	const handleSubmit = () => {
		if (handleValidation()) {
			getRiskScore(fields);
		}
	};

	const handleValidation = () => {
		const newErrors = {};
		let formIsValid = true;

		if (fields.email && !EMAIL_REGEX.test(fields.email)) {
			formIsValid = false;
			newErrors.email = 'Email format is not valid.';
		}

		if (!fields.zipCode) {
			formIsValid = false;
			newErrors.zipCode = 'Field cannot be empty.';
		}

		setErrors(newErrors);

		return formIsValid;
	};

	const hasOtherDiseaseSelected = fields.disease.indexOf('other') > -1;

	return (
		<Fragment>
			<div className={classes.formWrapper}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							fullWidth
							required
							id="zipCode"
							label="Enter home or school zip code here"
							name="zipCode"
							onChange={onChangeHandler}
							value={fields.zipCode}
							onKeyDown={handleOnKeyPress}
							error={errors.zipCode}
							helperText={errors.zipCode}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							fullWidth
							id="email"
							label="Enter email address here"
							name="email"
							onChange={onChangeHandler}
							value={fields.email}
							onKeyDown={handleOnKeyPress}
							error={errors.email}
							helperText={errors.email}
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel
							style={{
								marginBottom: '10px',
								fontSize: '12px'
							}}
							id="disease-select-label"
						>
							I have a school-aged child with:
						</InputLabel>

						<FormGroup row>
							{diseaseMap.map(disease => (
								<FormControlLabel
									key={disease.id}
									control={
										<Checkbox
											checked={
												fields.disease.indexOf(
													disease.value
												) > -1
											}
											onChange={onChangeCheckBoxHandler}
											name={disease.id}
											color="primary"
										/>
									}
									label={disease.name}
								/>
							))}
						</FormGroup>
					</Grid>
					{hasOtherDiseaseSelected && (
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="otherDisease"
								label="Other"
								name="otherDisease"
								onChange={onChangeHandler}
								value={fields.otherDisease}
							/>
						</Grid>
					)}
				</Grid>
				<Button
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
					onClick={handleSubmit}
				>
					Submit
				</Button>
			</div>
		</Fragment>
	);
};

Form.propTypes = {
	getRiskScore: PropTypes.func.isRequired
};

export default Form;
