import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import TempLinkDialog from '../TempLinkDialog/index';
import DeleteVisitDialog from '../DeleteVisitDialog/index';

const useStyles = makeStyles(theme => ({
	icon: {
		marginRight: theme.spacing(2)
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	cardContent: {
		flexGrow: 1
	},
	buttonsWrapper: {
		justifyContent: 'space-between',
		display: 'flex'
	},
	markButtonsWrapper: {
		justifyContent: 'space-around',
		display: 'flex'
	},
	header: {
		marginBottom: '20px'
	},
	visibilitySettingsButton: {
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	button: {
		width: '100%'
	}
}));

const VisibilitySettings = props => {
	const classes = useStyles();

	const { deleteVisit, handleSendEmail } = props;

	return (
		<Grid item xs={12} sm={12} md={12}>
			<Card className={classes.card}>
				<CardContent className={classes.cardContent}>
					<Grid container spacing={4}>
						<Grid
							item
							xs={12}
							md={12}
							className={classes.buttonsWrapper}
						>
							<TempLinkDialog handleSendEmail={handleSendEmail} />
						</Grid>
						<Grid
							item
							xs={12}
							md={12}
							className={classes.buttonsWrapper}
						>
							<DeleteVisitDialog deleteVisit={deleteVisit} />
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};

VisibilitySettings.propTypes = {
	handleSendEmail: PropTypes.func.isRequired,
	deleteVisit: PropTypes.func.isRequired
};

export default VisibilitySettings;
