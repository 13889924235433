import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import Grid from '@material-ui/core/Grid';
import {
	CssBaseline,
	Typography,
	Container,
	Button,
	Box
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import { openAlert } from '@SharedActions';

import { styles } from './styles';

import { infoComponents, initialState } from './constants';

import { checkAccessCode, loadProfileFromTempLink } from '../../actions';

class ProfilePrintInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...initialState,
			code: ''
		};
	}

	handlePrint = () => {
		window.print();
	};

	checkCode = async () => {
		const { code } = this.state;
		const { guid } = this.props.match.params;

		try {
			const res = await this.props.checkAccessCode(code, guid);
			if (res.response) {
				this.props.loadProfileFromTempLink(guid);
			} else {
				this.props.openAlert({
					severity: 'error',
					message: 'The access code is wrong. Please try again.'
				});
			}
		} catch (e) {
			console.log(e);
		}
	};

	handleFieldChange = e => {
		this.setState({ code: e.target.value });
	};

	render() {
		const {
			profileData,
			providerData,
			classes,
			isAccessCodeValid,
			hiddenProfile
		} = this.props;

		const { showVisibilitySettings, code, visibilitySchema } = this.state;

		const infoComponentsMap = infoComponents[providerData.providerTypeName];

		return (
			<Fragment>
				<CssBaseline />
				<main>
					{hiddenProfile ? (
						<div className={classes.informationWrapper}>
							<Container maxWidth="sm">
								<Typography
									component="h1"
									variant="h2"
									align="center"
									color="textPrimary"
									gutterBottom
								>
									Profile Information
								</Typography>
								<Typography
									variant="h5"
									align="center"
									color="textSecondary"
									paragraph
								>
									Please enter the access code, sent in a
									separate email, to view the health
									information that has been shared
								</Typography>
								<Container
									style={{
										marginTop: '50px'
									}}
								>
									<Typography
										variant="h4"
										align="center"
										paragraph
									>
										This profile is completely hidden.
										Please change the visibility settings in
										profile page if you want to display
										information.
									</Typography>
								</Container>
							</Container>
						</div>
					) : (
						<div className={classes.informationWrapper}>
							<Container maxWidth="sm">
								<Typography
									component="h1"
									variant="h2"
									align="center"
									color="textPrimary"
									gutterBottom
								>
									Profile Information
								</Typography>
								<Typography
									variant="h5"
									align="center"
									color="textSecondary"
									paragraph
								>
									Please enter the access code, sent in a
									separate email, to view the health
									information that has been shared
								</Typography>
							</Container>
							<Container
								className={classes.informationGrid}
								maxWidth="md"
							>
								{isAccessCodeValid && profileData.size > 0 ? (
									<Grid
										style={{ marginBottom: '10px' }}
										container
										spacing={7}
									>
										<Grid item xs={12} md={12}>
											<Box
												display="block"
												displayPrint="none"
											>
												<Button
													variant="contained"
													color="primary"
													className={classes.button}
													onClick={this.handlePrint}
													style={{ width: '100%' }}
												>
													Print
												</Button>
											</Box>
										</Grid>
										<Grid
											item
											xs={12}
											md={12}
											className={classes.buttonsWrapper}
										>
											<Typography
												component="h1"
												variant="h2"
												align="center"
												color="textPrimary"
												gutterBottom
												className={classes.header}
											>
												{`${providerData.providerName.toUpperCase()} - ${profileData.get(
													'exportPersonName'
												)}`}
											</Typography>
										</Grid>
										<Grid
											item
											xs={12}
											md={12}
											className={classes.buttonsWrapper}
										>
											{infoComponentsMap.map(item => {
												const ItemComponent = item.comp;

												const schema = fromJS(
													visibilitySchema
												);

												const currentSchema = schema.getIn(
													[...item.schemaProp]
												);

												const sectionSchema = schema.getIn(
													[
														'_globalSettings',
														'elements',
														...item.sectionSchemaProp
													]
												);

												return (
													<Grid container spacing={4}>
														<ItemComponent
															data={profileData
																.getIn([
																	...item.data
																])
																.toJS()}
															providerName={
																providerData
															}
															showVisibilitySettings={
																showVisibilitySettings
															}
															changeVisibilitySettings={() => {}}
															visibilitySchema={currentSchema.toJS()}
															sectionSchema={
																sectionSchema
															}
														/>
													</Grid>
												);
											})}
										</Grid>
									</Grid>
								) : (
									<Grid
										style={{ marginBottom: '10px' }}
										container
										spacing={7}
									>
										<Grid offset={5} item xs={12}>
											<TextField
												variant="outlined"
												margin="normal"
												required
												fullWidth
												id="code"
												label="Access code"
												name="code"
												value={code}
												onChange={
													this.handleFieldChange
												}
											/>
										</Grid>
										<Grid item xs={12}>
											<Button
												onClick={this.checkCode}
												variant="contained"
												color="primary"
												style={{
													width: '100%'
												}}
											>
												Check code
											</Button>
										</Grid>
									</Grid>
								)}
							</Container>
						</div>
					)}
				</main>
			</Fragment>
		);
	}
}

ProfilePrintInformation.propTypes = {
	checkAccessCode: PropTypes.func.isRequired,
	openAlert: PropTypes.func.isRequired,
	hiddenProfile: PropTypes.bool.isRequired,
	loadProfileFromTempLink: PropTypes.func.isRequired,
	isAccessCodeValid: PropTypes.bool.isRequired,
	profileData: PropTypes.object.isRequired,
	providerData: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			guid: PropTypes.string.isRequired
		})
	})
};

ProfilePrintInformation.defaultProps = {
	match: {}
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				isAccessCodeValid: state.dashboard.getIn([
					'profileDashboard',
					'isAccessCodeValid'
				]),
				hiddenProfile: state.dashboard.getIn([
					'profileDashboard',
					'hiddenProfile'
				]),
				profileData: state.dashboard.getIn([
					'profileDashboard',
					'profileData'
				]),
				providerData: state.dashboard
					.getIn(['profileDashboard', 'providerData'])
					.toJS()
			};
		},
		{
			checkAccessCode,
			loadProfileFromTempLink,
			openAlert
		}
	)(ProfilePrintInformation)
);
