import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@Components/Table/index';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		padding: '0 16px'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(4),
		padding: '0 16px'
	},
	header: {
		padding: '20px'
	}
}));

const saveFile = (e, fileInfo) => {
	e.preventDefault();
	e.stopPropagation();
	const downloadLink = document.createElement('a');
	downloadLink.setAttribute('href', fileInfo.fileUrl);
	downloadLink.setAttribute('target', '_blank');
	downloadLink.setAttribute('download', fileInfo.name);
	downloadLink.style.display = 'none';
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
};

const Files = React.memo(props => {
	const classes = useStyles();

	const {
		data,
		showVisibilitySettings,
		changeVisibilitySettings,
		visibilitySchema,
		sectionSchema
	} = props;

	const handleCheckbox = (index, isSelected) => {
		changeVisibilitySettings(index, isSelected, ['files']);
	};

	const handleToggle = () => {
		changeVisibilitySettings('files', !sectionSchema, [
			'_globalSettings',
			'elements'
		]);
	};

	const headCells = [
		{
			id: 'name',
			numeric: false,
			disablePadding: false,
			label: 'Name'
		},
		{
			id: 'mimetype',
			numeric: false,
			disablePadding: false,
			label: 'Type'
		},
		{
			id: 'custom',
			isCustom: true,
			numeric: false,
			disablePadding: false,
			label: 'Action',
			customComponent: fileInfo => {
				/* if (!fileTypes.unreadableFormat(fileInfo.mimetype)) {
					return <OpenDialog fileInfo={fileInfo} />;
				} */

				return (
					<Button
						onClick={e => saveFile(e, fileInfo)}
						variant="contained"
						color="primary"
						style={{ width: '120px' }}
					>
						Open
					</Button>
				);
			}
		}
	];

	const selectedValues = (visibilitySchema || []).map((item, index) => {
		return item && index.toString();
	});

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<Grid item xs={12} sm={12} md={12}>
					<Typography
						align="left"
						gutterBottom
						variant="h5"
						component="h2"
						className={classes.header}
					>
						{showVisibilitySettings && (
							<FormControlLabel
								control={
									<Checkbox
										icon={<VisibilityIcon />}
										checkedIcon={
											<VisibilityIcon color="primary" />
										}
										edge="start"
										checked={sectionSchema}
										tabIndex={-1}
										disableRipple
										onClick={handleToggle}
									/>
								}
							/>
						)}
						Files
					</Typography>
					<Table
						rows={data}
						headCells={headCells}
						hasEmptyRows={false}
						showVisibilitySettings={showVisibilitySettings}
						customCheckbox
						handleCheckboxOnChange={handleCheckbox}
						selectedValues={selectedValues}
					/>
				</Grid>
			</Paper>
		</div>
	);
});

Files.propTypes = {
	data: PropTypes.array.isRequired,
	showVisibilitySettings: PropTypes.bool.isRequired,
	changeVisibilitySettings: PropTypes.func.isRequired,
	visibilitySchema: PropTypes.array.isRequired,
	sectionSchema: PropTypes.bool.isRequired
};

export default Files;
