import React, { useState } from 'react';
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConsentPDF from '@ConsentPDF/consent.pdf';

const ConsentPdfDialog = () => {
	const [open, setOpen] = useState(false);
	const [numberPages, setNumberPages] = useState(null);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumberPages(numPages);
	};

	return (
		<React.Fragment>
			<Button
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
			>
				Open the Consent
			</Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="consent-dialog-title"
				maxWidth="md"
			>
				<DialogTitle id="consent-dialog-title">Consent</DialogTitle>
				<DialogContent>
					<div>
						<Document
							file={ConsentPDF}
							onLoadSuccess={onDocumentLoadSuccess}
						>
							{Array.from(new Array(numberPages), (el, index) => (
								<Page
									key={`page_${index + 1}`}
									pageNumber={index + 1}
								/>
							))}
						</Document>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

export default ConsentPdfDialog;
