export const UPDATE_OPTIONS = [
	{
		value: 0,
		label: 'Manual'
	},
	{
		value: 3,
		label: 'Once every 3 days'
	},
	{
		value: 7,
		label: 'Once per week'
	},
	{
		value: 30,
		label: 'Once per month'
	},
	{
		value: 100,
		label: 'Annually'
	}
];

export const TEMP_LINK_OPTIONS = [
	{
		value: 1,
		label: '1 Hour'
	},
	{
		value: 2,
		label: '2 Hours'
	},
	{
		value: 4,
		label: '4 Hours'
	},
	{
		value: 6,
		label: '6 Hours'
	},
	{
		value: 12,
		label: '12 Hours'
	},
	{
		value: 24,
		label: '24 Hours'
	},
	{
		value: 36,
		label: '36 Hours'
	},
	{
		value: 48,
		label: '48 Hours'
	},
	{
		value: 168,
		label: '168 Hours'
	}
];
