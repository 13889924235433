import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const ChildhoodIllnesses = props => {
	const handleDateChange = (date, index) => {
		props.handleField(date, 'ChildhoodIllnesses', 'childhoodIllnesses', [
			index,
			'date'
		]);
	};

	const handleAdd = () => {
		props.handleAddNewItem(
			[
				{
					schoolYear: '',
					date: new Date(),
					school: '',
					illness: '',
					examinedBy: '',
					ageDiagnosed: '',
					referredBy: ''
				}
			],
			'ChildhoodIllnesses',
			'childhoodIllnesses'
		);
	};

	const handleField = (event, index) => {
		props.handleField(
			event.target.value,
			'ChildhoodIllnesses',
			'childhoodIllnesses',
			[index, event.target.id]
		);
	};

	const handleDelete = index => {
		props.deleteData(index, 'ChildhoodIllnesses', 'childhoodIllnesses');
	};

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom>
				Childhood Illnesses
			</Typography>
			{props.data.length > 0 ? (
				props.data.map((illness, index) => {
					return (
						<Grid container spacing={3}>
							<Grid item xs={12} md={3}>
								<TextField
									id="schoolYear"
									label="School Year"
									fullWidth
									onChange={e => handleField(e, index)}
									value={illness.schoolYear}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="MM/dd/yyyy"
										margin="normal"
										id="date"
										label="Date"
										value={illness.date}
										style={{
											width: '100%',
											marginTop: 0,
											marginBottom: 0
										}}
										autoOk
										onChange={date =>
											handleDateChange(date, index)
										}
										KeyboardButtonProps={{
											'aria-label': 'change date'
										}}
									/>
								</MuiPickersUtilsProvider>
							</Grid>

							<Grid item xs={12} md={3}>
								<TextField
									id="school"
									label="School"
									fullWidth
									onChange={e => handleField(e, index)}
									value={illness.school}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="illness"
									label="Illness"
									fullWidth
									onChange={e => handleField(e, index)}
									value={illness.illness}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="examinedBy"
									label="Examined By"
									fullWidth
									onChange={e => handleField(e, index)}
									value={illness.examinedBy}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="ageDiagnosed"
									label="Age Diagnosed"
									fullWidth
									onChange={e => handleField(e, index)}
									value={illness.ageDiagnosed}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="referredBy"
									label="Referred By"
									fullWidth
									onChange={e => handleField(e, index)}
									value={illness.referredBy}
								/>
							</Grid>
							<Grid item xs={12} md={1}>
								<IconButton
									aria-label="delete"
									color="primary"
									style={{
										backgroundColor: '#d9534f',
										'&:hover': {
											backgroundColor: '#c9302c'
										},
										color: '#ffffff',
										height: '45px',
										width: '45px',
										marginTop: '5px',
										cursor: 'pointer'
									}}
									onClick={() => handleDelete(index)}
								>
									<DeleteIcon />
								</IconButton>
							</Grid>
						</Grid>
					);
				})
			) : (
				<Typography component="h1" variant="h4" align="center">
					No Illnesses
				</Typography>
			)}

			<Grid contariner spacing={3}>
				<Button
					style={{ marginTop: '20px' }}
					variant="contained"
					color="primary"
					onClick={handleAdd}
				>
					Add Illnesse
				</Button>
			</Grid>
		</React.Fragment>
	);
};

ChildhoodIllnesses.propTypes = {
	data: PropTypes.array.isRequired,
	handleField: PropTypes.func.isRequired,
	deleteData: PropTypes.func.isRequired,
	handleAddNewItem: PropTypes.func.isRequired
};

export default ChildhoodIllnesses;
