import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	tempLinkButton: {
		cursor: 'pointer',
		textAlign: 'center',
		width: '100%',
		margin: '0 auto',
		marginBottom: '20px',
		maxWidth: '400px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		}
	}
}));

const TempLinkDialog = props => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [email, setEmail] = React.useState('');

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setEmail('');
	};

	const handleFieldChange = event => {
		setEmail(event.target.value);
	};

	const handleSendButton = () => {
		props.handleSendEmail(email);
		handleClose();
	};

	return (
		<React.Fragment>
			<Button
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
				className={classes.tempLinkButton}
			>
				Send temporary link
			</Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				className={classes.root}
			>
				<DialogTitle id="form-dialog-title">
					Send temporary link
				</DialogTitle>
				<DialogContent>
					<DialogContentText>Send temporary link</DialogContentText>

					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email"
						name="email"
						value={email}
						onChange={handleFieldChange}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button
						disabled={!email}
						onClick={handleSendButton}
						color="primary"
					>
						Send
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

TempLinkDialog.propTypes = {
	handleSendEmail: PropTypes.func.isRequired
};

export default TempLinkDialog;
