import * as actionTypes from './actionTypes';

export const getRiskScoreByZipCode = data => {
	return {
		type: actionTypes.GET_RISK_SCORE_BY_ZIP_CODE,
		requestType: 'GET',
		request: `/riskScoresByZipCode?zipCode=${data.zipCode}`,
		data
	};
};

export const sendFeedback = feedBackData => {
	return {
		type: actionTypes.SEND_COVID_FEEDBACK,
		requestType: 'POST',
		request: `/sendCovidFeedback`,
		body: {
			...feedBackData
		}
	};
};

export const updateFeedback = feedBackData => {
	return {
		type: actionTypes.UPDATE_COVID_FEEDBACK,
		requestType: 'POST',
		message: 'Feedback sent successfully',
		request: `/updateCovidFeedback`,
		body: {
			...feedBackData
		}
	};
};

export const setDefaultState = () => {
	return {
		type: actionTypes.SET_DEFAULT_STATE
	};
};
