import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import {
	CssBaseline,
	Typography,
	Container,
	Button,
	Box
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import { openAlert } from '@SharedActions';

import SummaryContent from '../../components/SummaryContent/index';

import { styles } from '../../styles';
import { authTitle, notAuthTitle } from '../../constants';
import {
	checkAccessCode,
	summaryProfilesFromTempLink,
	getTempLinks
} from '../../actions';

class PrintSummaryInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {}

	handlePrint = () => {
		window.print();
	};

	checkCode = async () => {
		const { code } = this.state;
		const { guid } = this.props.match.params;

		try {
			const res = await this.props.checkAccessCode(code, guid);
			if (res.response) {
				try {
					const getTempLinkRes = await this.props.getTempLinks(guid);

					if (getTempLinkRes.response) {
						this.props.summaryProfilesFromTempLink(
							getTempLinkRes.response.getIn([
								'content',
								'parentData'
							])
						);
					}
				} catch (e) {
					console.log(e);
				}
			} else {
				this.props.openAlert({
					severity: 'error',
					message: 'The access code is wrong. Please try again.'
				});
			}
		} catch (e) {
			console.log(e);
		}
	};

	handleFieldChange = e => {
		this.setState({ code: e.target.value });
	};

	render() {
		const { classes, isAccessCodeValid, allInformation } = this.props;

		const { code } = this.state;

		return (
			<Fragment>
				<CssBaseline />
				<main
					style={{
						backgroundColor: '#ffffff'
					}}
				>
					<div className={classes.informationWrapper}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
							>
								Shared Health Summary Information
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
							>
								{isAccessCodeValid ? authTitle : notAuthTitle}
							</Typography>
						</Container>
						{isAccessCodeValid ? (
							<Container
								className={classes.informationSummaryGrid}
								maxWidth="md"
							>
								<Grid
									style={{ marginBottom: '10px' }}
									container
								>
									<Grid item xs={12} md={12}>
										<Box
											display="block"
											displayPrint="none"
										>
											<Button
												variant="contained"
												color="primary"
												className={classes.button}
												onClick={this.handlePrint}
												style={{
													width: '100%',
													marginBottom: '30px'
												}}
											>
												Print
											</Button>
										</Box>
									</Grid>
									<Grid item md={12} xs={12}>
										<SummaryContent
											data={[
												{
													categoryName: 'title',
													isNested: false,
													values: 'Health Data'
												},
												...allInformation.myChart,
												{
													categoryName: 'title',
													isNested: false,
													values: 'Education Data'
												},
												...allInformation.education,
												{
													categoryName: 'title',
													isNested: false,
													values: 'Fort Worth'
												},
												...allInformation.fwisd,
												...allInformation.absences,
												{
													categoryName: 'title',
													isNested: false,
													values: 'IrvingISD'
												},
												...allInformation.irvingISD
											]}
										/>
									</Grid>
								</Grid>
							</Container>
						) : (
							<Container
								className={classes.informationGrid}
								maxWidth="md"
							>
								<Grid
									style={{ marginBottom: '10px' }}
									container
									spacing={7}
								>
									<Grid offset={5} item xs={12}>
										<TextField
											variant="outlined"
											margin="normal"
											required
											fullWidth
											id="code"
											label="Access code"
											name="code"
											value={code}
											onChange={this.handleFieldChange}
										/>
									</Grid>
									<Grid item xs={12}>
										<Button
											onClick={this.checkCode}
											variant="contained"
											color="primary"
											style={{
												width: '100%'
											}}
										>
											Check code
										</Button>
									</Grid>
								</Grid>
							</Container>
						)}
					</div>
				</main>
			</Fragment>
		);
	}
}

PrintSummaryInformation.propTypes = {
	checkAccessCode: PropTypes.func.isRequired,
	openAlert: PropTypes.func.isRequired,
	getTempLinks: PropTypes.func.isRequired,
	summaryProfilesFromTempLink: PropTypes.func.isRequired,
	allInformation: PropTypes.object.isRequired,
	isAccessCodeValid: PropTypes.bool.isRequired,
	classes: PropTypes.object.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			guid: PropTypes.string.isRequired
		})
	})
};

PrintSummaryInformation.defaultProps = {
	match: {}
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				allInformation: state.summary.get('allInformation').toJS(),
				isAccessCodeValid: state.summary.get('isAccessCodeValid')
			};
		},
		{
			summaryProfilesFromTempLink,
			checkAccessCode,
			getTempLinks,
			openAlert
		}
	)(PrintSummaryInformation)
);
