import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';
import { profileHelper } from '../../constants';

export const defaultState = fromJS({
	profileData: profileHelper,
	providers: [],
	profileInfo: {},
	imageText: ''
});

const createSelfProfile = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.GET_ALL_PROVIDERS:
			if (action.state === 'finished') {
				state = state.set('providers', action.response);
			}
			return state;
		case actionTypes.GET_TEXT_FROM_IMAGE:
			if (action.state === 'finished') {
				state = state.set('imageText', action.response);
			}
			return state;
		case actionTypes.SET_DEFAULT_STATE:
			state = state
				.set('profileInfo', fromJS({}))
				.set('imageText', '')
				.set('profileData', fromJS(profileHelper));

			return state;
		default:
			return state;
	}
};

export default createSelfProfile;
