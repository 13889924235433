import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@Components/Table/index';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const headCells = [
	{
		id: 'schoolYear',
		numeric: false,
		disablePadding: false,
		label: 'School Year'
	},
	{
		id: 'date',
		numeric: false,
		disablePadding: false,
		label: 'Date',
		isDateFormat: true
	},
	{
		id: 'school',
		numeric: false,
		disablePadding: false,
		label: 'School'
	},
	{
		id: 'checkIn',
		numeric: false,
		disablePadding: false,
		label: 'Check In'
	},
	{
		id: 'timeOut',
		numeric: false,
		disablePadding: false,
		label: 'Time Out'
	},
	{
		id: 'examinedBy',
		numeric: false,
		disablePadding: false,
		label: 'Examined By'
	},
	{
		id: 'reasonForVisit',
		numeric: false,
		disablePadding: false,
		label: 'Reason'
	},
	{
		id: 'treatment',
		numeric: false,
		disablePadding: false,
		label: 'Treatment'
	},
	{
		id: 'disposition',
		numeric: false,
		disablePadding: false,
		label: 'Disposition'
	},
	{
		id: 'referredBy',
		numeric: false,
		disablePadding: false,
		label: 'Referred By'
	}
];

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		padding: '0 16px'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(4),
		padding: '0 16px'
	},
	header: {
		padding: '20px'
	}
}));

const OfficeVisits = React.memo(props => {
	const classes = useStyles();

	const {
		data,
		showVisibilitySettings,
		changeVisibilitySettings,
		visibilitySchema
	} = props;

	const handleCheckbox = (index, isSelected) => {
		changeVisibilitySettings(index, isSelected, [
			'healthInfo',
			'officeVisits'
		]);
	};

	const selectedValues = (visibilitySchema || []).map((item, index) => {
		return item && index.toString();
	});

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<Grid item xs={12} sm={12} md={12}>
					<Typography
						align="left"
						gutterBottom
						variant="h6"
						component="h6"
						className={classes.header}
					>
						Office Visits
					</Typography>
					<Table
						rows={data}
						headCells={headCells}
						hasEmptyRows={false}
						showVisibilitySettings={showVisibilitySettings}
						customCheckbox
						handleCheckboxOnChange={handleCheckbox}
						selectedValues={selectedValues}
					/>
				</Grid>
			</Paper>
		</div>
	);
});

OfficeVisits.propTypes = {
	data: PropTypes.array.isRequired,
	showVisibilitySettings: PropTypes.bool.isRequired,
	changeVisibilitySettings: PropTypes.func.isRequired,
	visibilitySchema: PropTypes.array.isRequired
};

export default OfficeVisits;
