import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@Components/TextField/index';

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: '20px',
		width: '100%',
		border: '1px solid rgba(0, 0, 0, 0.12)',
		padding: '30px'
	}
}));

const keyMap = [
	{
		id: 'planOfCareUrl',
		label: 'Url'
	},
	{
		id: 'planOfCareSelectorAll',
		label: 'Plan of Care All'
	},
	{
		id: 'planOfCareOverdueSelectorName',
		label: 'Plan of Care Overdue Name'
	},
	{
		id: 'planOfCareOverdueNextSelector',
		label: 'Plan of Care Next Name'
	},
	{
		id: 'planOfCareFutureSelectorName',
		label: 'Plan of Care Future Name'
	},
	{
		id: 'planOfCareOverdueTitleSelector',
		label: 'Plan of Care Title'
	},
	{
		id: 'planOfCareStatusSelector',
		label: 'Status'
	},
	{
		id: 'planOfCareDateSelector',
		label: 'Date'
	},
	{
		id: 'planOfCareOrderBySelector',
		label: 'Order By'
	},
	{
		id: 'planOfCareLocationSelector',
		label: 'Location'
	},
	{
		id: 'planOfCareOrderDetailsSelector',
		label: 'Order Details'
	}
];

const PlanOfCareSelectors = props => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography variant="h6" gutterBottom>
				Plan of Care Selectors
			</Typography>
			<Grid container spacing={3}>
				{keyMap.map(item => (
					<Grid item xs={12} md={6}>
						<TextField
							id={item.id}
							label={item.label}
							fullWidth
							onChange={props.handleField}
							value={props.data[item.id]}
						/>
					</Grid>
				))}
			</Grid>
		</div>
	);
};

PlanOfCareSelectors.propTypes = {
	handleField: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired
};

export default PlanOfCareSelectors;
