import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@Components/Table/index';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AbsencesInSchool from './AbsencesInSchool';

const absencesHeadCells = [
	{
		id: 'absenceType',
		numeric: false,
		disablePadding: false,
		label: 'Absence Type'
	},
	{
		id: 'periods',
		numeric: false,
		disablePadding: false,
		label: 'Periods'
	}
];

const otherMarksHeadCells = [
	{
		id: 'absenceType',
		numeric: false,
		disablePadding: false,
		label: 'Absence Type'
	},
	{
		id: 'periods',
		numeric: false,
		disablePadding: false,
		label: 'Periods'
	}
];

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		padding: '0 16px'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(4),
		padding: '0 16px'
	},
	header: {
		padding: '20px'
	}
}));

const Absences = props => {
	const classes = useStyles();

	const {
		data,
		showVisibilitySettings,
		changeVisibilitySettings,
		visibilitySchema,
		sectionSchema
	} = props;

	const handleCheckbox = (index, isSelected, id) => {
		changeVisibilitySettings(id, isSelected, ['absences', index]);
	};

	const handleToggle = () => {
		changeVisibilitySettings('absences', !sectionSchema, [
			'_globalSettings',
			'elements'
		]);
	};

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<Grid item xs={12} sm={12} md={12}>
					<Typography
						align="left"
						gutterBottom
						variant="h5"
						component="h2"
						className={classes.header}
					>
						{showVisibilitySettings && (
							<FormControlLabel
								control={
									<Checkbox
										icon={<VisibilityIcon />}
										checkedIcon={
											<VisibilityIcon color="primary" />
										}
										edge="start"
										checked={sectionSchema}
										tabIndex={-1}
										disableRipple
										onClick={handleToggle}
									/>
								}
							/>
						)}
						Absences
					</Typography>
					{data.map((item, index) => {
						return (
							<Grid item xs={12} sm={12} md={12}>
								<Typography
									align="left"
									gutterBottom
									variant="h6"
									component="h6"
									className={classes.header}
								>
									{showVisibilitySettings && (
										<FormControlLabel
											control={
												<Checkbox
													icon={<VisibilityIcon />}
													checkedIcon={
														<VisibilityIcon color="primary" />
													}
													edge="start"
													checked={
														visibilitySchema[index]
															.year
													}
													tabIndex={-1}
													disableRipple
													onClick={() =>
														handleCheckbox(
															index,
															!visibilitySchema[
																index
															].year,
															'year'
														)
													}
												/>
											}
										/>
									)}
									{item.year}
								</Typography>
								<Typography
									align="left"
									gutterBottom
									variant="h6"
									component="h6"
									className={classes.header}
								>
									{showVisibilitySettings && (
										<FormControlLabel
											control={
												<Checkbox
													icon={<VisibilityIcon />}
													checkedIcon={
														<VisibilityIcon color="primary" />
													}
													edge="start"
													checked={
														visibilitySchema[index]
															.absencesData
													}
													tabIndex={-1}
													disableRipple
													onClick={() =>
														handleCheckbox(
															index,
															!visibilitySchema[
																index
															].absencesData,
															'absencesData'
														)
													}
												/>
											}
										/>
									)}
									Absences
								</Typography>
								<Table
									rows={item.absencesData}
									headCells={absencesHeadCells}
									hasEmptyRows={false}
									showVisibilitySettings={false}
									handleCheckboxOnChange={false}
									selectedValues={[]}
								/>
								<Typography
									align="left"
									gutterBottom
									variant="h6"
									component="h6"
									className={classes.header}
								>
									{showVisibilitySettings && (
										<FormControlLabel
											control={
												<Checkbox
													icon={<VisibilityIcon />}
													checkedIcon={
														<VisibilityIcon color="primary" />
													}
													edge="start"
													checked={
														visibilitySchema[index]
															.otherMarks
													}
													tabIndex={-1}
													disableRipple
													onClick={() =>
														handleCheckbox(
															index,
															!visibilitySchema[
																index
															].otherMarks,
															'otherMarks'
														)
													}
												/>
											}
										/>
									)}
									Other Marks
								</Typography>
								<Table
									rows={item.otherMarks}
									headCells={otherMarksHeadCells}
									hasEmptyRows={false}
									showVisibilitySettings={false}
									handleCheckboxOnChange={false}
									selectedValues={[]}
								/>
								<Typography
									align="left"
									gutterBottom
									variant="h6"
									component="h6"
									className={classes.header}
								>
									{showVisibilitySettings && (
										<FormControlLabel
											control={
												<Checkbox
													icon={<VisibilityIcon />}
													checkedIcon={
														<VisibilityIcon color="primary" />
													}
													edge="start"
													checked={
														visibilitySchema[index]
															.absencesInSchool
													}
													tabIndex={-1}
													disableRipple
													onClick={() =>
														handleCheckbox(
															index,
															!visibilitySchema[
																index
															].absencesInSchool,
															'absencesInSchool'
														)
													}
												/>
											}
										/>
									)}
									Absences in school
								</Typography>
								{item.absencesInSchool.map(school => {
									return <AbsencesInSchool data={school} />;
								})}
							</Grid>
						);
					})}
				</Grid>
			</Paper>
		</div>
	);
};

Absences.propTypes = {
	data: PropTypes.array.isRequired,
	showVisibilitySettings: PropTypes.bool.isRequired,
	changeVisibilitySettings: PropTypes.func.isRequired,
	visibilitySchema: PropTypes.array.isRequired,
	sectionSchema: PropTypes.bool.isRequired
};

export default Absences;
