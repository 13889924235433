export const styles = theme => ({
	informationWrapper: {
		paddingTop: '100px'
	},
	subHeader: {
		marginBottom: '20px',
		marginTop: '20px'
	},
	informationGrid: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8)
	}
});
