import * as actionTypes from './actionTypes';

export const addProvider = providerData => {
	return {
		type: actionTypes.ADD_PROVIDER_CONFIG,
		message: 'Provider configuration create successful.',
		requestType: 'POST',
		request: `/providerConfiguration`,
		body: {
			...providerData
		}
	};
};

export const updateProvider = providerData => {
	return {
		type: actionTypes.UPDATE_PROVIDER_CONFIG,
		message: 'Provider configuration update successful.',
		requestType: 'POST',
		request: `/providerConfig`,
		body: {
			...providerData
		}
	};
};

export const getProvider = id => {
	return {
		type: actionTypes.GET_PROVIDER,
		requestType: 'GET',
		request: `/providerConfig?providerConfig=${id}`,
		body: {
			id
		}
	};
};
