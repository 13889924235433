export const sectionTitlesMap = {
	firstName: 'First Name',
	lastName: 'Last Name',
	gender: 'Gender',
	age: 'Age',
	address: 'Address',
	language: 'Language',
	graduationYear: 'Graduation Year',
	otherId: 'Other Id',
	communityService: 'Community Service',
	second: 'Second',
	principal: 'Principal',
	grade: 'Grade',
	status: 'Status',
	homeRoom: 'Home Room',
	disciplineOfficer: 'Discipline Officer',
	school: 'School',
	schoolMail: 'School Mail',
	call: 'Call',
	itemStudentInfo: 'Emergency Contacts',
	gradebooks: 'Grade books',
	attendances: 'Attendance',
	disciplines: 'Disciplines',
	healthConditions: 'Health Conditions',
	diabeteScarelog: 'Diabetes Carelogs',
	disabilities: 'Disabilities',
	ihpForms: 'IHP Forms',
	childhoodIllnesses: 'Childhood Illnesses',
	injuries: 'Injuries',
	medications: 'Medications',
	physicalExams: 'PhysicalExams',
	tests: 'Tests',
	vaccinations: 'Vaccinations',
	race: 'Race',
	dateOfBirth: 'Date Of Birth',
	phoneNumber: 'Phone Number',
	zipCode: 'Zip Code',
	emergencyContactName: 'Emergency Contact Name',
	emergencyContactPhone: 'Emergency Contact Phone Number',
	insuranceName: 'Insurance Name',
	insuranceType: 'Insurance Type',
	insuranceNumber: 'Insurance Number',
	allergies: 'Allergies',
	diagnosis: 'Diagnosis',
	note: 'Note',
	familyHistory: 'Family History',
	medicalHistory: 'Medical History',
	alcoholUse: 'Alcohol Use',
	ouncesWeek: 'Ounces Week',
	smokelessTobaccoUse: 'Smokeless Tobacco',
	smokelessTobaccoTypes: 'Smokeless Tobacco Types',
	smokingTobaccoUse: 'Smoking Tobacco Use',
	smokingTobaccoTypes: 'Smoking Tobacco Types',
	packsDay: 'Packs per Day',
	yearsSmoked: 'Years Smoked',
	planOfCareOverdue: 'Plan Of Care Overdue',
	planOfCareNext: 'Plan Of Care Next',
	planOfCareFuture: 'Plan Of Care Future',
	immunizations: 'Immunizations',
	laboratoryTests: 'Laboratory Tests',
	surgicalHistory: 'Surgical History',
	visitNotes: 'Visit Notes',
	referredBy: 'Referred By',
	date: 'Date',
	clinic: 'Clinic',
	medicalRecordNumber: 'Medical Number',
	officeVisits: 'Office Visits',
	type: 'Type',
	name: 'Name',
	severity: 'Severity',
	code: 'Code',
	healthIssue: 'Health Issue',
	relationship: 'Relationship',
	comment: 'Comment',
	when: 'Date',
	title: 'Title',
	location: 'Location',
	orderBy: 'Order By',
	orderDetails: 'Order Details',
	studyResult: 'Study Result',
	value: 'Value',
	component: 'Component',
	range: 'Range',
	dosage: 'Dosage',
	primaryPhone: 'Primary Phone',
	homeEmail: 'Home Email',
	secondPhone: 'Second Phone',
	thirdPhone: 'Third Phone',
	employerPhone: 'Employer Phone',
	emerganceContacts: 'Emergence Contacts',
	className: 'Class Name',
	period: 'Period',
	teacher: 'Teacher',
	mark: 'Mark',
	attendance: 'Attendance',
	offense: 'Offense',
	officer: 'Officer',
	healthCondition: 'HealthCondition',
	examinedBy: 'Examined By',
	schoolYear: 'School Year',
	treatment: 'Treatment',
	parentNotified: 'Parent Notified',
	insulinDose: 'Insulin Dose',
	ketoneResults: 'Ketone Results',
	time: 'Time',
	bloodGlucose: 'Blood Glucose',
	carbIntake: 'Carb Intake',
	iob: 'IOB',
	injectionSite: 'Injection Site',
	disability: 'Disability',
	specialNeeds: 'Special Needs',
	personEntered: 'Person Entered',
	formName: 'Form Name',
	docType: 'Doc Type',
	print: 'Print',
	illness: 'Illness',
	ageDiagnosed: 'Age of Diagnosed',
	immediateCare: 'Immediate Care',
	daysMissed: 'Days Missed',
	bodyPartInjuryObservationTreatment:
		'Body Part Injury Observation Treatment',
	distribute: 'Distribute',
	prescribedBy: 'Prescribed By',
	medication: 'Medication',
	formsReceived: 'Forms Received',
	height: 'Height',
	disposition: 'Disposition',
	weight: 'Weight',
	bloodPressure: 'Blood Pressure',
	waiver: 'Waiver',
	vaccination: 'Vaccination',
	compliance: 'Compliance',
	date1: 'Date 1',
	date2: 'Date 2',
	date3: 'Date 3',
	date4: 'Date 4',
	date5: 'Date 5'
};
