import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const defaultState = fromJS({
	users: []
});

const adminUsers = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.GET_USERS:
			if (action.state === 'finished') {
				let users = action.response.toJS();
				users = users.map(user => ({
					...user,
					fullName: `${user.firstName} ${user.lastName}`
				}));

				state = state.set('users', fromJS(users));
			}
			return state;
		case actionTypes.SET_USER_TYPE:
			return state.setIn(
				['users', action.body.rowIndex, 'userType'],
				action.body.newUserType
			);
		default:
			return state;
	}
};

export default adminUsers;
