import * as actionTypes from './actionTypes';

export const getCovidReport = personId => {
	return {
		type: actionTypes.GET_COVID_REPORT,
		requestType: 'GET',
		request: `/riskScoreReport?person=${personId}`,
		body: {
			personId
		}
	};
};
