import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const SocialHistory = props => {
	const onFieldChange = event => {
		props.handleField(
			event.target.value,
			'SocialHistory',
			'socialHistory',
			[event.target.id]
		);
	};
	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom>
				Social History
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<TextField
						id="alcoholUse"
						name="alcoholUse"
						label="Alcohol Use"
						fullWidth
						value={props.data.alcoholUse}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="ouncesWeek"
						name="ouncesWeek"
						label="Ounces Week"
						fullWidth
						value={props.data.ouncesWeek}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="smokelessTobaccoUse"
						name="smokelessTobaccoUse"
						label="Smokeless Tobacco"
						value={props.data.smokelessTobaccoUse}
						fullWidth
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="smokelessTobaccoTypes"
						name="smokelessTobaccoTypes"
						label="Smokeless Tobacco Types"
						fullWidth
						value={props.data.smokelessTobaccoTypes}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="packsDay"
						name="packsDay"
						label="Packs per Day"
						fullWidth
						value={props.data.packsDay}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="smokingTobaccoTypes"
						name="smokingTobaccoTypes"
						label="Tobacco Types"
						fullWidth
						value={props.data.smokingTobaccoTypes}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="smokingTobaccoUse"
						name="smokingTobaccoUse"
						label="'Smoking Tobacco Use"
						fullWidth
						value={props.data.smokingTobaccoUse}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="yearsSmoked"
						name="yearsSmoked"
						label="Years Smoked"
						fullWidth
						value={props.data.yearsSmoked}
						onChange={onFieldChange}
					/>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

SocialHistory.propTypes = {
	data: PropTypes.array.isRequired,
	handleField: PropTypes.func.isRequired
};

export default SocialHistory;
