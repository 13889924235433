import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
	formControl: {
		width: '100%'
	},
	providerName: {
		textTransform: 'capitalize'
	}
}));

const ProviderSelect = props => {
	const classes = useStyles();

	return (
		<div>
			<FormControl variant="outlined" className={classes.formControl}>
				<InputLabel id="providers-select-label">Providers</InputLabel>
				<Select
					labelId="providers-select-label"
					id="providers-select"
					value={props.providerId}
					className={classes.providerName}
					onChange={props.handleChange}
					label="Providers"
				>
					{props.providers.map(provider => {
						return (
							<MenuItem
								className={classes.providerName}
								key={provider.id}
								value={provider.id}
							>
								{provider.name}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		</div>
	);
};

ProviderSelect.propTypes = {
	providerId: PropTypes.string.isRequired,
	handleChange: PropTypes.func.isRequired,
	providers: PropTypes.array.isRequired
};

export default ProviderSelect;
