import React from 'react';
import PropTypes, { element } from 'prop-types';
import RenderLink from '@Components/RenderLink/index';

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

const ListItemLink = props => {
	const { icon, primary, to, className } = props;

	return (
		<li>
			<RenderLink element={ListItem} button className={className} to={to}>
				{icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
				<ListItemText primary={primary} />
			</RenderLink>
		</li>
	);
};

ListItemLink.propTypes = {
	icon: PropTypes.element,
	primary: PropTypes.string.isRequired,
	className: PropTypes.string.isRequired,
	to: PropTypes.any.isRequired
};

ListItemLink.defaultProps = {
	icon: element
};

export default ListItemLink;
