import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import VirtualizedTable from '@Components/VirtualizedTable/index';

import {
	Button,
	CssBaseline,
	Typography,
	Paper,
	Container
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
/* Clear information for selected person in left navigation */
import { clearSelectedPerson } from '@SharedActions';
import { styles } from './styles';
import { getJobs, deleteJob } from './actions';

class Jobs extends Component {
	componentDidMount = async () => {
		await this.props.clearSelectedPerson();
		await this.props.getJobs();
	};

	render = () => {
		const { jobs, classes } = this.props;

		return (
			<React.Fragment>
				<CssBaseline />
				<main>
					<div className={classes.jobsContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
								className={classes.header}
							>
								Jobs
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
								className={classes.subHeader}
							>
								Please see below the status of your connections
							</Typography>
						</Container>
						<Container className={classes.jobsGrid} maxWidth="md">
							<Button
								onClick={this.props.getJobs}
								color="primary"
								variant="contained"
								className={classes.refreshButton}
							>
								Refresh
							</Button>
							<Paper
								style={{
									height: 'calc(100vh - 400px)',
									width: '930px'
								}}
							>
								<VirtualizedTable
									rowCount={jobs.length}
									rowGetter={({ index }) => jobs[index]}
									rowHeight={100}
									columns={[
										{
											width: 150,
											label: 'Connection To',
											dataKey: 'providerName'
										},
										{
											width: 120,
											label: 'Type',
											dataKey: 'type'
										},
										{
											width: 120,
											label: 'Start Time',
											dataKey: 'time',
											isDateTime: true
										},
										{
											width: 120,
											label: 'Status',
											dataKey: 'status'
										},
										{
											width: 300,
											label: 'Description',
											dataKey: 'error'
										},
										{
											width: 120,
											label: 'Actions',
											dataKey: 'id',
											customComponent: jobId => (
												<Button
													onClick={() =>
														this.props.deleteJob(
															jobId
														)
													}
													variant="contained"
													color="primary"
												>
													Delete
												</Button>
											)
										}
									]}
								/>
							</Paper>
						</Container>
					</div>
				</main>
			</React.Fragment>
		);
	};
}

Jobs.propTypes = {
	jobs: PropTypes.array.isRequired,
	classes: PropTypes.object.isRequired,
	getJobs: PropTypes.func.isRequired,
	clearSelectedPerson: PropTypes.func.isRequired,
	deleteJob: PropTypes.func.isRequired
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				jobs: state.jobs.get('jobs').toJS()
			};
		},
		{
			getJobs,
			clearSelectedPerson,
			deleteJob
		}
	)(Jobs)
);
