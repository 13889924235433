import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const defaultState = fromJS({
	jobs: []
});

const jobs = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.GET_JOBS:
			if (action.state === 'finished') {
				state = state.set('jobs', action.response);
			}
			return state;
		case actionTypes.DELETE_JOB:
			if (action.state === 'finished') {
				const currJobs = state.get('jobs').toJS();
				const newJobs = currJobs.filter(
					job => job.id !== action.body.jobId
				);

				state = state.set('jobs', fromJS(newJobs));
			}
			return state;
		default:
			return state;
	}
};

export default jobs;
