export const rankMap = {
	education: {
		firstName: 0,
		lastName: 1,
		gender: 2,
		age: 3,
		address: 4,
		language: 5,
		graduationYear: 6,
		otherId: 7,
		communityService: 8,
		second: 9,
		principal: 10,
		grade: 11,
		status: 12,
		homeRoom: 13,
		disciplineOfficer: 14,
		school: 15,
		schoolMail: 16,
		call: 17,
		itemStudentInfo: 18,
		gradebooks: 19,
		attendances: 20,
		disciplines: 21,
		healthConditions: 22,
		diabeteScarelog: 23,
		disabilities: 24,
		ihpForms: 25,
		childhoodIllnesses: 26,
		injuries: 27,
		medications: 28,
		physicalExams: 29,
		tests: 30,
		vaccinations: 31
	},
	myChart: {
		firstName: 0,
		lastName: 1,
		race: 2,
		dateOfBirth: 3,
		phoneNumber: 4,
		address: 5,
		zipCode: 6,
		emergencyContactName: 7,
		emergencyContactPhone: 8,
		clinic: 9,
		medicalRecordNumber: 10,
		insuranceName: 11,
		insuranceType: 12,
		insuranceNumber: 13,
		allergies: 14,
		diagnosis: 15,
		familyHistory: 16,
		medicalHistory: 17,
		alcoholUse: 18,
		ouncesWeek: 19,
		smokelessTobaccoUse: 20,
		smokelessTobaccoTypes: 21,
		smokingTobaccoUse: 22,
		smokingTobaccoTypes: 23,
		packsDay: 24,
		yearsSmoked: 25,
		surgicalHistory: 26,
		planOfCareOverdue: 27,
		planOfCareNext: 28,
		planOfCareFuture: 29,
		immunizations: 30,
		laboratoryTests: 31,
		medications: 32,
		files: 33
	},
	fwisd: {
		firstName: 0,
		lastName: 1,
		birthDate: 2,
		localStudentId: 3,
		tccId: 4,
		childInfoAddressAndContacts: 5,
		district: 6,
		lepFlag: 7,
		nl3Flag: 8,
		nl4Flag: 9,
		denialDate: 10,
		subjectiveTeacherEvalForm: 11,
		texasSchool: 12,
		collegePreparatoryCourses: 13,
		collegeReadinessTSI: 14,
		endorsements: 15,
		financialAid: 16,
		parentCompletedHardCopy: 17,
		plansForTheFuture: 18,
		staarEOCChecklists: 19,
		homelessIndicator: 20,
		internal1: 21,
		internal2: 22,
		internal3: 23,
		assignmentsAndGrades: 24,
		grades: 25
	},
	absences: {
		absencesData: 1,
		otherMarks: 2,
		absencesInSchool: 3
	},
	irvingISD: {
		name: 0,
		birthDate: 1,
		building: 2,
		counselor: 3,
		gender: 4,
		localStudentId: 5,
		grades: 6,
		childInfoAddressAndContacts: 7,
		attendances: 8 /* ,
		classes: 9 */
	}
};

export const authTitle =
	'The below health information has been shared with you by the person indicated. Please keep this information secure and delete if you do recognize the name below';

export const notAuthTitle =
	'Please enter code sent in a separate email to access the health summary information that has been shared with you.';

export const sectionTitlesMap = {
	firstName: 'First Name',
	lastName: 'Last Name',
	gender: 'Gender',
	age: 'Age',
	address: 'Address',
	language: 'Language',
	graduationYear: 'Graduation Year',
	otherId: 'Other Id',
	communityService: 'Community Service',
	second: 'Second',
	principal: 'Principal',
	grade: 'Grade',
	status: 'Status',
	homeRoom: 'Home Room',
	disciplineOfficer: 'Discipline Officer',
	school: 'School',
	schoolMail: 'School Mail',
	call: 'Call',
	itemStudentInfo: 'Emergency Contacts',
	gradebooks: 'Grade books',
	attendances: 'Attendance',
	disciplines: 'Disciplines',
	healthConditions: 'Health Conditions',
	diabeteScarelog: 'Diabetes Carelogs',
	disabilities: 'Disabilities',
	ihpForms: 'IHP Forms',
	childhoodIllnesses: 'Childhood Illnesses',
	injuries: 'Injuries',
	medications: 'Medications',
	physicalExams: 'PhysicalExams',
	tests: 'Tests',
	vaccinations: 'Vaccinations',
	race: 'Race',
	dateOfBirth: 'Date Of Birth',
	birthDate: 'Date Of Birth',
	phoneNumber: 'Phone Number',
	zipCode: 'Zip Code',
	emergencyContactName: 'Emergency Contact Name',
	emergencyContactPhone: 'Emergency Contact Phone Number',
	insuranceName: 'Insurance Name',
	insuranceType: 'Insurance Type',
	insuranceNumber: 'Insurance Number',
	allergies: 'Allergies',
	diagnosis: 'Diagnosis',
	familyHistory: 'Family History',
	medicalHistory: 'Medical History',
	alcoholUse: 'Alcohol Use',
	ouncesWeek: 'Ounces Week',
	smokelessTobaccoUse: 'Smokeless Tobacco',
	smokelessTobaccoTypes: 'Smokeless Tobacco Types',
	smokingTobaccoUse: 'Smoking Tobacco Use',
	smokingTobaccoTypes: 'Smoking Tobacco Types',
	packsDay: 'Packs per Day',
	yearsSmoked: 'Years Smoked',
	planOfCareOverdue: 'Plan Of Care Overdue',
	planOfCareNext: 'Plan Of Care Next',
	planOfCareFuture: 'Plan Of Care Future',
	immunizations: 'Immunizations',
	laboratoryTests: 'Laboratory Tests',
	surgicalHistory: 'Surgical History',
	referredBy: 'Referred By',
	date: 'Date',
	clinic: 'Clinic',
	medicalRecordNumber: 'Medical Number',
	officeVisits: 'Office Visits',
	type: 'Type',
	name: 'Name',
	severity: 'Severity',
	code: 'Code',
	healthIssue: 'Health Issue',
	relationship: 'Relationship',
	comment: 'Comment',
	when: 'Date',
	title: 'Title',
	location: 'Location',
	orderBy: 'Order By',
	orderDetails: 'Order Details',
	studyResult: 'Study Result',
	value: 'Value',
	component: 'Component',
	range: 'Range',
	dosage: 'Dosage',
	primaryPhone: 'Primary Phone',
	homeEmail: 'Home Email',
	secondPhone: 'Second Phone',
	thirdPhone: 'Third Phone',
	employerPhone: 'Employer Phone',
	emerganceContacts: 'Emergence Contacts',
	className: 'Class Name',
	period: 'Period',
	teacher: 'Teacher',
	mark: 'Mark',
	attendance: 'Attendance',
	offense: 'Offense',
	officer: 'Officer',
	healthCondition: 'HealthCondition',
	examinedBy: 'Examined By',
	schoolYear: 'School Year',
	treatment: 'Treatment',
	parentNotified: 'Parent Notified',
	insulinDose: 'Insulin Dose',
	ketoneResults: 'Ketone Results',
	time: 'Time',
	bloodGlucose: 'Blood Glucose',
	carbIntake: 'Carb Intake',
	iob: 'IOB',
	injectionSite: 'Injection Site',
	disability: 'Disability',
	specialNeeds: 'Special Needs',
	personEntered: 'Person Entered',
	formName: 'Form Name',
	docType: 'Doc Type',
	print: 'Print',
	illness: 'Illness',
	ageDiagnosed: 'Age of Diagnosed',
	immediateCare: 'Immediate Care',
	daysMissed: 'Days Missed',
	bodyPartInjuryObservationTreatment:
		'Body Part Injury Observation Treatment',
	distribute: 'Distribute',
	prescribedBy: 'Prescribed By',
	medication: 'Medication',
	formsReceived: 'Forms Received',
	height: 'Height',
	disposition: 'Disposition',
	weight: 'Weight',
	bloodPressure: 'Blood Pressure',
	waiver: 'Waiver',
	vaccination: 'Vaccination',
	compliance: 'Compliance',
	date1: 'Date 1',
	date2: 'Date 2',
	date3: 'Date 3',
	date4: 'Date 4',
	date5: 'Date 5',
	localStudentId: 'Local Student Id',
	tccId: 'TCC Id',
	childInfoAddressAndContacts: 'Addresses and contacts',
	mailingAddress: 'Mailing Address',
	mainAddress: 'Main Address',
	district: 'District',
	lepFlag: 'Lep Flag',
	nl3Flag: 'Nl3 Flag',
	nl4Flag: 'Nl4 Flag',
	subjectiveTeacherEvalForm: 'Subjective Teacher Eval Form',
	texasSchool: 'Texas School',
	denialDate: 'Denial Date',
	collegePreparatoryCourses: 'College Preparatory Courses',
	collegeReadinessTSI: 'College Readiness TSI',
	endorsements: 'Endorsements',
	financialAid: 'Financial Aid',
	parentCompletedHardCopy: 'Parent Completed Hard Copy',
	plansForTheFuture: 'Plans For The Future',
	staarEOCChecklists: 'Staar EOC Checklists',
	homelessIndicator: 'Homeless Indicator',
	internal1: 'Internal 1',
	internal2: 'Internal 2',
	internal3: 'Internal 3',
	grades: 'Grades',
	assignmentsAndGrades: 'Assignments And Grades',
	course: 'Course',
	courseNum: 'Course Number',
	gradeTitle: 'Grade',
	markingPeriodId: 'Marking Period',
	percentGrade: 'Percent',
	year: 'Year',
	assignmentName: 'Assignment Name',
	files: 'Files',
	mimetype: 'Type',
	fileUrl: 'Action',
	absences: 'Absences',
	absencesData: 'Absences',
	periods: 'Periods',
	absenceType: 'Absence Type',
	otherMarks: 'Other Marks',
	absencesInSchool: 'Absences In School',
	schoolName: 'School Name',
	schoolSummaryTotalDays: 'School Summary Total Days',
	totalDaysAbsent: 'Total Days Absent',
	totalDaysAttended: 'Total Days Attended',
	totalDaysPossible: 'Total Days Possible',
	daily: 'Daily',
	_00: '00',
	_01: '01',
	_02: '02',
	_03: '03',
	_04: '04',
	_05: '05',
	_06: '06',
	_07: '07',
	_08: '08',
	_09: '09',
	building: 'Building',
	counselor: 'Counselor',
	room: 'Room',
	description: 'Description',
	month: 'Month',
	classes: 'Classes'
};
