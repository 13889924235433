import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';

import { withStyles } from '@material-ui/styles';
import Copyrights from '@Components/Copyrights/index';

import { changePassword } from '../actions';

import { restPasswordStyles } from './styles';

const STRONG_PASS_REGEX = new RegExp(
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
);

class ResetPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			fields: {},
			errors: {}
		};
	}

	onChangeHandler = event => {
		const { fields } = this.state;
		fields[event.target.name] = event.target.value;
		this.setState({ fields, errors: {} });
	};

	onFormSubmit = async event => {
		event.preventDefault();
		if (this.handleValidation()) {
			const { fields } = this.state;

			const passwordData = {
				pw: fields.password,
				rpw: fields.repPassword,
				token: this.props.match.params.token
			};

			try {
				await this.props.changePassword(passwordData);
				this.props.history.push('/login');
			} catch (e) {
				console.log(e, 'error');
			}
		}
	};

	handleValidation = () => {
		const { fields } = this.state;
		const errors = {};
		let formIsValid = true;

		if (!fields.password) {
			formIsValid = false;
			errors.password = 'Field cannot be empty.';
		} else if (!STRONG_PASS_REGEX.test(fields.password)) {
			formIsValid = false;
			errors.password =
				'Password must contains at least 1 lowercase, 1 uppercase alphabetical character, 1 numeric character, one special character and eight characters or longer.';
		}

		if (!fields.repPassword) {
			formIsValid = false;
			errors.repPassword = 'Field cannot be empty.';
		} else if (!STRONG_PASS_REGEX.test(fields.repPassword)) {
			formIsValid = false;
			errors.repPassword =
				'Password must contains at least 1 lowercase, 1 uppercase alphabetical character, 1 numeric character, one special character and eight characters or longer.';
		}

		if (
			fields.password &&
			fields.repPassword &&
			fields.password !== fields.repPassword
		) {
			formIsValid = false;
			errors.repPassword = 'Passwords not match.';
		}

		this.setState({ errors });

		return formIsValid;
	};

	render() {
		const { classes } = this.props;
		const { errors, fields } = this.state;
		return (
			<div>
				<Paper className={classes.mainImage} />
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<div className={classes.paper}>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Enter your new login credentials
						</Typography>
						<form
							onSubmit={this.onFormSubmit}
							className={classes.form}
							noValidate
						>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										name="password"
										onChange={this.onChangeHandler}
										error={errors.password}
										helperText={errors.password}
										label="Password"
										type="password"
										value={fields.password}
										id="password"
										inputProps={{
											autocomplete: 'new-password',
											form: {
												autocomplete: 'off'
											}
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										name="repPassword"
										onChange={this.onChangeHandler}
										error={errors.repPassword}
										helperText={errors.repPassword}
										label="Confirm Password"
										type="password"
										value={fields.repPassword}
										id="repPassword"
										inputProps={{
											autocomplete: 'new-password',
											form: {
												autocomplete: 'off'
											}
										}}
									/>
								</Grid>
							</Grid>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
							>
								Change
							</Button>
						</form>
					</div>
					<Box mt={5}>
						<Copyrights />
					</Box>
				</Container>
			</div>
		);
	}
}

ResetPassword.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	changePassword: PropTypes.func.isRequired
};

export default withStyles(restPasswordStyles)(
	connect(null, {
		changePassword
	})(ResetPassword)
);
