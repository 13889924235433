import React from 'react';
import PropTypes from 'prop-types';

import ListItemText from '@material-ui/core/ListItemText';

import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import CardContent from '@material-ui/core/CardContent';
import ListItem from '@material-ui/core/ListItem';

import Popover from '@material-ui/core/Popover';
import Table from '@Components/Table/index';

import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const headCells = [
	{
		id: 'date',
		numeric: false,
		disablePadding: false,
		label: 'Date'
	},
	{
		id: 'daily',
		numeric: false,
		disablePadding: false,
		label: 'Daily'
	},
	{
		id: '_00',
		numeric: false,
		disablePadding: false,
		label: '00'
	},
	{
		id: '_01',
		numeric: false,
		disablePadding: false,
		label: '01'
	},
	{
		id: '_02',
		numeric: false,
		disablePadding: false,
		label: '02'
	},
	{
		id: '_03',
		numeric: false,
		disablePadding: false,
		label: '03'
	},
	{
		id: '_04',
		numeric: false,
		disablePadding: false,
		label: '04'
	},
	{
		id: '_05',
		numeric: false,
		disablePadding: false,
		label: '05'
	},
	{
		id: '_06',
		numeric: false,
		disablePadding: false,
		label: '06'
	},
	{
		id: '_07',
		numeric: false,
		disablePadding: false,
		label: '07'
	},
	{
		id: '_08',
		numeric: false,
		disablePadding: false,
		label: '08'
	},
	{
		id: '_09',
		numeric: false,
		disablePadding: false,
		label: '09'
	}
];

const useStyles = makeStyles(theme => ({
	childInfoGeneral: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	childInfoGeneralContent: {
		flexGrow: 1
	},
	informationWrapper: {
		maxWidth: '180px',
		textAlign: 'right'
	},
	informationWrapperText: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		}
	},
	checkboxWrapper: {
		height: '42px',
		minWidth: '30px'
	},
	header: {
		marginBottom: '20px',
		marginLeft: '40px',
		marginTop: '20px'
	},
	typography: {
		padding: theme.spacing(2)
	},
	ellipsisText: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		cursor: 'pointer',
		minWidth: '300px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px',
			minWidth: '58px'
		}
	},
	titleHeader: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px'
		}
	},
	title: {
		[theme.breakpoints.down('sm')]: {
			width: '140px'
		}
	}
}));

const AbsencesInSchool = props => {
	const classes = useStyles();

	const { data } = props;

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedPopOverId, setSelectedPopOverId] = React.useState(null);

	const handleClick = (event, id) => {
		setAnchorEl(event.currentTarget);
		setSelectedPopOverId(id);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setSelectedPopOverId(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const listMap = [
		{
			value: data.schoolName,
			id: 'schoolName',
			title: 'School Name'
		},
		{
			value: data.totalDaysPossible,
			id: 'totalDaysPossible',
			title: 'Total Full Days Possible'
		},
		{
			value: data.totalDaysAttended,
			id: 'totalDaysAttended',
			title: 'Total Days Attended'
		},
		{
			value: data.totalDaysAbsent,
			id: 'totalDaysAbsent',
			title: 'Total Days Absent'
		},

		{
			value: data.schoolSummaryTotalDays,
			id: 'schoolSummaryTotalDays',
			title: 'School Summary Total Days'
		}
	];

	return (
		<Grid item xs={12} sm={12} md={12} style={{ marginBottom: '10px' }}>
			<Card className={classes.childInfoGeneral}>
				<CardContent className={classes.childInfoGeneralContent}>
					<List className={classes.root}>
						{listMap.map(item => {
							const labelId = `checkbox-list-label-${item.id}`;

							return (
								<ListItem
									key={item.id}
									role={undefined}
									dense
									button
								>
									<ListItemText
										id={labelId}
										className={classes.title}
										primary={
											<div
												className={classes.titleHeader}
											>
												{`${item.title}:`}
											</div>
										}
									/>

									<ListItemText
										id={labelId}
										align="center"
										primary={
											<div
												className={
													classes.informationWrapperText
												}
											>
												{item.value || 'N/A'}
											</div>
										}
										className={classes.informationWrapper}
										onClick={e => handleClick(e, item.id)}
									/>
									<Popover
										id={id}
										open={selectedPopOverId === item.id}
										anchorEl={anchorEl}
										onClose={handleClose}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center'
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'center'
										}}
									>
										<Typography
											className={classes.typography}
										>
											{item.value || 'N/A'}
										</Typography>
									</Popover>
								</ListItem>
							);
						})}
					</List>
				</CardContent>
			</Card>

			<Table
				rows={data.absencesInSchoolDetails}
				headCells={headCells}
				hasEmptyRows={false}
				showVisibilitySettings={false}
				handleCheckboxOnChange={false}
				selectedValues={[]}
			/>
		</Grid>
	);
};

AbsencesInSchool.propTypes = {
	data: PropTypes.object.isRequired
};

export default AbsencesInSchool;
