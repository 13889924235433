import * as actionTypes from './actionTypes';

export const getProfilesList = personId => {
	return {
		type: actionTypes.GET_PROFILES,
		requestType: 'GET',
		request: `/profiles?person=${personId}`,
		body: {
			personId
		}
	};
};

export const getProfile = (profileId, profileDetailId) => {
	return {
		type: actionTypes.GET_PROFILE,
		requestType: 'GET',
		request: `/profile?id=${profileId}`,
		body: { profileId, profileDetailId }
	};
};

export const getProvider = providerId => {
	return {
		type: actionTypes.GET_PROVIDER,
		requestType: 'GET',
		request: `/provider?id=${providerId}`,
		body: { providerId }
	};
};

export const getProviderCredentials = profileId => {
	return {
		type: actionTypes.GET_PROVIDER_CREDENTIALS,
		requestType: 'GET',
		request: `/providerCredentialId?profile=${profileId}`
	};
};

export const updateProviderCredentials = credentialsData => {
	return {
		type: actionTypes.UPDATE_PROVIDER_CREDENTIALS,
		message: 'Provider credentials are updated successful',
		requestType: 'POST',
		request: `/updateCredentials`,
		body: { ...credentialsData }
	};
};

export const updateProfileVisibilitySchema = updateData => {
	return {
		type: actionTypes.UPDATE_PROFILE_VISIBILITY_SCHEMA,
		requestType: 'POST',
		message: 'Visibility settings are update successful.',
		request: `/updateProfileVisibility`,
		body: { ...updateData }
	};
};

export const deleteProfile = profileObj => {
	return {
		type: actionTypes.DELETE_PROFILE,
		requestType: 'POST',
		message: 'Profile deleted successful',
		request: `/deleteProfile`,
		body: { ...profileObj }
	};
};

export const updateProfile = profileObj => {
	return {
		type: actionTypes.UPDATE_PROFILE,
		requestType: 'POST',
		message:
			'Profile update request sent successful. You can check create status in Jobs.',
		request: `/updateVendorProfile`,
		body: { ...profileObj }
	};
};

export const moveProfile = profileObj => {
	return {
		type: actionTypes.MOVE_PROFILE,
		requestType: 'POST',
		message: 'Profile moved successful',
		request: `/moveProfile`,
		body: { ...profileObj }
	};
};

export const resetData = () => {
	return {
		type: actionTypes.RESET_DATA,
		body: {}
	};
};

export const checkAccessCode = (code, guid) => {
	return {
		type: actionTypes.CHECK_ACCESS_CODE,
		requestType: 'GET',
		request: `/checkAccessCode?code=${code}&guid=${guid}`,
		body: { code, guid }
	};
};

export const loadProfileFromTempLink = guid => {
	return {
		type: actionTypes.LOAD_PROFILE_FROM_TEMP_LINK,
		requestType: 'GET',
		request: `/tempLink?guid=${guid}`,
		body: { guid }
	};
};

export const sendTempLink = data => {
	return {
		type: actionTypes.SEND_TEMP_LINK,
		requestType: 'POST',
		message: 'Temp link was send successful. Please check your email.',
		request: `/tempLink`,
		body: {
			...data
		}
	};
};

export const getPersonsList = userId => {
	return {
		type: actionTypes.GET_PERSONS,
		requestType: 'GET',
		request: `/people?user=${userId}`,
		body: {
			userId
		}
	};
};
