export const styles = theme => ({
	importProfilesGrid: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8),
		marginTop: '40px'
	},
	importProfilesContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6)
	},
	importButtonWrapper: {
		textAlign: 'center'
	}
});
