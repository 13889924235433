import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

const Gradebooks = props => {
	const handleAdd = () => {
		props.handleAddNewItem(
			[
				{
					className: '',
					period: '',
					teacher: '',
					marks: []
				}
			],
			'Gradebooks',
			'gradebooks'
		);
	};

	const handleField = (event, index) => {
		props.handleField(event.target.value, 'Gradebooks', 'gradebooks', [
			index,
			event.target.id
		]);
	};

	const handleAddMarks = index => {
		props.handleField(
			[
				...props.data[index].marks,
				{
					mark: ''
				}
			],
			'Gradebooks',
			'gradebooks',
			[index, 'marks']
		);
	};

	const handleFieldMarks = (event, index, marksIndex) => {
		props.handleField(event.target.value, 'Gradebooks', 'gradebooks', [
			index,
			'marks',
			marksIndex,
			event.target.id
		]);
	};

	const handleDelete = index => {
		props.deleteData(index, 'Gradebooks', 'gradebooks');
	};

	const handleDeleteMarks = (index, marksIndex) => {
		const { marks } = props.data[index];
		marks.splice(marksIndex, 1);

		props.handleField([...marks], 'Gradebooks', 'gradebooks', [
			index,
			'marks'
		]);
	};

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom>
				Gradebooks
			</Typography>
			{props.data.length > 0 ? (
				props.data.map((gradebook, index) => {
					return (
						<Grid container spacing={3}>
							<Grid item xs={12} md={4}>
								<TextField
									id="className"
									label="Class Name"
									fullWidth
									onChange={e => handleField(e, index)}
									value={gradebook.className}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<TextField
									id="period"
									label="Period"
									fullWidth
									onChange={e => handleField(e, index)}
									value={gradebook.period}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="teacher"
									label="Teacher"
									fullWidth
									onChange={e => handleField(e, index)}
									value={gradebook.teacher}
								/>
							</Grid>

							<Grid item xs={12} md={3}>
								<Button
									style={{ marginTop: '20px' }}
									variant="contained"
									color="primary"
									onClick={() => handleAddMarks(index)}
								>
									Add marks
								</Button>
							</Grid>

							<Grid item xs={12} md={1}>
								<IconButton
									aria-label="delete"
									color="primary"
									style={{
										backgroundColor: '#d9534f',
										'&:hover': {
											backgroundColor: '#c9302c'
										},
										color: '#ffffff',
										height: '45px',
										width: '45px',
										marginTop: '5px',
										cursor: 'pointer'
									}}
									onClick={() => handleDelete(index)}
								>
									<DeleteIcon />
								</IconButton>
							</Grid>
							{gradebook.marks.length > 0 && (
								<Grid item xs={12} md={12}>
									<Grid container>
										<Typography variant="h6" gutterBottom>
											Marks
										</Typography>

										{gradebook.marks.map(
											(mark, markIndex) => {
												return (
													<Grid container spacing={3}>
														<Grid
															item
															xs={12}
															md={3}
														>
															<TextField
																id="mark"
																label="mark"
																fullWidth
																value={
																	mark.mark
																}
																onChange={e =>
																	handleFieldMarks(
																		e,
																		index,
																		markIndex
																	)
																}
															/>
														</Grid>

														<Grid
															item
															xs={12}
															md={1}
														>
															<IconButton
																aria-label="delete"
																color="primary"
																style={{
																	backgroundColor:
																		'#d9534f',
																	'&:hover': {
																		backgroundColor:
																			'#c9302c'
																	},
																	color:
																		'#ffffff',
																	height:
																		'45px',
																	width:
																		'45px',
																	marginTop:
																		'5px',
																	cursor:
																		'pointer'
																}}
																onClick={() =>
																	handleDeleteMarks(
																		index,
																		markIndex
																	)
																}
															>
																<DeleteIcon />
															</IconButton>
														</Grid>
													</Grid>
												);
											}
										)}
									</Grid>
								</Grid>
							)}
						</Grid>
					);
				})
			) : (
				<Typography component="h1" variant="h4" align="center">
					No Gradebooks
				</Typography>
			)}
			<Grid contariner spacing={3}>
				<Button
					style={{ marginTop: '20px' }}
					variant="contained"
					color="primary"
					onClick={handleAdd}
				>
					Add gradebooks
				</Button>
			</Grid>
		</React.Fragment>
	);
};

Gradebooks.propTypes = {
	data: PropTypes.array.isRequired,
	handleField: PropTypes.func.isRequired,
	deleteData: PropTypes.func.isRequired,
	handleAddNewItem: PropTypes.func.isRequired
};

export default Gradebooks;
