export const styles = theme => ({
	icon: {
		marginRight: theme.spacing(2)
	},
	peopleContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(0, 0, 6)
	},
	heroButtons: {
		marginTop: theme.spacing(4)
	},
	peopleGrid: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8)
	},
	avatar: {
		display: 'flex',
		flexDirection: 'column',
		height: '140px',
		width: '140px',
		margin: '0 auto'
	},
	personName: {
		textAlign: 'center',
		marginTop: '10px',
		cursor: 'pointer'
	},
	link: {
		textDecoration: 'none',
		color: 'rgba(0, 0, 0, 0.87)',
		cursor: 'pointer'
	},
	avatarWrapper: {
		'&:hover': {
			/* transform: 'scale(1.1)', */
			backgroundColor: theme.palette.primary.hover,
			'& $editButton': {
				visibility: 'visible'
			}
		}
	},
	header: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.75rem'
		}
	},
	addIcon: {
		fontSize: '120px'
	},
	input: {
		display: 'none'
	},
	uploadButtons: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '15px'
	},
	editButton: {
		visibility: 'hidden',
		'&:hover': {
			visibility: 'hidden'
		}
	},
	avatarAddButton: {
		display: 'flex',
		flexDirection: 'column',
		height: '140px',
		width: '140px',
		margin: '0 auto',
		backgroundColor: theme.palette.primary.main
	}
});
