import { fromJS } from 'immutable';
import { formatDate } from '@Common';
import * as actionTypes from './actionTypes';
import { rankMap, sectionTitlesMap } from './constants';

export const defaultState = fromJS({
	allProfiles: [],
	data: {
		myChart: {},
		education: {},
		fwisd: {},
		irvingISD: {}
	},
	allInformation: {
		myChart: [],
		education: [],
		fwisd: [],
		absences: [],
		irvingISD: []
	},
	isAccessCodeValid: false,
	personId: '',
	medicalQuestions: {
		recipients: [],
		subjects: []
	}
});

const providerTypeMap = {
	MyChart: 'myChart',
	Education: 'education',
	FWISD: 'fwisd',
	IrvingISD: 'irvingISD'
};

const iterate = (data, source, key, path, profileData) => {
	let value = source[key];
	// hot fix - Format date for date of birth. Refactor this some day
	if (key === 'dateOfBirth') {
		value = formatDate(value);
	}
	const item = {
		info: value,
		...profileData
	};

	if (
		value !== undefined &&
		value !== null &&
		((Array.isArray(value) && value.length > 0) ||
			(!Array.isArray(value) && Object.keys(value).length > 0))
	) {
		if (data.hasIn([path, key])) {
			const previousData = data.getIn([path, key]);
			data = data.setIn(
				[path, key],
				fromJS([
					...previousData.toJS(),
					{ ...item, isTable: typeof value === 'string' }
				])
			);
		} else if (Array.isArray(value) || typeof value === 'string') {
			data = data.setIn(
				[path, key],
				fromJS([{ ...item, isTable: typeof value === 'string' }])
			);
		} else {
			Object.keys(value).map(objectKey => {
				data = iterate(data, value, objectKey, path, profileData);
			});
		}
	}
	return data;
};

const generateDataArray = (data, type) => {
	let dataArray = new Array(Object.keys(rankMap[type]).length - 1);

	Object.keys(data.get(type).toJS()).map((key, index) => {
		const hasProp = Object.prototype.hasOwnProperty.call(
			rankMap[type],
			key
		);

		if (hasProp) {
			const values = data.getIn([type, key]).toJS();
			const objectValue = {
				categoryName: key,
				isNested: !values[0].isTable,
				values
			};
			dataArray.splice(rankMap[type][key], 1, objectValue);
		}
	});

	dataArray = dataArray.filter(element => {
		return element !== undefined;
	});

	return dataArray;
};

const summary = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.GET_SUMMARY_PROFILE_LIST:
			if (action.state === 'finished') {
				const allProfiles = action.response
					.getIn(['profiles', 'profiles'])
					.toJS();

				let data = state.get('data');

				allProfiles.map(profile => {
					Object.keys(profile).map(objectKey => {
						const path = providerTypeMap[profile.providerTypeName];
						const profileData = {
							profile: profile.exportPersonName,
							provider: profile.providerName
						};

						data = iterate(
							data,
							profile,
							objectKey,
							path,
							profileData
						);
					});
				});

				const educationData = generateDataArray(data, 'education');
				const myChartData = generateDataArray(data, 'myChart');
				const fwisdData = generateDataArray(data, 'fwisd');

				const irvingISDData = generateDataArray(data, 'irvingISD');

				/* HOT FIX - Custom generate for absences in fwisd (performance issue) : refactor some day */

				const absences =
					data.getIn(['fwisd', 'absences']) || fromJS([]);
				let absencesObject = fromJS({});

				absences.toJS().map(absence => {
					Object.keys(absence.info[0]).map(objectKey => {
						const profileData = {
							profile: absence.profile,
							provider: absence.provider
						};

						absence.info.map(item => {
							absencesObject = iterate(
								absencesObject,
								item,
								objectKey,
								item.year,
								profileData
							);
						});
					});
				});

				const absencesData = [];

				absencesObject.map(item => {
					let dataArray = new Array(
						Object.keys(rankMap.absences).length - 1
					);
					Object.keys(item.toJS()).map((key, index) => {
						const hasProp = Object.prototype.hasOwnProperty.call(
							rankMap.absences,
							key
						);

						if (hasProp) {
							const values = item.getIn([key]).toJS();
							const objectValue = {
								categoryName: `${
									sectionTitlesMap[key]
								} - ${item.getIn(['year', '0', 'info'])}`,
								isNested: !values[0].isTable,
								values
							};
							dataArray.splice(
								rankMap.absences[key],
								1,
								objectValue
							);

							dataArray = dataArray.filter(element => {
								return element !== undefined;
							});
						}
					});

					absencesData.push(...dataArray);
				});

				/* TODO: Remove this func for medical questions */

				/* Start Code */

				const medicalQuestions = {
					recipients: [],
					subjects: []
				};
				action.response
					.getIn(['profiles', 'profiles'])
					.toJS()
					.map(item => {
						if (
							item.medicalQuestions.hasOwnProperty('recipients')
						) {
							medicalQuestions.recipients.push(
								...item.medicalQuestions.recipients
							);
						}

						if (item.medicalQuestions.hasOwnProperty('subjects')) {
							medicalQuestions.subjects.push(
								...item.medicalQuestions.subjects
							);
						}
					});

				if (medicalQuestions.recipients.length === 0) {
					medicalQuestions.recipients.push({ name: 'No Data' });
				} else {
					medicalQuestions.recipients = medicalQuestions.recipients.filter(
						(v, i, a) => a.findIndex(t => t.name === v.name) === i
					);
				}

				if (medicalQuestions.subjects.length === 0) {
					medicalQuestions.subjects.push({ name: 'No Data' });
				} else {
					medicalQuestions.subjects = medicalQuestions.subjects.filter(
						(v, i, a) => a.findIndex(t => t.name === v.name) === i
					);
				}
				/* End Code */
				console.log(irvingISDData);

				state = state
					.setIn(['allInformation', 'myChart'], fromJS(myChartData))
					.setIn(['allInformation', 'absences'], fromJS(absencesData))
					.set('medicalQuestions', fromJS(medicalQuestions))
					.setIn(
						['allInformation', 'education'],
						fromJS(educationData)
					)
					.setIn(
						['allInformation', 'irvingISD'],
						fromJS(irvingISDData)
					)
					.setIn(['allInformation', 'fwisd'], fromJS(fwisdData));

				return state;
			}

			return state;
		case actionTypes.CHECK_ACCESS_CODE:
			if (action.state === 'finished') {
				state = state.set('isAccessCodeValid', action.response);
			}
			return state;
		case actionTypes.GET_TEMP_LINKS:
			if (action.state === 'finished') {
				state = state.set('personId', action.response.get('personId'));
			}
			return state;
		default:
			return state;
	}
};

export default summary;
