import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import authentication from '../../utilities/authentication';

const GodRoute = ({ component: Component, ...rest }) => {
	const loggedObject = authentication.loggedData();
	const isLoggedIn = loggedObject.loggedIn;
	const isGod = loggedObject.userType === 'GOD' ? true : false;

	return (
		<Route
			{...rest}
			render={props =>
				isLoggedIn && isGod ? (
					<Component {...props} />
				) : isLoggedIn ? (
					<Redirect
						to={{ pathname: '/', state: { from: props.location } }}
					/>
				) : (
					<Redirect
						to={{ pathname: '/', state: { from: props.location } }}
					/>
				)
			}
		/>
	);
};

export default GodRoute;
