import { fromJS } from 'immutable';

import createSelfProfile, {
	defaultState as createSelfProfileDefaultState
} from './pages/CreateSelfProfile/reducer';

import editSelfProfile, {
	defaultState as editSelfProfileDefaultState
} from './pages/EditSelfProfile/reducer';

export const defaultState = fromJS({
	createSelfProfile: createSelfProfileDefaultState,
	editSelfProfile: editSelfProfileDefaultState
});

const CREATE_SELF_PROFILE = 'createSelfProfile';
const EDIT_SELF_PROFILE = 'editSelfProfile';

export default function selfProfile(state = defaultState, action) {
	return state
		.set(
			EDIT_SELF_PROFILE,
			editSelfProfile(state.get(EDIT_SELF_PROFILE), action)
		)
		.set(
			CREATE_SELF_PROFILE,
			createSelfProfile(state.get(CREATE_SELF_PROFILE), action)
		);
}
