export const styles = theme => ({
	exportSummaryContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6)
	},
	exportSummaryGrid: {
		padding: theme.spacing(8)
	},
	informationWrapper: {
		paddingTop: '100px'
	},
	summaryContentGrid: {
		paddingLeft: '10px',
		paddingRight: '10px',
		maxWidth: 'unset'
	},
	informationSummaryGrid: {
		paddingLeft: '100px',
		paddingRight: '100px',
		maxWidth: 'unset'
	},
	mainContainer: {
		overflow: 'auto',
		height: '74vh'
	},
	header: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.75rem'
		}
	},
	subHeader: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.00rem'
		}
	}
});
