/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as PapaParse from 'papaparse';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	browseButtonWrapper: {
		marginTop: '20px',
		textAlign: 'center'
	},
	input: {
		display: 'none'
	}
}));

const CSVReader = props => {
	const classes = useStyles();

	const {
		onFileLoaded,
		parserOptions,
		onError,
		inputId,
		buttonStyles
	} = props;

	const handleChangeFile = e => {
		const reader = new FileReader();
		const { files } = e.target;
		if (files.length > 0) {
			const fileInfo = {
				name: files[0].name,
				size: files[0].size,
				type: files[0].type
			};
			reader.onload = _event => {
				let _a;
				const csvData = PapaParse.parse(
					reader.result,
					Object.assign(parserOptions, {
						error: onError,
						encoding: 'UTF-8'
					})
				);
				onFileLoaded(
					(_a =
						csvData === null || csvData === void 0
							? void 0
							: csvData.data) !== null && _a !== void 0
						? _a
						: [],
					fileInfo
				);
			};
			reader.readAsText(files[0], 'UTF-8');
		}
	};

	return (
		<Fragment>
			<div className={classes.browseButtonWrapper}>
				<input
					className={classes.input}
					id={inputId}
					type="file"
					accept=".csv, text/csv"
					onChange={e => handleChangeFile(e)}
				/>
				<label htmlFor={inputId}>
					<Button
						variant="contained"
						color="primary"
						component="span"
						style={buttonStyles}
					>
						Browse
					</Button>
				</label>
			</div>
		</Fragment>
	);
};

CSVReader.propTypes = {
	onFileLoaded: PropTypes.func.isRequired,
	inputId: PropTypes.string.isRequired,
	parserOptions: PropTypes.object.isRequired,
	buttonStyles: PropTypes.object.isRequired
};

export default CSVReader;
