import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

const StudentInfo = props => {
	const onFieldChange = event => {
		props.handleField(event.target.value, 'StudentInfo', 'studentInfo', [
			event.target.id
		]);
	};

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom>
				Student Info
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<TextField
						id="firstName"
						name="firstName"
						label="First name"
						fullWidth
						value={props.data.firstName}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="lastName"
						name="lastName"
						label="Last name"
						fullWidth
						value={props.data.lastName}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="gender"
						name="gender"
						label="Gender"
						fullWidth
						value={props.data.gender}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="age"
						name="age"
						label="Age"
						fullWidth
						value={props.data.age}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="address"
						name="address"
						label="Address"
						fullWidth
						value={props.data.address}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="otherId"
						name="otherId"
						label="Other id"
						fullWidth
						value={props.data.otherId}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="language"
						name="language"
						label="Language"
						fullWidth
						value={props.data.language}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="graduationYear"
						name="graduationYear"
						label="Graduation year"
						fullWidth
						value={props.data.graduationYear}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="communityService"
						name="communityService"
						label="Community service"
						fullWidth
						value={props.data.communityService}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="second"
						name="second"
						label="Second"
						fullWidth
						value={props.data.second}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="school"
						name="school"
						label="School"
						fullWidth
						value={props.data.school}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="principal"
						name="principal"
						label="Principal"
						fullWidth
						value={props.data.principal}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="grade"
						name="grade"
						label="Grade"
						fullWidth
						value={props.data.grade}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="status"
						name="status"
						label="Status"
						fullWidth
						value={props.data.status}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="homeRoom"
						name="homeRoom"
						label="Home Room"
						fullWidth
						value={props.data.homeRoom}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="disciplineOfficer"
						name="disciplineOfficer"
						label="Discipline Officer"
						fullWidth
						value={props.data.disciplineOfficer}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="schoolMail"
						name="schoolMail"
						label="School Mail"
						fullWidth
						value={props.data.schoolMail}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="call"
						name="call"
						label="Call"
						fullWidth
						value={props.data.call}
						onChange={onFieldChange}
					/>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

StudentInfo.propTypes = {
	data: PropTypes.array.isRequired,
	handleField: PropTypes.func.isRequired
};

export default StudentInfo;
