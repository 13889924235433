import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { formatDate } from '@Common';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { AutoSizer, Column, Table, InfiniteLoader } from 'react-virtualized';

const styles = theme => ({
	flexContainer: {
		display: 'flex',
		alignItems: 'center',
		boxSizing: 'border-box'
	},
	table: {
		'& .ReactVirtualized__Table__headerRow': {
			flip: false,
			paddingRight: theme.direction === 'rtl' ? '0 !important' : undefined
		}
	},
	tableRow: {
		cursor: 'pointer'
	},
	tableRowHover: {
		'&:hover': {
			backgroundColor: '#c5e2fea3'
		}
	},
	tableCell: {
		flex: 1
	},
	noClick: {
		cursor: 'initial'
	}
});

class VirtualizedTable extends React.PureComponent {
	getRowClassName = ({ index }) => {
		const { classes, onRowClick } = this.props;

		return clsx(classes.tableRow, classes.flexContainer, {
			[classes.tableRowHover]: index !== -1 && onRowClick != null
		});
	};

	cellRenderer = (
		{ cellData, columnIndex, rowIndex },
		customComponent,
		isDateTime
	) => {
		const { columns, classes, rowHeight, onRowClick } = this.props;

		let data = cellData;

		if (isDateTime) {
			data = formatDate(cellData);
		}

		if (customComponent) {
			data = customComponent(cellData, rowIndex);
		}

		return (
			<TableCell
				component="div"
				className={clsx(classes.tableCell, classes.flexContainer, {
					[classes.noClick]: onRowClick == null
				})}
				variant="body"
				style={{ height: rowHeight }}
				align={
					(columnIndex != null && columns[columnIndex].numeric) ||
					false
						? 'right'
						: 'left'
				}
			>
				{data}
			</TableCell>
		);
	};

	headerRenderer = ({ label, columnIndex }) => {
		const { headerHeight, columns, classes } = this.props;

		return (
			<TableCell
				component="div"
				className={clsx(
					classes.tableCell,
					classes.flexContainer,
					classes.noClick
				)}
				variant="head"
				style={{ height: headerHeight }}
				align={columns[columnIndex].numeric || false ? 'right' : 'left'}
			>
				<span>{label}</span>
			</TableCell>
		);
	};

	render() {
		const {
			classes,
			columns,
			rowHeight,
			headerHeight,
			loadMore,
			onRowClick,
			hasInitialWidth,
			...tableProps
		} = this.props;

		let totalTableWidth = 0;

		columns.forEach(element => {
			totalTableWidth += element.width;
		});

		return (
			<InfiniteLoader
				isRowLoaded={this.props.isRowLoaded}
				loadMoreRows={loadMore}
				rowCount={1000000}
			>
				{({ onRowsRendered, registerChild }) => (
					<AutoSizer>
						{({ height, width }) => (
							<Table
								height={height}
								width={
									hasInitialWidth ? width : totalTableWidth
								}
								rowHeight={rowHeight}
								gridStyle={{
									direction: 'inherit'
								}}
								headerHeight={headerHeight}
								className={classes.table}
								{...tableProps}
								rowClassName={this.getRowClassName}
								ref={registerChild}
								onRowClick={onRowClick}
								onRowsRendered={onRowsRendered}
							>
								{columns.map(
									(
										{
											dataKey,
											customComponent,
											isDateTime,
											...other
										},
										index
									) => {
										return (
											<Column
												key={dataKey}
												headerRenderer={headerProps =>
													this.headerRenderer({
														...headerProps,
														columnIndex: index
													})
												}
												className={
													classes.flexContainer
												}
												cellRenderer={e =>
													this.cellRenderer(
														e,
														customComponent,
														isDateTime
													)
												}
												dataKey={dataKey}
												{...other}
											/>
										);
									}
								)}
							</Table>
						)}
					</AutoSizer>
				)}
			</InfiniteLoader>
		);
	}
}

VirtualizedTable.propTypes = {
	classes: PropTypes.object.isRequired,
	onRowClick: PropTypes.func,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			dataKey: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			numeric: PropTypes.bool,
			width: PropTypes.number.isRequired
		})
	).isRequired,
	headerHeight: PropTypes.number,
	onRowClick: PropTypes.func,
	loadMore: PropTypes.func,
	isRowLoaded: PropTypes.func,
	rowHeight: PropTypes.number,
	hasInitialWidth: PropTypes.bool
};

VirtualizedTable.defaultProps = {
	headerHeight: 48,
	rowHeight: 48,
	loadMore: () => {},
	onRowClick: () => {},
	isRowLoaded: () => {},
	onRowClick: () => {},
	hasInitialWidth: false
};

export default withStyles(styles)(VirtualizedTable);
