import { fromJS } from 'immutable';

import vaccineDashboard, {
	defaultState as vaccineDashboardDefaultState
} from './pages/VaccineDashboard/reducer';
import covidHesitancyAnalysis, {
	defaultState as covidHesitancyAnalysisDefaultState
} from './pages/CovidHesitancyAnalysis/reducer';

export const defaultState = fromJS({
	vaccineDashboard: vaccineDashboardDefaultState,
	covidHesitancyAnalysis: covidHesitancyAnalysisDefaultState
});

const VACCINE_DASHBOARD = 'vaccineDashboard';
const COVID_HESITANCY_ANALYSIS = 'covidHesitancyAnalysis';

export default function unauthenticatedPages(state = defaultState, action) {
	return state
		.set(
			VACCINE_DASHBOARD,
			vaccineDashboard(state.get(VACCINE_DASHBOARD), action)
		)
		.set(
			COVID_HESITANCY_ANALYSIS,
			covidHesitancyAnalysis(state.get(COVID_HESITANCY_ANALYSIS), action)
		);
}
