import profilesImage from '@Images/profiles.jpg';
import visitsImage from '@Images/visits.jpg';

export const CARDS = [
	{
		name: 'profile',
		title: 'My Connections',
		url: id => `/dashboard/${id}/profiles`,
		description: 'See here all your connections and profiles.',
		image: profilesImage
	},
	{
		name: 'visits',
		title: 'My Visits',
		url: id => `/dashboard/${id}/visits`,
		description: 'See here the information for all your hospital visits.',
		image: visitsImage
	}
];
