export const styles = theme => ({
	fullName: {
		fontSize: '2.75rem',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem'
		}
	},
	visibilityIcon: {
		height: '40px',
		width: '40px',
		bottom: '-5px',
		position: 'absolute',
		right: '0px',
		[theme.breakpoints.down('sm')]: {
			height: '35px',
			width: '35px',
			bottom: '0'
		}
	},
	fullNameWrapper: {
		marginTop: '100px',
		marginLeft: '50px',
		marginRight: '50px',
		[theme.breakpoints.down('sm')]: {
			marginTop: '0px'
		}
	}
});
