import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const IhpForms = props => {
	const handleDateChange = (date, index) => {
		props.handleField(date, 'IhpForms', 'ihpForms', [index, 'date']);
	};

	const handleAdd = () => {
		props.handleAddNewItem(
			[
				{
					print: '',
					schoolYear: '',
					date: new Date(),
					time: '',
					formName: '',
					personEntered: '',
					docType: ''
				}
			],
			'IhpForms',
			'ihpForms'
		);
	};

	const handleField = (event, index) => {
		props.handleField(event.target.value, 'IhpForms', 'ihpForms', [
			index,
			event.target.id
		]);
	};

	const handleDelete = index => {
		props.deleteData(index, 'IhpForms', 'ihpForms');
	};

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom>
				IHP Forms
			</Typography>
			{props.data.length > 0 ? (
				props.data.map((form, index) => {
					return (
						<Grid container spacing={3}>
							<Grid item xs={12} md={3}>
								<TextField
									id="print"
									label="Print"
									fullWidth
									onChange={e => handleField(e, index)}
									value={form.print}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="schoolYear"
									label="School Year"
									fullWidth
									onChange={e => handleField(e, index)}
									value={form.schoolYear}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										disableToolbar
										variant="inline"
										format="MM/dd/yyyy"
										margin="normal"
										id="date"
										label="Date"
										value={form.date}
										style={{
											width: '100%',
											marginTop: 0,
											marginBottom: 0
										}}
										autoOk
										onChange={date =>
											handleDateChange(date, index)
										}
										KeyboardButtonProps={{
											'aria-label': 'change date'
										}}
									/>
								</MuiPickersUtilsProvider>
							</Grid>

							<Grid item xs={12} md={3}>
								<TextField
									id="time"
									label="Time"
									fullWidth
									onChange={e => handleField(e, index)}
									value={form.time}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="formName"
									label="Form Name"
									fullWidth
									onChange={e => handleField(e, index)}
									value={form.formName}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="personEntered"
									label="Person Entered"
									fullWidth
									onChange={e => handleField(e, index)}
									value={form.personEntered}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="docType"
									label="Doc Type"
									fullWidth
									onChange={e => handleField(e, index)}
									value={form.docType}
								/>
							</Grid>
							<Grid item xs={12} md={1}>
								<IconButton
									aria-label="delete"
									color="primary"
									style={{
										backgroundColor: '#d9534f',
										'&:hover': {
											backgroundColor: '#c9302c'
										},
										color: '#ffffff',
										height: '45px',
										width: '45px',
										marginTop: '5px',
										cursor: 'pointer'
									}}
									onClick={() => handleDelete(index)}
								>
									<DeleteIcon />
								</IconButton>
							</Grid>
						</Grid>
					);
				})
			) : (
				<Typography component="h1" variant="h4" align="center">
					No IHP Forms
				</Typography>
			)}
			<Grid contariner spacing={3}>
				<Button
					style={{ marginTop: '20px' }}
					variant="contained"
					color="primary"
					onClick={handleAdd}
				>
					Add IHP Form
				</Button>
			</Grid>
		</React.Fragment>
	);
};

IhpForms.propTypes = {
	data: PropTypes.array.isRequired,
	handleField: PropTypes.func.isRequired,
	deleteData: PropTypes.func.isRequired,
	handleAddNewItem: PropTypes.func.isRequired
};

export default IhpForms;
