import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
	CssBaseline,
	Grid,
	Container,
	TextField,
	Button
} from '@material-ui/core';

import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/styles';

import { addProvider, getProvider, updateProvider } from './actions';

import { styles } from './styles';

import { infoComponents } from './constants';

class ProviderForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			providerTypeId: '',
			providerTypeName: 'MyChart',
			configuration: {},
			name: ''
		};
	}

	componentDidMount = async () => {
		const { type, providerId } = this.props.location.state;
		const { typeName } = this.props.match.params;

		let { configuration } = infoComponents[typeName];

		let name = '';

		if (type === 'update') {
			const providerConfiguration = await this.props.getProvider(
				providerId
			);

			configuration = providerConfiguration.response
				.get('configuration')
				.toJS();
			name = providerConfiguration.response.get('name');
		}

		this.setState({
			providerTypeId: this.props.match.params.typeId,
			providerTypeName: this.props.match.params.typeName,
			configuration,
			name
		});
	};

	onFieldChange = event => {
		const { configuration } = this.state;
		this.setState({
			configuration: {
				...configuration,
				[event.target.id]: event.target.value
			}
		});
	};

	onFieldChangeName = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	handleSaveProvider = () => {
		const { providerTypeId, name, configuration } = this.state;
		const { type, providerId } = this.props.location.state;

		const providerData = {
			providerTypeId,
			name,
			configuration
		};

		if (type === 'update') {
			providerData.id = providerId;
			this.props.updateProvider(providerData);
		} else {
			this.props.addProvider(providerData);
		}
	};

	handleCancel = () => {};

	render = () => {
		const { classes } = this.props;
		const { providerTypeName, name, configuration } = this.state;

		const renderMap = infoComponents[providerTypeName].componentsMap;

		return (
			<React.Fragment>
				<CssBaseline />
				<main>
					<div className={classes.providerCreateContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
								className={classes.header}
							>
								Providers Administration
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
								className={classes.subHeader}
							>
								Create new provider of type {providerTypeName}
							</Typography>
						</Container>
					</div>
					<Container
						className={classes.providerCreateGrid}
						maxWidth="md"
					>
						<Grid container spacing={4}>
							<div className={classes.nameFiledWrapper}>
								<Typography variant="h6" gutterBottom>
									Name Provider
								</Typography>
								<Grid container spacing={3}>
									<Grid item xs={12} md={6}>
										<TextField
											id="name"
											label="Name"
											value={name}
											fullWidth
											onChange={this.onFieldChangeName}
										/>
									</Grid>
								</Grid>
							</div>

							{renderMap.map(ComponentItem => (
								<ComponentItem
									handleField={this.onFieldChange}
									data={configuration}
								/>
							))}

							<Grid container spacing={3}>
								<Grid item xs={12} md={3}>
									<Button
										onClick={this.handleSaveProvider}
										variant="contained"
										color="primary"
										className={classes.saveButton}
									>
										Save Provider
									</Button>
								</Grid>
								<Grid item xs={12} md={3}>
									<Button
										onClick={this.handleCancel}
										variant="contained"
										color="primary"
										className={classes.cancelButton}
									>
										Cancel
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Container>
				</main>
			</React.Fragment>
		);
	};
}

ProviderForm.propTypes = {
	classes: PropTypes.object.isRequired,
	addProvider: PropTypes.func.isRequired,
	getProvider: PropTypes.func.isRequired,
	updateProvider: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			typeId: PropTypes.string.isRequired,
			typeName: PropTypes.string.isRequired
		})
	}),
	location: PropTypes.shape({
		state: PropTypes.shape({
			providerId: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired
		})
	})
};

ProviderForm.defaultProps = {
	match: {},
	location: {}
};

export default withStyles(styles)(
	connect(null, {
		addProvider,
		getProvider,
		updateProvider
	})(ProviderForm)
);
