import React from 'react';

import GodRoute from '@Components/Routes/GodRoute';
import ProviderList from './pages/ProviderList/index';
import ProviderForm from './pages/ProviderForm/index';

class Providers extends React.Component {
	render() {
		return (
			<React.Fragment>
				<GodRoute
					path="/admin/providers"
					exact
					component={ProviderList}
				/>
				<GodRoute
					path="/admin/providers/providerCreate/:typeName/:typeId"
					exact
					component={ProviderForm}
				/>
				<GodRoute
					path="/admin/providers/providerUpdate/:typeName/:typeId"
					exact
					component={ProviderForm}
				/>
			</React.Fragment>
		);
	}
}

export default Providers;
