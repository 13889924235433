import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';

import Grid from '@material-ui/core/Grid';
import { CssBaseline, Typography, Container } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { styles } from './styles';

import { infoComponents, initialState } from './constants';

import { getProfile, getProvider } from '../../actions';

class ProfileAdminInformation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...initialState
		};
	}

	componentDidMount = async () => {
		const { providerId, profileId } = this.props.location.state;

		await this.props.getProfile(profileId, '');
		await this.props.getProvider(providerId);
	};

	render = () => {
		const { profileData, providerData, classes } = this.props;

		const { showVisibilitySettings, visibilitySchema } = this.state;

		const infoComponentsMap = infoComponents[providerData.providerTypeName];

		return (
			<Fragment>
				<CssBaseline />
				<main>
					<div className={classes.informationWrapper}>
						<Container
							className={classes.informationGrid}
							maxWidth="md"
						>
							{profileData.get('content') &&
								!!providerData.providerName && (
									<Grid
										style={{ marginBottom: '10px' }}
										container
										spacing={7}
									>
										<Grid
											item
											xs={12}
											md={12}
											className={classes.buttonsWrapper}
										>
											<Typography
												component="h1"
												variant="h2"
												align="center"
												color="textPrimary"
												gutterBottom
												className={classes.header}
											>
												{`${providerData.providerName.toUpperCase()} - ${profileData.get(
													'firstName'
												)} ${profileData.get(
													'lastName'
												)}`}
											</Typography>
										</Grid>
										<Grid
											item
											xs={12}
											md={12}
											className={classes.buttonsWrapper}
										>
											{infoComponentsMap.map(item => {
												const ItemComponent = item.comp;

												const schema = fromJS(
													visibilitySchema
												);
												const currentSchema = schema.getIn(
													[...item.schemaProp]
												);

												const sectionSchema = schema.getIn(
													[
														'_globalSettings',
														'elements',
														...item.sectionSchemaProp
													]
												);

												return (
													<Grid container spacing={4}>
														<ItemComponent
															data={profileData
																.getIn([
																	...item.data
																])
																.toJS()}
															providerName={
																providerData
															}
															showVisibilitySettings={
																showVisibilitySettings
															}
															changeVisibilitySettings={() => {}}
															visibilitySchema={currentSchema.toJS()}
															sectionSchema={
																sectionSchema
															}
														/>
													</Grid>
												);
											})}
										</Grid>
									</Grid>
								)}
						</Container>
					</div>
				</main>
			</Fragment>
		);
	};
}

ProfileAdminInformation.propTypes = {
	getProfile: PropTypes.func.isRequired,
	getProvider: PropTypes.func.isRequired,
	profileData: PropTypes.object.isRequired,
	providerData: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
	location: PropTypes.shape({
		state: PropTypes.shape({
			profileId: PropTypes.string.isRequired,
			providerId: PropTypes.string.isRequired
		})
	})
};

ProfileAdminInformation.defaultProps = {
	location: {}
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				profileData: state.dashboard.getIn([
					'profileDashboard',
					'profileData'
				]),
				providerData: state.dashboard
					.getIn(['profileDashboard', 'providerData'])
					.toJS()
			};
		},
		{
			getProfile,
			getProvider
		}
	)(ProfileAdminInformation)
);
