import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const defaultState = fromJS({
	profilesList: [],
	profileData: {},
	providerData: {
		providerTypeName: 'MyChart'
	},
	providerCredentials: {},
	isAccessCodeValid: false,
	hiddenProfile: false,
	personsList: []
});

const profileDashboard = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.GET_PROFILES:
			if (action.state === 'finished') {
				const profiles = action.response.toJS().map(profile => {
					return {
						...profile,
						name: profile.profileName.split('-')[1],
						providerName: profile.profileName.split('-')[0]
					};
				});
				state = state.set('profilesList', fromJS(profiles));
			}
			return state;
		case actionTypes.GET_PROVIDER_CREDENTIALS:
			if (action.state === 'finished') {
				state = state.set('providerCredentials', action.response);
			}
			return state;
		case actionTypes.GET_PROVIDER:
			if (action.state === 'finished') {
				state = state.set('providerData', action.response);
			}
			return state;

		case actionTypes.GET_PROFILE:
			if (action.state === 'finished') {
				state = state.set(
					'profileData',
					fromJS({
						...action.response.toJS(),
						profileDetailId: action.body.profileDetailId
					})
				);
			}
			return state;
		case actionTypes.RESET_DATA:
			state = defaultState;

			return state;
		case actionTypes.DELETE_PROFILE:
			if (action.state === 'finished') {
				const newProfilesList = state
					.get('profilesList')
					.toJS()
					.filter(profile => profile.id !== action.body.profileId);

				state = state.set('profilesList', fromJS(newProfilesList));
			}
			return state;
		case actionTypes.MOVE_PROFILE:
			if (action.state === 'finished') {
				const newProfilesList = state
					.get('profilesList')
					.toJS()
					.filter(profile => profile.id !== action.body.profileId);

				state = state.set('profilesList', fromJS(newProfilesList));
			}
			return state;
		case actionTypes.CHECK_ACCESS_CODE:
			if (action.state === 'finished') {
				state = state.set('isAccessCodeValid', action.response);
			}
			return state;
		case actionTypes.GET_PERSONS:
			if (action.state === 'finished') {
				state = state.set('personsList', action.response);
			}
			return state;
		case actionTypes.LOAD_PROFILE_FROM_TEMP_LINK:
			if (action.state === 'finished') {
				const profileData = action.response.getIn([
					'content',
					'responseData',
					'profile'
				]);

				if (!profileData) {
					state = state.set('hiddenProfile', true);
				}

				state = state
					.set(
						'providerData',
						action.response.getIn(['content', 'parentData'])
					)
					.set('profileData', profileData || fromJS({}));
			}
			return state;

		default:
			return state;
	}
};

export default profileDashboard;
