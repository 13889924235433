import { fromJS } from 'immutable';

import adminUsers, {
	defaultState as adminUsersDefaultState
} from './pages/Users/reducer';

import profiles, {
	defaultState as profilesDefaultState
} from './pages/ProfilesList/reducer';

import covidFeedbacks, {
	defaultState as covidFeedbacksDefaultState
} from './pages/CovidFeedbacks/reducer';

import feedbacks, {
	defaultState as feedbacksDefaultState
} from './pages/Feedbacks/reducer';

import requestedZipCodes, {
	defaultState as requestedZipCodesDefaultState
} from './pages/RequestedZipCodes/reducer';

import providers, {
	defaultState as providersDefaultState
} from './pages/Providers/reducer';

export const defaultState = fromJS({
	adminUsers: adminUsersDefaultState,
	profiles: profilesDefaultState,
	covidFeedbacks: covidFeedbacksDefaultState,
	requestedZipCodes: requestedZipCodesDefaultState,
	feedbacks: feedbacksDefaultState,
	providers: providersDefaultState
});

const ADMIN_USERS = 'adminUsers';
const PROFILES = 'profiles';
const COVID_FEEDBACKS = 'covidFeedbacks';
const FEEDBACKS = 'feedbacks';
const REQUESTED_ZIP_CODES = 'requestedZipCodes';
const PROVIDERS = 'providers';

export default function admin(state = defaultState, action) {
	return state
		.set(ADMIN_USERS, adminUsers(state.get(ADMIN_USERS), action))
		.set(
			COVID_FEEDBACKS,
			covidFeedbacks(state.get(COVID_FEEDBACKS), action)
		)
		.set(FEEDBACKS, feedbacks(state.get(FEEDBACKS), action))
		.set(
			REQUESTED_ZIP_CODES,
			requestedZipCodes(state.get(REQUESTED_ZIP_CODES), action)
		)
		.set(PROVIDERS, providers(state.get(PROVIDERS), action))
		.set(PROFILES, profiles(state.get(PROFILES), action));
}
