import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Copyrights from '@Components/Copyrights/index';
import { withStyles } from '@material-ui/styles';
import {
	TextField,
	Avatar,
	Button,
	CssBaseline,
	FormControlLabel,
	Checkbox,
	Link,
	Paper,
	Box,
	Grid,
	Typography,
	CardMedia
} from '@material-ui/core';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import logoBig from '@Images/MyPHI-Logo.png';
import { logIn } from '@SharedActions';
import RenderLink from '@Components/RenderLink/index';

import { loginStyles } from './styles';
import PWAInstallDialog from '../components/PWAInstallDialog/index';

class Login extends Component {
	constructor(props) {
		super(props);

		let promptData = JSON.parse(localStorage.getItem('iosPwaDialog'));

		if (promptData === null) {
			promptData = { visits: 0 };
			localStorage.setItem('iosPwaDialog', JSON.stringify(promptData));
		}

		this.state = {
			fields: {},
			errors: {},
			showDialog:
				JSON.parse(localStorage.getItem('iosPwaDialog')).visits <= 5
		};
	}

	onChangeHandler = event => {
		const { fields } = this.state;
		fields[event.target.name] = event.target.value;
		this.setState({ fields, errors: {} });
	};

	onFormSubmit = async event => {
		event.preventDefault();

		if (this.handleValidation()) {
			const loginData = {
				email: this.state.fields.email.toLowerCase(),
				password: this.state.fields.password
			};

			try {
				await this.props.logIn(loginData);
				this.props.history.push({
					pathname: '/',
					state: { isCreateDialogOpen: false }
				});
			} catch (e) {
				console.log(e);
			}
		}
	};

	handleValidation = () => {
		const { fields } = this.state;
		const errors = {};
		let formIsValid = true;

		if (!fields.email) {
			formIsValid = false;
			errors.email = 'Please enter your email.';
		}

		if (!fields.password) {
			formIsValid = false;
			errors.password = 'Please enter your password.';
		}

		this.setState({ errors });

		return formIsValid;
	};

	deviceCheck = () => {
		const isiOS = /iphone|ipad|ipod/.test(
			window.navigator.userAgent.toLowerCase()
		);
		const isiPadOS =
			navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
		const isStandalone =
			'standalone' in window.navigator && window.navigator.standalone;

		const isSafari = !!navigator.userAgent.match(
			/Version\/[\d\.]+.*Safari/
		);

		return (isiOS || isiPadOS) && !isStandalone && isSafari;
	};

	render() {
		const { classes } = this.props;
		const { errors, showDialog } = this.state;

		return (
			<Grid container component="main" className={classes.root}>
				<CssBaseline />
				<Grid item xs={false} sm={4} md={7} className={classes.image} />
				<Grid
					item
					xs={12}
					sm={8}
					md={5}
					component={Paper}
					elevation={6}
					square
				>
					<div className={classes.paper}>
						<CardMedia
							className={classes.logo}
							component="img"
							alt="MyPHI"
							image={logoBig}
							title="MyPHI"
						/>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5">
							Sign in
						</Typography>
						<form
							onSubmit={this.onFormSubmit}
							className={classes.form}
							noValidate
						>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								autoFocus
								error={errors.email}
								helperText={errors.email}
								onChange={this.onChangeHandler}
							/>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="current-password"
								error={errors.password}
								helperText={errors.password}
								onChange={this.onChangeHandler}
							/>
							<FormControlLabel
								control={
									<Checkbox
										value="remember"
										color="primary"
									/>
								}
								label="Remember me"
							/>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}
							>
								Sign In
							</Button>
							<Grid container>
								<Grid item xs style={{ textAlign: 'center' }}>
									<RenderLink
										element={Link}
										to="/user/forgottenPassword"
										variant="body2"
									>
										Forgot password
									</RenderLink>
								</Grid>
								<Grid item xs style={{ textAlign: 'center' }}>
									<RenderLink
										element={Link}
										to="/vaccinations-centers"
										variant="body2"
									>
										Interactive Locator
									</RenderLink>
								</Grid>
								<Grid item xs style={{ textAlign: 'center' }}>
									<RenderLink
										element={Link}
										to="/register"
										variant="body2"
									>
										Don&apos;t have an account? Sign Up
									</RenderLink>
								</Grid>
								{this.deviceCheck() && (
									<>
										<Grid
											item
											xs
											style={{ textAlign: 'center' }}
										>
											<PWAInstallDialog
												forceOpen={showDialog}
											/>
										</Grid>
									</>
								)}
							</Grid>
							<Box mt={5}>
								<Copyrights />
							</Box>
						</form>
					</div>
				</Grid>
			</Grid>
		);
	}
}

Login.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	logIn: PropTypes.func.isRequired
};

export default withStyles(loginStyles)(
	connect(null, {
		logIn
	})(Login)
);
