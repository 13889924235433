import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
	CssBaseline,
	Typography,
	Container,
	Avatar,
	Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import { getVisitsList, resetData } from '../../actions';

import { styles } from './styles';

class VisitsList extends Component {
	componentDidMount = async () => {
		const { personId } = this.props.match.params;
		await this.props.resetData();
		await this.props.getVisitsList(personId);
	};

	loadVisitData = visit => {
		const { person } = this.props.location.state;

		this.props.history.push({
			pathname: `/dashboard/${person.id}/visits/${visit.id}`,
			state: { person, visit }
		});
	};

	render = () => {
		const { classes, visits } = this.props;
		return (
			<Fragment>
				<CssBaseline />
				<div className={classes.content}>
					<main>
						<Typography
							component="h1"
							variant="h2"
							align="center"
							color="textPrimary"
							gutterBottom
							className={classes.header}
						>
							Visits List
						</Typography>
						<Typography
							variant="h5"
							align="center"
							color="textSecondary"
							paragraph
						>
							Please select visit
						</Typography>
						<Container className={classes.visitsGrid}>
							<Grid container spacing={5}>
								{visits.length === 0 && (
									<Grid item xs={12} sm={12} md={12}>
										<Typography
											variant="h6"
											align="center"
											color="textSecondary"
										>
											This person has no visits
										</Typography>
									</Grid>
								)}

								{visits.map(visit => (
									<Grid
										onClick={() =>
											this.loadVisitData(visit)
										}
										item
										key={visit.id}
										xs={12}
										sm={6}
										md={3}
										className={classes.avatarWrapper}
									>
										<div className={classes.link}>
											<Avatar
												className={classes.avatar}
												alt={visit.name}
											>
												<SupervisorAccountIcon
													className={classes.icons}
												/>
											</Avatar>
											<Typography
												className={classes.personName}
											>
												{visit.visitName}
											</Typography>
										</div>
									</Grid>
								))}
							</Grid>
						</Container>
					</main>
				</div>
			</Fragment>
		);
	};
}

VisitsList.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	visits: PropTypes.array.isRequired,
	resetData: PropTypes.func.isRequired,
	getVisitsList: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			personId: PropTypes.string.isRequired
		})
	}),
	location: PropTypes.shape({
		state: PropTypes.shape({
			person: PropTypes.object.isRequired
		})
	})
};

VisitsList.defaultProps = {
	location: {},
	match: {}
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				visits: state.dashboard
					.getIn(['visitDashboard', 'visitsList'])
					.toJS()
			};
		},
		{
			getVisitsList,
			resetData
		}
	)(VisitsList)
);
