import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@Components/TextField/index';

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: '20px',
		width: '100%',
		border: '1px solid rgba(0, 0, 0, 0.12)',
		padding: '30px'
	}
}));

const keyMap = [
	{
		id: 'patientInformationUrl',
		label: 'Url'
	},
	{
		id: 'patientInformationSelectorName',
		label: 'Name'
	},
	{
		id: 'selectorPassword',
		label: 'Password'
	},
	{
		id: 'patientInformationSelectorBirth',
		label: 'Birth'
	},
	{
		id: 'patientInformationSelectorRace',
		label: 'Race'
	},
	{
		id: 'patientInformationSelectorMedicalRecordNumbers',
		label: 'Medical Record Numbers'
	},
	{
		id: 'patientInformationSelectorPrimaryProvider',
		label: 'Primary Provider'
	},
	{
		id: 'patientInformationSelectorClinic',
		label: 'Clinic'
	},
	{
		id: 'patientInformationSelectorAddress',
		label: 'Address'
	},
	{
		id: 'patientInformationSelectorPhone',
		label: 'Phone'
	},
	{
		id: 'patientInformationSelectorEmergencyContactName',
		label: 'Emergency Contact Name'
	},
	{
		id: 'patientInformationSelectorEmergencyContactPhone',
		label: 'Emergency Contact Phone'
	},
	{
		id: 'patientInformationSelectorInshuranceName',
		label: 'Insurance Name'
	},
	{
		id: 'patientInformationSelectorInshuranceType',
		label: 'Insurance Type'
	},
	{
		id: 'patientInformationSelectorInshuranceNumber',
		label: 'Insurance Number'
	}
];

const PatientInformationSelectors = props => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography variant="h6" gutterBottom>
				Patient Information Selectors
			</Typography>
			<Grid container spacing={3}>
				{keyMap.map(item => (
					<Grid item xs={12} md={6}>
						<TextField
							id={item.id}
							label={item.label}
							fullWidth
							onChange={props.handleField}
							value={props.data[item.id]}
						/>
					</Grid>
				))}
			</Grid>
		</div>
	);
};

PatientInformationSelectors.propTypes = {
	handleField: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired
};

export default PatientInformationSelectors;
