import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		minWidth: 500
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	}
}));

export default function DeleteVisitDialog(props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Button
				variant="contained"
				color="primary"
				onClick={handleClickOpen}
				style={{
					width: '100%',
					backgroundColor: '#d9534f'
				}}
			>
				Delete Visit
			</Button>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				className={classes.root}
			>
				<DialogTitle id="form-dialog-title">
					Delete Visit Confirmation
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete this Visit?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button onClick={props.deleteVisit} color="primary">
						Confirm Delete Visit
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
