import * as actionTypes from './actionTypes';

export const importRiskScores = data => {
	return {
		type: actionTypes.IMPORT_RISK_SCORES,
		requestType: 'POST',
		message: 'File is imported successful.',
		request: `/import`,
		body: {
			data,
			importType: 'riskScore'
		}
	};
};
