import * as actionTypes from './actionTypes';

export const getVaccines = params => {
	return {
		type: actionTypes.GET_VACCINES,
		requestType: 'GET',
		request: `/getVaccineProvider?zipCode=${params.zipCode}&address=${params.address}&range=${params.range}`,
		data: { ...params }
	};
};

export const checkAgain = params => {
	return {
		type: actionTypes.CHECK_AGAIN,
		data: { ...params }
	};
};

export const goToPoint = id => {
	return {
		type: actionTypes.GO_TO_POINT,
		data: { id }
	};
};
