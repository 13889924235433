import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

const RenderLink = props => {
	const { children, to, element } = props;

	const renderLink = React.useMemo(
		() =>
			React.forwardRef((itemProps, ref) => (
				<RouterLink to={to} ref={ref} {...itemProps} />
			)),
		[to]
	);

	const Component = element;

	return (
		<Fragment>
			<Component {...props} component={renderLink}>
				{children}
			</Component>
		</Fragment>
	);
};

RenderLink.propTypes = {
	children: PropTypes.any.isRequired,
	element: PropTypes.any.isRequired,
	to: PropTypes.any.isRequired
};

export default RenderLink;
