import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

const EmerganceContacts = props => {
	const handleAdd = () => {
		props.handleAddNewItem(
			[
				{
					emerganceContacts: '',
					primaryPhone: '',
					secondPhone: '',
					thirdPhone: '',
					employerPhone: '',
					homeEmail: ''
				}
			],
			'EmerganceContacts',
			'emerganceContacts'
		);
	};

	const handleField = (event, index) => {
		props.handleField(
			event.target.value,
			'EmerganceContacts',
			'emerganceContacts',
			[index, event.target.id]
		);
	};

	const handleDelete = index => {
		props.deleteData(index, 'EmerganceContacts', 'emerganceContacts');
	};

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom>
				Emergency Contacts
			</Typography>
			{props.data.length > 0 ? (
				props.data.map((contact, index) => {
					return (
						<Grid container spacing={3}>
							<Grid item xs={12} md={4}>
								<TextField
									id="emerganceContacts"
									label="Emergency"
									fullWidth
									onChange={e => handleField(e, index)}
									value={contact.emerganceContacts}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<TextField
									id="primaryPhone"
									label="Primary Phone"
									fullWidth
									onChange={e => handleField(e, index)}
									value={contact.primaryPhone}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="secondPhone"
									label="Second Phone"
									fullWidth
									onChange={e => handleField(e, index)}
									value={contact.secondPhone}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<TextField
									id="thirdPhone"
									label="Third Phone"
									fullWidth
									onChange={e => handleField(e, index)}
									value={contact.thirdPhone}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<TextField
									id="employerPhone"
									label="Employer Phone"
									fullWidth
									onChange={e => handleField(e, index)}
									value={contact.employerPhone}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="homeEmail"
									label="Home email"
									fullWidth
									onChange={e => handleField(e, index)}
									value={contact.homeEmail}
								/>
							</Grid>
							<Grid item xs={12} md={1}>
								<IconButton
									aria-label="delete"
									color="primary"
									style={{
										backgroundColor: '#d9534f',
										'&:hover': {
											backgroundColor: '#c9302c'
										},
										color: '#ffffff',
										height: '45px',
										width: '45px',
										marginTop: '5px',
										cursor: 'pointer'
									}}
									onClick={() => handleDelete(index)}
								>
									<DeleteIcon />
								</IconButton>
							</Grid>
						</Grid>
					);
				})
			) : (
				<Typography component="h1" variant="h4" align="center">
					No Emergency Contacts
				</Typography>
			)}
			<Grid contariner spacing={3}>
				<Button
					style={{ marginTop: '20px' }}
					variant="contained"
					color="primary"
					onClick={handleAdd}
				>
					Add emergency contact
				</Button>
			</Grid>
		</React.Fragment>
	);
};

EmerganceContacts.propTypes = {
	data: PropTypes.array.isRequired,
	handleField: PropTypes.func.isRequired,
	deleteData: PropTypes.func.isRequired,
	handleAddNewItem: PropTypes.func.isRequired
};

export default EmerganceContacts;
