import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

const Medications = props => {
	const handleAdd = () => {
		props.handleAddNewItem(
			[
				{
					schoolYear: '',
					school: '',
					medication: '',
					distribute: '',
					prescribedBy: '',
					formsReceived: ''
				}
			],
			'EducationMedications',
			'educationMedications'
		);
	};

	const handleField = (event, index) => {
		props.handleField(
			event.target.value,
			'EducationMedications',
			'educationMedications',
			[index, event.target.id]
		);
	};

	const handleDelete = index => {
		props.deleteData(index, 'EducationMedications', 'educationMedications');
	};

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom>
				Medications
			</Typography>
			{props.data.length > 0 ? (
				props.data.map((medication, index) => {
					return (
						<Grid container spacing={3}>
							<Grid item xs={12} md={3}>
								<TextField
									id="schoolYear"
									label="School Year"
									fullWidth
									onChange={e => handleField(e, index)}
									value={medication.schoolYear}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="school"
									label="School"
									fullWidth
									onChange={e => handleField(e, index)}
									value={medication.school}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="medication"
									label="Medication"
									fullWidth
									onChange={e => handleField(e, index)}
									value={medication.medication}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="distribute"
									label="Distribute"
									fullWidth
									onChange={e => handleField(e, index)}
									value={medication.distribute}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="prescribedBy"
									label="Prescribed By"
									fullWidth
									onChange={e => handleField(e, index)}
									value={medication.prescribedBy}
								/>
							</Grid>
							<Grid item xs={12} md={3}>
								<TextField
									id="formsReceived"
									label="Forms Received"
									fullWidth
									onChange={e => handleField(e, index)}
									value={medication.formsReceived}
								/>
							</Grid>
							<Grid item xs={12} md={1}>
								<IconButton
									aria-label="delete"
									color="primary"
									style={{
										backgroundColor: '#d9534f',
										'&:hover': {
											backgroundColor: '#c9302c'
										},
										color: '#ffffff',
										height: '45px',
										width: '45px',
										marginTop: '5px',
										cursor: 'pointer'
									}}
									onClick={() => handleDelete(index)}
								>
									<DeleteIcon />
								</IconButton>
							</Grid>
						</Grid>
					);
				})
			) : (
				<Typography component="h1" variant="h4" align="center">
					No Medication
				</Typography>
			)}
			<Grid contariner spacing={3}>
				<Button
					style={{ marginTop: '20px' }}
					variant="contained"
					color="primary"
					onClick={handleAdd}
				>
					Add medication
				</Button>
			</Grid>
		</React.Fragment>
	);
};

Medications.propTypes = {
	data: PropTypes.array.isRequired,
	handleField: PropTypes.func.isRequired,
	deleteData: PropTypes.func.isRequired,
	handleAddNewItem: PropTypes.func.isRequired
};

export default Medications;
