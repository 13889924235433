import * as actionTypes from './actionTypes';

export const summaryProfilesList = personId => {
	return {
		type: actionTypes.GET_SUMMARY_PROFILE_LIST,
		requestType: 'GET',
		request: `/summaryProfiles?person=${personId}`,
		body: {}
	};
};
export const summaryProfilesFromTempLink = personId => {
	return {
		type: actionTypes.GET_SUMMARY_PROFILE_LIST,
		requestType: 'GET',
		request: `/summaryProfilesFromTempLink?person=${personId}`,
		body: {}
	};
};

export const createTempLinkSummary = data => {
	return {
		type: actionTypes.CREATE_TEMP_LINK_SUMMARY,
		requestType: 'POST',
		message: 'Temp link was send successful. Please check your email.',
		request: `/tempLinkSummary`,
		body: {
			...data
		}
	};
};

export const sendExport = data => {
	return {
		type: actionTypes.SEND_EXPORT,
		requestType: 'POST',
		message: 'Export file was send successful. Please check your email.',
		request: `/export`,
		body: {
			...data
		}
	};
};

/* export const getRecipients = personId => {
	return {
		type: actionTypes.GET_RECIPIENTS,
		requestType: 'GET',
		request: `/summaryProfiles?person=${personId}`,
		body: {}
	};
}; */

export const checkAccessCode = (code, guid) => {
	return {
		type: actionTypes.CHECK_ACCESS_CODE,
		requestType: 'GET',
		request: `/checkAccessCode?code=${code}&guid=${guid}`,
		body: { code, guid }
	};
};

export const getTempLinks = guid => {
	return {
		type: actionTypes.GET_TEMP_LINKS,
		requestType: 'GET',
		request: `/tempLinkSummary?guid=${guid}`,
		body: { guid }
	};
};
