import * as actionTypes from './actionTypes';

export const getAllProviders = () => {
	return {
		type: actionTypes.GET_ALL_PROVIDERS,
		requestType: 'GET',
		request: `/allproviders`,
		body: {}
	};
};

export const fileUpload = form => {
	return {
		type: actionTypes.FILE_UPLOAD,
		requestType: 'POST',
		request: `/fileUpload`,
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		body: form
	};
};

export const getTextFromImage = form => {
	return {
		type: actionTypes.GET_TEXT_FROM_IMAGE,
		requestType: 'POST',
		request: `/getTextFromImage`,
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		body: form
	};
};

export const getTextFromUrlImage = url => {
	return {
		type: actionTypes.GET_TEXT_FROM_URL_IMAGE,
		requestType: 'POST',
		request: `/getTextFromUrlImage`,
		body: { url }
	};
};

/* Edit actions */
export const getProvidersType = () => {
	return {
		type: actionTypes.GET_PROVIDERS_TYPE,
		requestType: 'GET',
		request: `/providersType`,
		body: {}
	};
};
export const getProfile = ({ profileId }) => {
	return {
		type: actionTypes.GET_PROFILE,
		requestType: 'GET',
		request: `/profile?id=${profileId}`,
		body: { profileId }
	};
};

export const updateProfile = profileData => {
	return {
		type: actionTypes.UPDATE_PROFILE,
		requestType: 'POST',
		message: 'Update Success',
		request: `/updateSelfProfile`,
		body: {
			...profileData
		}
	};
};

export const setDefaultState = () => {
	return {
		type: actionTypes.SET_DEFAULT_STATE
	};
};
