export const STEPS = [
	'Select provider from list',
	'Enter Username and Password',
	'Create profile'
];

export const validationMap = {
	0: ['selectedProviderId'],
	1: ['providerUsername', 'providerPassword'],
	2: []
};
