export const CLOSE_ALERT = Symbol('CLOSE_ALERT');
export const OPEN_ALERT = Symbol('OPEN_ALERT');
export const LOG_OUT = Symbol('LOG_OUT');
export const SEND_FEEDBACK = Symbol('SEND_FEEDBACK');
export const GET_MESSAGES = Symbol('GET_MESSAGES');
export const SEND_SMS_CODE = Symbol('SEND_SMS_CODE');

export const closeAlert = () => {
	return {
		type: CLOSE_ALERT,
		body: {}
	};
};

export const logOut = () => {
	return {
		type: LOG_OUT,
		requestType: 'POST',
		request: `/logout`,
		body: {}
	};
};

export const sendFeedback = feedBackData => {
	return {
		type: SEND_FEEDBACK,
		requestType: 'POST',
		message: 'Feedback sent successfully',
		request: `/sendFeedback`,
		body: {
			...feedBackData
		}
	};
};
export const getMessages = userId => {
	return {
		type: GET_MESSAGES,
		requestType: 'GET',
		request: `/getMessages?userId=${userId}`
	};
};

export const sendSmsCode = (smsCode, providerId) => {
	return {
		type: SEND_SMS_CODE,
		requestType: 'POST',
		message: 'SMS code sent successful.',
		request: `/sendSmsCode`,
		body: {
			smsCode,
			id: providerId
		}
	};
};
