import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import { Avatar, Container, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import CreatePerson from './components/CreatePersonDialog/index';
import { styles } from './styles';
import {
	getPersonsList,
	addPerson,
	photoUpload,
	setSelectedPerson
} from './actions';

import authentication from '../../utilities/authentication';
import cookies from '../../utilities/cookies';

class Persons extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false
		};
	}

	componentDidMount = async () => {
		const { history } = this.props;
		cookies.remove('person');

		await this.props.setSelectedPerson({});
		this.handleGetPersonsList();

		/* Set creation dialog open, when user log in after registration  */
		this.setState({
			open:
				(history.location.state &&
					history.location.state.isCreateDialogOpen) ||
				false
		});

		history.replace(history.location.pathname, {
			isCreateDialogOpen: false
		});
	};

	handleGetPersonsList = () => {
		const { userId } = authentication.loggedData();

		this.props.getPersonsList(userId);
	};

	loadDashboard = person => {
		const personId = person.id;
		this.props.setSelectedPerson(person);
		cookies.set('person', personId);
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	handleOpen = () => {
		this.setState({ open: true });
	};

	render = () => {
		const { personsList, classes, history } = this.props;
		const { open } = this.state;
		return (
			<Container className={classes.peopleGrid} maxWidth="md">
				<div className={classes.peopleContent}>
					<Container maxWidth="sm">
						<Typography
							component="h1"
							variant="h2"
							align="center"
							color="textPrimary"
							gutterBottom
							className={classes.header}
						>
							My People
						</Typography>
					</Container>
				</div>
				<Grid container spacing={5}>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
						style={{ cursor: 'pointer' }}
						className={classes.avatarWrapper}
						onClick={this.handleOpen}
					>
						<Avatar
							className={classes.avatarAddButton}
							alt="Add Person"
						>
							<AddIcon className={classes.addIcon} />
						</Avatar>
						<Typography className={classes.personName}>
							Add Person
						</Typography>
					</Grid>
					{personsList.map(person => (
						<Grid
							item
							key={person.id}
							xs={12}
							sm={6}
							md={3}
							className={classes.avatarWrapper}
						>
							<Link
								onClick={() => this.loadDashboard(person)}
								className={classes.link}
								to={{
									pathname: `/dashboard/${person.id}`,
									state: {
										person: {
											id: person.id,
											name: person.personName,
											photo: person.photo
										}
									},
									pathInfo: person.personName
								}}
							>
								<Avatar
									className={classes.avatar}
									alt={person.personName}
									src={person.photo}
								/>
								<Typography className={classes.personName}>
									{person.personName}
								</Typography>
							</Link>
						</Grid>
					))}
				</Grid>
				<CreatePerson
					open={open}
					addPerson={this.props.addPerson}
					photoUpload={this.props.photoUpload}
					handleClose={this.handleClose}
					getPersonsList={this.handleGetPersonsList}
					history={history}
				/>
			</Container>
		);
	};
}

Persons.propTypes = {
	classes: PropTypes.object.isRequired,
	personsList: PropTypes.array.isRequired,
	getPersonsList: PropTypes.func.isRequired,
	addPerson: PropTypes.func.isRequired,
	photoUpload: PropTypes.func.isRequired,
	setSelectedPerson: PropTypes.func.isRequired,
	history: PropTypes.any.isRequired
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				personsList: state.persons.get('personsList').toJS()
			};
		},
		{
			getPersonsList,
			addPerson,
			photoUpload,
			setSelectedPerson
		}
	)(Persons)
);
