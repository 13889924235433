import React, { Component, Fragment } from 'react';
import GodRoute from '@Components/Routes/GodRoute';

import AdminUsers from './pages/Users/index';
import AllProfiles from './pages/ProfilesList/index';
import ProfileImporter from './pages/ProfileImporter/index';
import RiskScoresImporter from './pages/RiskScoresImporter/index';
import CovidFeedbacks from './pages/CovidFeedbacks/index';
import RequestedZipCodes from './pages/RequestedZipCodes/index';
import Feedbacks from './pages/Feedbacks/index';
import Providers from './pages/Providers/index';

import ProfileAdmin from '../Dashboard/pages/Profiles/pages/ProfileAdminInformation/index';

class Admin extends Component {
	render = () => {
		return (
			<Fragment>
				<GodRoute path="/admin/users" component={AdminUsers} />
				<GodRoute
					path="/admin/allProfiles"
					component={AllProfiles}
					exact
				/>
				<GodRoute
					path="/admin/profileImporter"
					component={ProfileImporter}
				/>
				<GodRoute
					path="/admin/riskScoresImporter"
					component={RiskScoresImporter}
				/>
				<GodRoute
					path="/admin/covidFeedbacks"
					component={CovidFeedbacks}
				/>
				<GodRoute
					path="/admin/requestedZipCodes"
					component={RequestedZipCodes}
				/>
				<GodRoute path="/admin/feedbacks" component={Feedbacks} />
				<GodRoute
					path="/admin/allProfiles/profile/:profileId"
					component={ProfileAdmin}
					exact
				/>
				<Providers />
			</Fragment>
		);
	};
}

export default Admin;
