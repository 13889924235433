export const styles = theme => ({
	profilesGrid: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8)
	},
	avatar: {
		display: 'flex',
		flexDirection: 'column',
		height: '140px',
		width: '140px',
		cursor: 'pointer',
		margin: '0 auto'
	},
	link: {
		textDecoration: 'none',
		color: 'rgba(0, 0, 0, 0.87)',
		cursor: 'pointer'
	},
	personName: {
		textAlign: 'center',
		paddingTop: '10px',
		cursor: 'pointer'
	},
	addIcon: {
		fontSize: '120px'
	},
	avatarAddButton: {
		display: 'flex',
		flexDirection: 'column',
		height: '140px',
		width: '140px',
		margin: '0 auto',
		backgroundColor: theme.palette.primary.main
	},
	avatarWrapper: {
		'&:hover': {
			/* transform: 'scale(1.1)', */
			backgroundColor: theme.palette.primary.hover,
			'& $editButton': {
				visibility: 'visible'
			}
		}
	},
	icons: {
		fontSize: '120px'
	},
	content: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6),
		[theme.breakpoints.down('sm')]: {
			paddingBottom: '0'
		}
	},
	header: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.75rem'
		}
	}
});
