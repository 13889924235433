import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

class TextFieldWrapper extends React.Component {
	shouldComponentUpdate(nextProps) {
		/* 	if (nextProps.selectedLanguage !== this.props.selectedLanguage) {
			this.forceUpdate();
		} */

		for (let i = 0; i < this.props.keyArr.length; i++) {
			const childProps = this.props[this.props.keyArr[i]];
			const childNextProps = nextProps[this.props.keyArr[i]];

			if (childProps !== childNextProps) {
				return true;
			}
		}

		return false;
	}

	render() {
		return (
			<React.Fragment>
				<TextField
					{...this.props}
					InputLabelProps={{
						shrink: true
					}}
				/>
			</React.Fragment>
		);
	}
}

TextFieldWrapper.propTypes = {
	keyArr: PropTypes.any
};

TextFieldWrapper.defaultProps = {
	keyArr: ['value']
};

export default TextFieldWrapper;
