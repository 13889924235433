import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	CssBaseline,
	Typography,
	Container,
	Avatar,
	Grid
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import SchoolIcon from '@material-ui/icons/School';
import AddIcon from '@material-ui/icons/Add';

import { getProfilesList, resetData } from '../../actions';

import { styles } from './styles';

class ProfilesList extends Component {
	componentDidMount = async () => {
		const { personId } = this.props.match.params;
		await this.props.resetData();
		await this.props.getProfilesList(personId);
	};

	loadProfileData = async profile => {
		const { person } = this.props.location.state;

		this.props.history.push({
			pathname: `/dashboard/${person.id}/profiles/${profile.id}`,
			state: { person, profile }
		});
	};

	handleAddConnection = () => {
		const { person } = this.props.location.state;

		this.props.history.push({
			pathname: `/dashboard/${person.id}/newSelfProfile`,
			state: { person, showMenu: true }
		});
	};

	render = () => {
		const { classes, profiles } = this.props;
		return (
			<Fragment>
				<CssBaseline />
				<div className={classes.content}>
					<main>
						<Typography
							component="h1"
							variant="h2"
							align="center"
							color="textPrimary"
							gutterBottom
							className={classes.header}
						>
							Connections List
						</Typography>
						<Typography
							variant="h5"
							align="center"
							color="textSecondary"
							paragraph
						>
							Please select Connection
						</Typography>
						<Container className={classes.profilesGrid}>
							<Grid container spacing={5}>
								{/* {profiles.length === 0 && (
									<Grid item xs={12} sm={12} md={12}>
										<Typography
											variant="h6"
											align="center"
											color="textSecondary"
										>
											This person has no connections
										</Typography>
									</Grid>
								)} */}

								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									style={{ cursor: 'pointer' }}
									className={classes.avatarWrapper}
									onClick={this.handleAddConnection}
								>
									<Avatar
										className={classes.avatarAddButton}
										alt="Add Person"
									>
										<AddIcon className={classes.addIcon} />
									</Avatar>
									<Typography className={classes.personName}>
										Add Connection
									</Typography>
								</Grid>

								{profiles.map(profile => (
									<Grid
										onClick={() =>
											this.loadProfileData(profile)
										}
										item
										key={profile.id}
										xs={12}
										sm={6}
										md={3}
										className={classes.avatarWrapper}
									>
										<div className={classes.link}>
											<Avatar
												className={classes.avatar}
												alt={profile.name}
											>
												{profile.provider.name ===
												'MyChart' ? (
													<LocalHospitalIcon
														className={
															classes.icons
														}
													/>
												) : (
													<SchoolIcon
														className={
															classes.icons
														}
													/>
												)}
											</Avatar>
											<Typography
												className={classes.personName}
											>
												{profile.profileName}
											</Typography>
										</div>
									</Grid>
								))}
							</Grid>
						</Container>
					</main>
				</div>
			</Fragment>
		);
	};
}

ProfilesList.propTypes = {
	classes: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	profiles: PropTypes.array.isRequired,
	getProfilesList: PropTypes.func.isRequired,
	resetData: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			personId: PropTypes.string.isRequired
		})
	}),
	location: PropTypes.shape({
		state: PropTypes.shape({
			person: PropTypes.object.isRequired
		})
	})
};

ProfilesList.defaultProps = {
	location: {},
	match: {}
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				profiles: state.dashboard
					.getIn(['profileDashboard', 'profilesList'])
					.toJS()
			};
		},
		{
			getProfilesList,
			resetData
		}
	)(ProfilesList)
);
