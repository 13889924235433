/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	avatar: {
		display: 'flex',
		flexDirection: 'column',
		height: '140px',
		width: '140px',
		margin: '0 auto'
	},
	input: {
		display: 'none'
	},
	uploadButtons: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '15px'
	},
	editButton: {
		cursor: 'pointer',
		marginBottom: '12px'
	}
}));
const ONE_SPACE_REG_EX = /^\s+$/;
const EditDialog = props => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [username, setUsername] = React.useState(props.person.name);
	const [formData, setFormData] = React.useState(false);
	const [fileURL, setFileUrl] = React.useState(props.person.photo);

	const handleClickOpen = () => {
		setOpen(true);
		setUsername(props.person.name);
		setFileUrl(props.person.photo);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleNameChange = event => {
		setUsername(event.target.value);
	};

	const onNewFileChange = event => {
		const file = event.target.files[0];
		setFileUrl(URL.createObjectURL(file));

		const oneMBSize = 10048487;

		if (file && file.size <= oneMBSize) {
			const data = new FormData();

			data.append('avatar', file);

			setFormData(data);
		}
	};

	const handleEditPerson = (file, data) => {
		props.editPerson(
			{
				id: props.person.id,
				name: username
			},
			data
		);
		handleClose();
	};

	return (
		<>
			<EditIcon
				variant="contained"
				color="primary"
				className={classes.editButton}
				onClick={handleClickOpen}
			/>

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				className={classes.root}
			>
				<DialogTitle id="form-dialog-title">Edit Person</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Please enter full name. Attaching of photo is optional.
					</DialogContentText>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="fullName"
						label="Full Name"
						name="fullName"
						value={username}
						onChange={handleNameChange}
					/>
					<div>
						<Avatar
							className={classes.avatar}
							alt="photo"
							src={fileURL}
						/>
					</div>
					<div className={classes.uploadButtons}>
						<input
							accept="image/*"
							className={classes.input}
							id="contained-button-file"
							multiple
							type="file"
							onChange={onNewFileChange}
						/>
						<label htmlFor="contained-button-file">
							<Button
								variant="contained"
								color="primary"
								component="span"
							>
								Upload
								<PhotoCamera style={{ marginLeft: '10px' }} />
							</Button>
						</label>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button
						onClick={() => handleEditPerson(fileURL, formData)}
						color="primary"
						disabled={!username || ONE_SPACE_REG_EX.test(username)}
					>
						Edit
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

EditDialog.propTypes = {
	editPerson: PropTypes.func.isRequired,
	person: PropTypes.object.isRequired
};

export default EditDialog;
