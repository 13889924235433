import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const PersonalInfo = props => {
	const handleDateChange = date => {
		props.handleField(date, 'PersonalInfo', 'personalInfo', [
			'dateOfBirth'
		]);
	};

	const onFieldChange = event => {
		props.handleField(event.target.value, 'PersonalInfo', 'personalInfo', [
			event.target.id
		]);
	};

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom>
				Personal Info
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<TextField
						id="firstName"
						name="firstName"
						label="First name"
						fullWidth
						value={props.data.firstName}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="lastName"
						name="lastName"
						label="Last name"
						fullWidth
						value={props.data.lastName}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							disableToolbar
							variant="inline"
							margin="normal"
							id="dataOfBirth"
							autoOk
							label="Date of Birth"
							disableFuture
							openTo="year"
							format="dd/MM/yyyy"
							views={['year', 'month', 'date']}
							value={props.data.dateOfBirth}
							style={{
								width: '100%',
								marginTop: 0,
								marginBottom: 0
							}}
							onChange={handleDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change date'
							}}
						/>
					</MuiPickersUtilsProvider>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="race"
						name="race"
						label="Demographics - Race"
						fullWidth
						value={props.data.race}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="medicalRecordNumber"
						name="medicalRecordNumber"
						label="Medical Record Number"
						fullWidth
						value={props.data.medicalRecordNumber}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="clinic"
						name="clinic"
						label="Clinic"
						fullWidth
						value={props.data.clinic}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						id="primaryProvider"
						name="primaryProvider"
						label="Primary Provider"
						fullWidth
						value={props.data.primaryProvider}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="phoneNumber"
						name="phoneNumber"
						label="Phone Number"
						fullWidth
						value={props.data.phoneNumber}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="address"
						name="address"
						label="Address"
						fullWidth
						value={props.data.address}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="zipCode"
						name="zipCode"
						label="Zip code"
						fullWidth
						value={props.data.zipCode}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="emergencyContactName"
						name="emergencyContactName"
						label="Emergency contact name"
						fullWidth
						value={props.data.emergencyContactName}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="emergencyContactPhone"
						name="emergencyContactPhone"
						label="Emergency contact phone number"
						fullWidth
						value={props.data.emergencyContactPhone}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="insuranceName"
						name="insuranceName"
						label="Insurance Name"
						fullWidth
						value={props.data.insuranceName}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="insuranceType"
						name="insuranceType"
						label="Insurance Type"
						fullWidth
						value={props.data.insuranceType}
						onChange={onFieldChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="insuranceNumber"
						name="insuranceNumber"
						label="Insurance Number"
						fullWidth
						value={props.data.insuranceNumber}
						onChange={onFieldChange}
					/>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

PersonalInfo.propTypes = {
	data: PropTypes.array.isRequired,
	handleField: PropTypes.func.isRequired
};

export default PersonalInfo;
