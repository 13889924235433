/* Common functions for all project */
import { DEFAULT_DATE_FORMAT, MONTH_NAMES, DAYS_OF_WEEK } from './constants';

export const formatDate = (dateTime, patternStr = DEFAULT_DATE_FORMAT) => {
	const date = new Date(dateTime);

	if (date.toString() === 'Invalid Date') {
		return dateTime;
	}

	const day = date.getDate();
	const month = date.getMonth();
	const year = date.getFullYear();
	const hour = date.getHours();
	const minute = date.getMinutes();
	const second = date.getSeconds();
	const milliseconds = date.getMilliseconds();
	const h = hour % 12;
	const hh = twoDigitPad(h);
	const HH = twoDigitPad(hour);
	const mm = twoDigitPad(minute);
	const ss = twoDigitPad(second);
	const aaa = hour < 12 ? 'AM' : 'PM';
	const EEEE = DAYS_OF_WEEK[date.getDay()];
	const EEE = EEEE.substr(0, 3);
	const dd = twoDigitPad(day);
	const M = month + 1;
	const MM = twoDigitPad(M);
	const MMMM = MONTH_NAMES[month];
	const MMM = MMMM.substr(0, 3);
	const yyyy = `${year}`;
	const yy = yyyy.substr(2, 2);

	// checks to see if month name will be used
	patternStr = patternStr
		.replace('hh', hh)
		.replace('h', h)
		.replace('HH', HH)
		.replace('H', hour)
		.replace('mm', mm)
		.replace('m', minute)
		.replace('ss', ss)
		.replace('s', second)
		.replace('S', milliseconds)
		.replace('dd', dd)
		.replace('d', day)

		.replace('EEEE', EEEE)
		.replace('EEE', EEE)
		.replace('yyyy', yyyy)
		.replace('yy', yy)
		.replace('aaa', aaa);

	if (patternStr.indexOf('MMM') > -1) {
		patternStr = patternStr.replace('MMMM', MMMM).replace('MMM', MMM);
	} else {
		patternStr = patternStr.replace('MM', MM).replace('M', M);
	}
	return patternStr;
};
const twoDigitPad = num => {
	return num < 10 ? `0${num}` : num;
};
