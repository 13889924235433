import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CssBaseline, Typography, Container, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import ConfirmationDialog from '@Components/ConfirmationDialog';

import {
	summaryProfilesList,
	createTempLinkSummary,
	sendExport
} from './actions';

import SummaryContent from './components/SummaryContent/index';
import TempLinkDialog from './components/TempLinkDialog/index';
import ExportDialog from './components/ExportDialog/index';
import MedicalAdviceDialog from './components/MedicalAdviceDialog/index';
import authentication from '../../utilities/authentication';

import { styles } from './styles';

class Summary extends Component {
	constructor(props) {
		super(props);
		this.summaryRef = React.createRef();
		this.state = {
			email: '',
			isConfirmationDialogOpen: false
		};
	}

	componentDidMount = () => {
		this.props.summaryProfilesList(this.props.match.params.personId);
	};

	generateTempLink = () => {
		const loggedData = authentication.loggedData();

		const tempLink = {
			user: loggedData.userId,
			email: this.state.email,
			profileId: null,
			content: {
				parentData: this.props.match.params.personId
			}
		};

		this.props.createTempLinkSummary(tempLink);
		this.setState({
			email: '',
			isConfirmationDialogOpen: false
		});
	};

	handleSendEmail = email => {
		this.setState({
			email,
			isConfirmationDialogOpen: true
		});
	};

	sendExport = params => {
		const formData = {
			title: params.title,
			email: params.email,
			personId: this.props.match.params.personId
		};

		this.props.sendExport(formData);
	};

	handleCloseConfirmationDialog = () => {
		this.setState({
			email: '',
			isConfirmationDialogOpen: false
		});
	};

	sendMedicalAdvice = params => {
		console.log('send medical advice');
	};

	render = () => {
		const { classes, allInformation, medicalQuestions } = this.props;
		const { isConfirmationDialogOpen } = this.state;
		return (
			<React.Fragment>
				<CssBaseline />
				<main ref={this.summaryRef} className={classes.mainContainer}>
					<div className={classes.exportSummaryContent}>
						<Container maxWidth="sm">
							<Typography
								component="h1"
								variant="h2"
								align="center"
								color="textPrimary"
								gutterBottom
								className={classes.header}
							>
								Export Summary
							</Typography>
							<Typography
								variant="h5"
								align="center"
								color="textSecondary"
								paragraph
								className={classes.subHeader}
							>
								Please see below the summarized health &
								educational information according to your
								visibility settings
							</Typography>
						</Container>
						<Container
							className={classes.exportSummaryGrid}
							maxWidth="md"
						>
							<Grid
								style={{ marginBottom: '10px' }}
								container
								spacing={4}
							>
								<TempLinkDialog
									handleSendEmail={this.handleSendEmail}
								/>
							</Grid>
							<Grid
								style={{ marginBottom: '10px' }}
								container
								spacing={4}
							>
								<ExportDialog sendExport={this.sendExport} />
							</Grid>
							<Grid
								style={{ marginBottom: '10px' }}
								container
								spacing={4}
							>
								<MedicalAdviceDialog
									requestMedicalAdvice={
										this.sendMedicalAdvice
									}
									medicalQuestions={medicalQuestions}
								/>
							</Grid>
						</Container>
						<Container
							className={classes.summaryContentGrid}
							maxWidth="md"
						>
							<Grid style={{ marginBottom: '10px' }} container>
								<Grid item md={12} xs={12}>
									<SummaryContent
										scrollElement={this.summaryRef}
										data={[
											{
												categoryName: 'title',
												isNested: false,
												values: 'Health Data'
											},
											...allInformation.myChart,
											{
												categoryName: 'title',
												isNested: false,
												values: 'Education Data'
											},
											...allInformation.education,
											{
												categoryName: 'title',
												isNested: false,
												values: 'Fort Worth'
											},
											...allInformation.fwisd,
											...allInformation.absences,
											{
												categoryName: 'title',
												isNested: false,
												values: 'IrvingISD'
											},
											...allInformation.irvingISD
										]}
									/>
								</Grid>
							</Grid>
						</Container>
					</div>
				</main>
				<ConfirmationDialog
					open={isConfirmationDialogOpen}
					dialogContent="Are you sure that you want to share your health information with this email?"
					dialogTitle="Confirmation Dialog"
					action={this.generateTempLink}
					close={this.handleCloseConfirmationDialog}
				/>
			</React.Fragment>
		);
	};
}

Summary.propTypes = {
	classes: PropTypes.object.isRequired,
	allInformation: PropTypes.object.isRequired,
	medicalQuestions: PropTypes.object.isRequired,
	summaryProfilesList: PropTypes.func.isRequired,
	createTempLinkSummary: PropTypes.func.isRequired,
	sendExport: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			personId: PropTypes.string.isRequired
		})
	})
};

Summary.defaultProps = {
	match: {}
};

export default withStyles(styles)(
	connect(
		state => {
			return {
				allInformation: state.summary.get('allInformation').toJS(),
				medicalQuestions: state.summary.get('medicalQuestions').toJS()
			};
		},
		{ summaryProfilesList, createTempLinkSummary, sendExport }
	)(Summary)
);
