import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';

import createRootReducer from './reducers';
import middlewares from './lib/middlewares';

export const history = createBrowserHistory();

export function configureStore(preloadedState) {
	const store = createStore(
		createRootReducer(history),
		preloadedState,
		composeWithDevTools(
			applyMiddleware(routerMiddleware(history), thunk, ...middlewares)
		)
	);

	return store;
}
