import * as actionTypes from './actionTypes';

export const getRequestedZipCodes = () => {
	return {
		type: actionTypes.GET_REQUESTED_ZIP_CODES,
		requestType: 'GET',
		request: `/requestedZipCodes`,
		body: {}
	};
};
