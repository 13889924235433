import * as actionTypes from './actionTypes';

export const getUsers = searchValue => {
	return {
		type: actionTypes.GET_USERS,
		requestType: 'GET',
		request: `/users?searchTags=${searchValue}`
	};
};
export const updateUserType = userDetails => {
	return {
		type: actionTypes.UPDATE_USER_TYPE,
		requestType: 'POST',
		message: 'User updated successfully',
		request: `/updateUserType`,
		body: {
			...userDetails
		}
	};
};
export const setUserType = ({ rowIndex, newUserType }) => {
	return {
		type: actionTypes.SET_USER_TYPE,
		body: {
			rowIndex,
			newUserType
		}
	};
};
