import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const AgreeDialog = props => {
	const [open, setOpen] = React.useState(false);
	const [isAgree, setIsAgree] = React.useState(false);
	const [scroll, setScroll] = React.useState('paper');

	const handleClickOpen = scrollType => () => {
		if (!isAgree) {
			setOpen(true);
			setScroll(scrollType);
		} else {
			setIsAgree(false);
			props.handleAgree(false);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleAgree = () => {
		setOpen(false);
		setIsAgree(true);
		props.handleAgree(true);
	};

	const descriptionElementRef = React.useRef(null);
	React.useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);

	return (
		<div>
			<FormControlLabel
				control={
					<Checkbox
						color="primary"
						name="agree"
						checked={isAgree}
						onChange={handleClickOpen('paper')}
					/>
				}
				label="I agree to the Terms of Service"
			/>
			<Dialog
				open={open}
				onClose={handleClose}
				scroll={scroll}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">
					Terms of Service
				</DialogTitle>
				<DialogContent dividers={scroll === 'paper'}>
					<DialogContentText
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
					>
						<Grid container spacing={5}>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								style={{
									cursor: 'pointer',
									textAlign: 'center'
								}}
							>
								<Link
									target="_blank"
									href="https://www.privacypolicies.com/live/d3c39c9a-d753-415f-8add-737e033f8f66"
									variant="body2"
								>
									Terms and Conditions
								</Link>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								style={{
									cursor: 'pointer',
									textAlign: 'center'
								}}
							>
								<Link
									target="_blank"
									href="https://www.privacypolicies.com/live/c91f7a3a-ba68-4ea5-8de4-9ac478e62458"
									variant="body2"
								>
									Privacy Policy
								</Link>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								style={{
									cursor: 'pointer',
									textAlign: 'center'
								}}
							>
								<Link
									target="_blank"
									href="https://www.privacypolicies.com/live/c81cd6d9-0b4d-4de1-8ad1-3a03e99dcb23"
									variant="body2"
								>
									Cookie Policy
								</Link>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								style={{
									cursor: 'pointer',
									textAlign: 'center'
								}}
							>
								<Link
									target="_blank"
									href="https://www.privacypolicies.com/live/6162677c-2752-462b-b543-bc332c2c4df2"
									variant="body2"
								>
									Disclaimer
								</Link>
							</Grid>
						</Grid>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleAgree} color="primary">
						I agree
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

AgreeDialog.propTypes = {
	handleAgree: PropTypes.func.isRequired
};

export default AgreeDialog;
