import { fromJS } from 'immutable';

import testReport, {
	defaultState as testReportDefaultState
} from './pages/TestReport/reducer';

import covidReport, {
	defaultState as covidReportDefaultState
} from './pages/CovidReport/reducer';

import riskScoreByZipCode, {
	defaultState as riskScoreByZipCodeDefaultState
} from './pages/RiskScoreByZipCode/reducer';

export const defaultState = fromJS({
	testReport: testReportDefaultState,
	covidReport: covidReportDefaultState,
	riskScoreByZipCode: riskScoreByZipCodeDefaultState
});

const TEST_REPORT = 'testReport';
const COVID_REPORT = 'covidReport';
const RISK_SCORE_BY_ZIP_CODE = 'riskScoreByZipCode';

export default function reports(state = defaultState, action) {
	return state
		.set(COVID_REPORT, covidReport(state.get(COVID_REPORT), action))
		.set(
			RISK_SCORE_BY_ZIP_CODE,
			riskScoreByZipCode(state.get(RISK_SCORE_BY_ZIP_CODE), action)
		)
		.set(TEST_REPORT, testReport(state.get(TEST_REPORT), action));
}
