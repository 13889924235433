export const styles = theme => ({
	informationWrapper: {
		marginTop: '64px',
		marginBottom: '64px'
	},
	informationGrid: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8)
	}
});
