import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';

const getBarColor = props => {
	/* 	if (props.value >= 90) {
		return 'rgb(244, 117, 96)';
	}
	if (props.value >= 75 && props.value < 90) {
		return 'rgb(232, 168, 56)';
	}
	if (props.value >= 40 && props.value < 75) {
		return 'rgb(241, 225, 91)';
	}
	if (props.value < 40) {
		return '#1976d2';
    } */
	let color = '#00b050';
	if (props.value >= 10) {
		color = '#c00000';
	}
	if (props.value >= 5 && props.value < 10) {
		color = '#ffc000';
	}

	return color;
};

const withStylesProps = styles => Component => props => {
	const Comp = withStyles(styles(props))(Component);
	return <Comp {...props} />;
};

const styles = props => ({
	root: {
		height: 15,
		borderRadius: 5
	},
	colorPrimary: {
		backgroundColor: '#e8e8e8'
	},
	bar: {
		borderRadius: 5,
		backgroundColor: getBarColor(props)
	}
});

const BorderLinearProgress = withStylesProps(styles)(LinearProgress);

const useStyles = makeStyles({
	root: {
		flexGrow: 1
	}
});

const ProgressBar = props => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Box display="flex" alignItems="center">
				<Box width="100%" mr={1}>
					<BorderLinearProgress
						variant="determinate"
						value={props.value}
					/>
				</Box>
				<Box minWidth={35}>
					<Typography variant="body2" color="textSecondary">
						{props.value}%
					</Typography>
				</Box>
			</Box>
		</div>
	);
};

ProgressBar.propTypes = {
	value: PropTypes.number.isRequired
};

export default ProgressBar;
