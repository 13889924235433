export const styles = theme => ({
	icon: {
		marginRight: theme.spacing(2)
	},
	profileListContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6)
	},
	heroButtons: {
		marginTop: theme.spacing(4)
	},
	profileListGrid: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8)
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	cardMedia: {
		paddingTop: '56.25%' // 16:9
	},
	cardContent: {
		flexGrow: 1
	},
	subHeader: {
		marginBottom: '20px',
		marginTop: '20px'
	},
	link: {
		textDecoration: 'none',
		color: 'rgba(0, 0, 0, 0.87)'
	},
	tempLinkField: {
		width: '100%'
	},
	saveButton: {
		marginTop: '20px'
	},
	refreshButton: {
		marginBottom: '20px'
	},
	searchButton: {
		marginBottom: '20px',
		marginLeft: '20px'
	},
	buttonsGrid: {
		marginTop: '20px',
		marginBottom: '20px'
	}
});
