import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		width: '460px',
		maxWidth: 460,
		display: 'flex',
		backgroundColor: '#fafafa'
	},
	avatar: {
		backgroundColor: theme.palette.primary.main
	},
	items: {
		paddingLeft: 0,
		paddingRight: 0
	}
}));

const covidDesctiptions = {
	high: (
		<span>
			COVID-19 is spreading
			<span style={{ color: '#c00000', fontWeight: 'bold' }}>
				{' '}
				rapidly{' '}
			</span>
			in your neighborhood. Check the school’s COVID-19 protocol to decide
			if it is safe to send your child back to school. If your child has
			any chronic condition such as asthma, talk to the doctor about the
			risk for severe COVID-19 infection.
		</span>
	),
	moderate: (
		<span>
			COVID-19 is spreading
			<span style={{ color: '#ffc000', fontWeight: 'bold' }}>
				{' '}
				moderately{' '}
			</span>
			in your neighborhood. It might be safe to send your child to school
			if the school has a strong COVID-19 protocol. If your child has any
			chronic condition such as asthma, talk to the doctor about the risk
			for severe COVID-19 infection.
		</span>
	),
	low: (
		<span>
			COVID-19 is spreading
			<span style={{ color: '#00b050', fontWeight: 'bold' }}>
				{' '}
				slowly{' '}
			</span>
			in your neighborhood. It is likely safe to send your child to
			school. Check the school’s COVID-19 protocol. If your child has any
			chronic condition such as asthma, talk to the doctor about the risk
			for severe COVID-19 infection.
		</span>
	)
};

const statusColors = {
	high: '#c00000',
	moderate: '#ffc000',
	low: '#00b050'
};

const getCovidStatus = score => {
	let status = 'low';
	if (score >= 10) {
		status = 'high';
	}
	if (score >= 5 && score < 10) {
		status = 'moderate';
	}

	return status;
};

export default function Covid19Info(props) {
	const classes = useStyles();

	const { info } = props;
	const status = getCovidStatus(info.riskScore);
	return (
		<Fragment>
			<Typography
				style={{ fontWeight: 'bold' }}
				variant="h4"
				gutterBottom
				align="center"
			>
				<span>{`Zip Code ${info.zipCode} COVID-19 Risk ${info.riskScore}% - `}</span>
				<span
					style={{
						color: statusColors[status]
					}}
				>
					{`${status.toUpperCase()}`}
				</span>
			</Typography>
			<Typography align="center" variant="subtitle1">
				{covidDesctiptions[status]}
			</Typography>
		</Fragment>
	);
}

Covid19Info.propTypes = {
	info: PropTypes.object.isRequired
};
