import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@Components/TextField/index';

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: '20px',
		width: '100%',
		border: '1px solid rgba(0, 0, 0, 0.12)',
		padding: '30px'
	}
}));

const keyMap = [
	{
		id: 'medicalHistoryUrl',
		label: 'Url'
	},
	{
		id: 'medicalHistoryDiagnosisSelectorAll',
		label: 'Diagnosis All'
	},
	{
		id: 'medicalHistoryDiagnosisSelectorName',
		label: 'Diagnosis Name'
	},
	{
		id: 'medicalHistoryDiagnosisSelectorWhen',
		label: 'Diagnosis When'
	},
	{
		id: 'surgicalHistorySelectorAll',
		label: 'Surgical History All'
	},
	{
		id: 'surgicalHistorySelectorName',
		label: 'Surgical History Name'
	},
	{
		id: 'surgicalHistorySelectorWhen',
		label: 'Surgical History When'
	},
	{
		id: 'familyHistorySelectorAll',
		label: 'Family History All'
	},
	{
		id: 'familyHistorySelectorRelationship',
		label: 'Family History Relationship'
	},
	{
		id: 'familyHistorySelectorHealthIssue',
		label: 'Family History Health Issue'
	},
	{
		id: 'familyHistorySelectorComment',
		label: 'Family History Component'
	},
	{
		id: 'socialHistorySelector',
		label: 'Social History Global'
	},
	{
		id: 'smokingTobaccoSelectorUse',
		label: 'Smoking Tobacco Use'
	},
	{
		id: 'smokingTobaccoSelectorType',
		label: 'Smoking Tobacco Type'
	},
	{
		id: 'smokingTobaccoSelectorPacks',
		label: 'Smoking Tobacco Packs'
	},
	{
		id: 'smokingTobaccoSelectorTears',
		label: 'Smoking Tobacco Tears'
	},
	{
		id: 'smokelessTobaccoSelectorUse',
		label: 'Smokeless Tobacco Use'
	},
	{
		id: 'smokelessTobaccoSelectorType',
		label: 'Smokeless Tobacco Type'
	},
	{
		id: 'alcoholUseSelectorUse',
		label: 'Alcohol Use'
	},
	{
		id: 'alcoholUseSelectorOunces',
		label: 'Alcohol Use Ounces'
	}
];

const MedicalHistorySelectors = props => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography variant="h6" gutterBottom>
				Medical HistorySelectors Selectors
			</Typography>
			<Grid container spacing={3}>
				{keyMap.map(item => (
					<Grid item xs={12} md={6}>
						<TextField
							id={item.id}
							label={item.label}
							fullWidth
							onChange={props.handleField}
							value={props.data[item.id]}
						/>
					</Grid>
				))}
			</Grid>
		</div>
	);
};

MedicalHistorySelectors.propTypes = {
	handleField: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired
};

export default MedicalHistorySelectors;
