export const GET_PROFILES = Symbol('GET_PROFILES');
export const SEND_TEMP_LINK = Symbol('SEND_TEMP_LINK');
export const GET_PROFILE = Symbol('GET_PROFILE');
export const GET_PROVIDER = Symbol('GET_PROVIDER');
export const UPDATE_PROFILE = Symbol('UPDATE_PROFILE');
export const UPDATE_PROVIDER_CREDENTIALS = Symbol(
	'UPDATE_PROVIDER_CREDENTIALS'
);
export const RESET_DATA = Symbol('RESET_DATA');
export const CHECK_ACCESS_CODE = Symbol('CHECK_ACCESS_CODE');
export const LOAD_PROFILE_FROM_TEMP_LINK = Symbol('CHECK_ACCESS_CODE');
export const DELETE_PROFILE = Symbol('DELETE_PROFILE');
export const GET_PROVIDER_CREDENTIALS = Symbol('GET_PROVIDER_CREDENTIALS');
export const UPDATE_PROFILE_VISIBILITY_SCHEMA = Symbol(
	'UPDATE_PROFILE_VISIBILITY_SCHEMA'
);
export const MOVE_PROFILE = Symbol('MOVE_PROFILE');
export const GET_PERSONS = Symbol('GET_PERSONS');
