const drawerWidth = 250;
const scrollbarBG = '#CFD8DC';
const thumbBG = '#1976d2';
export const styles = theme => ({
	root: {
		display: 'flex'
	},
	loader: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	toolbar: {
		paddingRight: 24 // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	menuButton: {
		marginRight: 36
	},
	menuButtonHidden: {
		display: 'none'
	},
	title: {
		flexGrow: 1
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		overflowY: 'auto',
		height: '100vh',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		scrollbarWidth: 'thin',
		scrollbarColor: `${thumbBG} ${scrollbarBG}`,

		'&::-webkit-scrollbar': {
			width: '10px'
		},

		'&::-webkit-scrollbar-track': {
			background: scrollbarBG
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: thumbBG
		}
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		width: '0.000001px', // Mac hot fix
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9)
		}
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto'
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4)
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
		minHeight: 'calc(100vh - 180px)'
	},
	fixedHeight: {
		height: 240
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	customHoverFocus: {
		backgroundColor: '#1976d2',
		color: '#ffffff',
		height: '30px',
		width: '30px',
		'&:hover, &.Mui-focusVisible': {
			backgroundColor: '#1976d2',
			color: '#ffffff',
			height: '30px',
			width: '30px'
		}
	}
});
