import { fromJS } from 'immutable';
import * as actionTypes from './actionTypes';

export const defaultState = fromJS({
	providersType: [],
	providers: []
});

const providerList = (state = defaultState, action) => {
	switch (action.type) {
		case actionTypes.GET_PROVIDERS_TYPE:
			if (action.state === 'finished') {
				state = state.set('providersType', action.response);
			}
			return state;
		case actionTypes.GET_PROVIDERS_CONFIG:
			if (action.state === 'finished') {
				state = state.set('providers', action.response);
			}
			return state;

		default:
			return state;
	}
};

export default providerList;
