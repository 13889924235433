export const styles = theme => ({
	timelineContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6)
	},
	exportSummaryGrid: {
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(8)
	},
	timelineContentGrid: {
		paddingLeft: '10px',
		paddingRight: '10px',
		maxWidth: '1000px'
	},
	mainContainer: {
		height: '74vh',
		overflowY: 'auto',
		overflowX: 'hidden'
	},
	header: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.75rem'
		}
	},
	subHeader: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.00rem'
		}
	}
});
